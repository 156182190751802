import React, { useEffect, useCallback, useState, FormEvent } from 'react'

import { toast } from 'react-toastify'

import IOkr from '../../../interfaces/IOkr'

import { useOkr } from '../../../hooks/okr'

import './styles.scss'
import { useProblemsBase } from '../../../hooks/problems'
import { useIdeaBase } from '../../../hooks/ideiaBase'
import { useIdea } from '../../../hooks/idea'
import IPagination from '../../../interfaces/IPagination'
import { SearchPagination } from '../../../interfaces/ISearchPagination'
import IIdeaBase from '../../../interfaces/IIdeaBase'
import { LIMITTABLES } from '../../../config/constLimits'

const INITIAL_STATE = {
  problem: '',
  okr: '',
  idea: '',
  trust: 5,
  engage: 5,
  impact: 5,
}

const ModalAdd: React.FC = () => {
  document.body.classList.add('add-idea-modal')

  const [isProblemChecked] = useState(false)
  const [isIdeaChecked, setIsIdeaChecked] = useState(false)
  const [form, setForm] = useState(INITIAL_STATE)
  const [okrList, setOkrList] = useState({} as IPagination<IOkr>)
  const [ideasBaseList, setIdeasBaseList] = useState<IIdeaBase[]>([])

  const okrHook = useOkr()
  const problemsBaseHook = useProblemsBase()
  const ideaBaseHook = useIdeaBase()
  const ideiaHook = useIdea()

  useEffect(() => {
    okrHook.getAll({ all: true } as SearchPagination).then(response => {
      setOkrList(response)
    })
    problemsBaseHook.getAll()
    ideaBaseHook.getAll().then(response => {
      setIdeasBaseList(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault()
      const model = {
        problem: {
          _id: form.problem,
        },
        okr: {
          _id: form.okr,
        },
        description: form.idea,
        trust: form.trust,
        engage: form.engage,
        impact: form.impact,
      }
      try {
        await ideiaHook.Add(model)
        toast.success('Ideia criada com sucesso!')
        setForm({ ...INITIAL_STATE })

        ideiaHook.getAll({ page: 1, limit: LIMITTABLES } as SearchPagination)
      } catch (error) {
        // console.log(error)
      }
    },
    [form, ideiaHook],
  )

  return (
    <>
      <div
        className="modal fade"
        id="modalIdeaAdd"
        aria-labelledby="modalIdeaAdd"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Cadastro de Ideia
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Objetivo
                  </label>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="objectiveInput"
                      defaultValue="Selecione um objetivo..."
                      value={form.okr}
                      name="okr"
                      onChange={handleInputChange}
                    >
                      <option defaultValue="Selecione uma ação">
                        Selecione um objetivo
                      </option>
                      {okrList &&
                        okrList.results?.map(okrListObj => (
                          <option key={okrListObj._id} value={okrListObj._id}>
                            {okrListObj.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Problema
                    <h6>
                      O que nos impede de atingir o objetivo ou melhorar a
                      métrica-chave?
                    </h6>
                  </label>

                  <div className="input-group">
                    {isProblemChecked ? (
                      <input
                        type="text"
                        name="problem"
                        className="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={handleInputChange}
                      />
                    ) : (
                      <select
                        className="form-select"
                        name="problem"
                        id="problemInput"
                        defaultValue="Selecione ou cadastre uma barreira..."
                        onChange={handleInputChange}
                        value={form.problem}
                      >
                        <option defaultValue="Selecione uma ação">
                          Selecione um problema
                        </option>
                        {problemsBaseHook.problemsBaseList &&
                          problemsBaseHook.problemsBaseList.map(problem => (
                            <option key={problem._id} value={problem._id}>
                              {problem.name}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  {/* <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={isProblemChecked}
                      disabled
                      onChange={e => setIsProblemChecked(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Selecione para criar um novo problema
                    </label>
                  </div> */}
                </div>
                <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Ideia
                  </label>
                  <div className="input-group">
                    {isIdeaChecked ? (
                      <input
                        type="text"
                        name="idea"
                        className="form-control"
                        aria-describedby="basic-addon3"
                        value={form.idea}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <select
                        className="form-select"
                        id="actionInput"
                        name="idea"
                        onChange={handleInputChange}
                      >
                        <option defaultValue="Selecione uma ação">
                          Selecione uma ideia
                        </option>
                        {ideasBaseList &&
                          ideasBaseList.map(idea => (
                            <option key={idea._id} value={idea.description}>
                              {idea.description}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={isIdeaChecked}
                      onChange={e => setIsIdeaChecked(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Selecione para criar uma nova ideia
                    </label>
                  </div>
                </div>

                <div className="form-range-1">
                  <label
                    htmlFor="customRange2"
                    className="form-label labelForm"
                  >
                    Impacto:
                  </label>
                  <input
                    type="range"
                    name="impact"
                    onChange={handleInputChange}
                    min={0}
                    max={10}
                    step={1}
                    defaultValue={form.impact}
                  />
                </div>

                <div className="form-range-2">
                  <label
                    htmlFor="customRange2"
                    className="form-label labelForm"
                  >
                    Confiança:
                  </label>
                  <input
                    type="range"
                    name="trust"
                    onChange={handleInputChange}
                    min={0}
                    max={10}
                    step={1}
                    defaultValue={form.trust}
                  />
                </div>
                <div className="form-range-3">
                  <label
                    htmlFor="customRange2"
                    className="form-label labelForm"
                  >
                    Facilidade:
                  </label>
                  <input
                    type="range"
                    onChange={handleInputChange}
                    name="engage"
                    min={0}
                    max={10}
                    step={1}
                    defaultValue={form.engage}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary" id="save">
                  Salvar
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalAdd
