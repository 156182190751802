import { useContext } from 'react'
import { AiOutlineLike, AiFillLike } from 'react-icons/ai'
import { WorkshopContext } from '../../../pages/Workshop/WorkshopStageManager'

import './styles.scss'

interface IProps {
  stage: number
  onClick: () => void
  likes: number
  iLike: boolean
}

const NOT_FOUND_INDEX = -1

export const WorkshopCardFooterFactory: React.FC<IProps> = ({
  stage,
  onClick,
  likes,
  iLike,
}) => {
  const { likeStages, showLikeStages } = useContext(WorkshopContext)

  if (likeStages.indexOf(stage) !== NOT_FOUND_INDEX) {
    if (!iLike) {
      return (
        <div className="workshop-card-footer-factory">
          <AiOutlineLike size={22} onClick={onClick} />
        </div>
      )
    }

    return (
      <div className="workshop-card-footer-factory">
        <AiFillLike size={22} onClick={onClick} />
      </div>
    )
  }

  if (showLikeStages.indexOf(stage) !== NOT_FOUND_INDEX) {
    return (
      <div className="workshop-card-footer-factory">
        {likes}
        <AiFillLike size={22} />
      </div>
    )
  }

  return null
}

export default WorkshopCardFooterFactory
