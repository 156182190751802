import React, { useEffect, useState } from 'react'
import { ResponsiveLine, Serie } from '@nivo/line'

interface ILineChart {
  data: Serie[]
  goal?: number
}

const LineChart: React.FC<ILineChart> = ({ data, goal }) => {
  const [highestValue, setHighestValue] = useState(0)
  useEffect(() => {
    const value = data[0]?.data.reduce((acc, curr) => {
      if (typeof curr.y !== 'number') return acc
      return Math.max(acc, curr.y)
    }, 0)
    setHighestValue(value)
  }, [data])
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: goal
          ? highestValue > goal
            ? highestValue + (highestValue * 20) / 100
            : goal + (goal * 20) / 100
          : highestValue,
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
      }}
      markers={
        goal
          ? [
              {
                axis: 'y',
                value: goal,
                lineStyle: {
                  stroke: '#000000',
                  strokeWidth: 2,
                  strokeDasharray: '6 6',
                },
                legend: 'Meta',
              },
            ]
          : []
      }
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  )
}

export default LineChart
