import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { ImEye } from 'react-icons/im'
import { Link } from 'react-router-dom'
import IOrderPagination from '../../interfaces/IOrderPagination'

interface PaginationEngine {
  pathname: string
  page: number
  limit: number
  orderBy: string
  direction: string
  limits: Array<number>
  orders: Array<IOrderPagination>
  getSearch: (p: number, l: number, o: string, d: string) => string
  handleOrder: (value: string) => void
  handleLimit: (e: number) => void
  handleDirection: () => void
}

const PaginationEngine: React.FC<PaginationEngine> = ({
  pathname,
  page,
  limit,
  orderBy,
  direction,
  limits,
  orders,
  getSearch,
  handleLimit,
  handleOrder,
  handleDirection,
}) => {
  return (
    <>
      <div className="d-flex flex-row-reverse">
        <div className="row">
          <div className="col-md-2">
            <div className="dropdown">
              <Link
                className="btn  dropdown-toggle"
                to={{}}
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <ImEye />
              </Link>

              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {limits.map(LIMIT => (
                  <li>
                    <Link
                      className="dropdown-item"
                      to={{
                        pathname,
                        search: getSearch(page, LIMIT, orderBy, direction),
                      }}
                      onClick={() => handleLimit(LIMIT)}
                    >
                      {LIMIT}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dropdown">
              <Link
                className="btn  dropdown-toggle"
                to={{}}
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {orders.find(elem => elem.value == orderBy)?.description || ''}
              </Link>

              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {orders.map((o: IOrderPagination) => (
                  <li>
                    <Link
                      className="dropdown-item"
                      to={{
                        pathname,
                        search: getSearch(page, limit, o.value, direction),
                      }}
                      onClick={() => handleOrder(o.value)}
                    >
                      {o.description}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-2 ">
            <Link
              className="btn"
              to={{
                pathname,
                search: getSearch(
                  page,
                  limit,
                  orderBy,
                  direction == 'ASC' ? 'DESC' : 'ASC',
                ),
              }}
              role="button"
              onClick={() => handleDirection()}
            >
              {direction == 'ASC' ? (
                <AiOutlineArrowDown />
              ) : (
                <AiOutlineArrowUp />
              )}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaginationEngine
