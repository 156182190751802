import { useHistory } from 'react-router'
import './styles.scss'

export const WorkshopBackButton: React.FC = () => {
  const history = useHistory()

  const back: () => void = () => {
    history.push('/workshop')
  }
  return (
    <button className="back" type="button" onClick={back}>
      Voltar para lista de workshops
    </button>
  )
}

export default WorkshopBackButton
