import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'

import api from '../services/api'

import IOkr, {
  IOkrFormModel,
  IOkrListRequest,
  OkrList,
  TMetaKpi,
} from '../interfaces/IOkr'
import IKPI from '../interfaces/IKPI'
import errorHandler from '../utils/errorHandler'
import IPagination from '../interfaces/IPagination'
import { SearchPagination } from '../interfaces/ISearchPagination'

// export interface okrList {
//   okrs: Array<IOkr>
// }

export interface okrById {
  okr: IOkr
}

const apiRoute = 'okrs'

interface OkrContextData {
  COkr: IOkr
  setCOkr: Dispatch<SetStateAction<IOkr>>
  COkrList: IPagination<IOkr>
  setCOkrList: Dispatch<SetStateAction<IPagination<IOkr>>>
  IsCOkrListReloaded: boolean
  setIsCOkrListReloaded: Dispatch<SetStateAction<boolean>>
  CKpi: IKPI
  setCKpi: Dispatch<SetStateAction<IKPI>>
  metaKpi: TMetaKpi
  setMetaKpi: Dispatch<SetStateAction<TMetaKpi>>
  getAll: (search: SearchPagination) => Promise<IPagination<IOkr>>
  getById(obj: string): Promise<IOkr>
  Add: (obj: IOkrFormModel) => Promise<IOkr>
  Update: (obj: IOkr) => Promise<IOkr>
  UpdateFormOkr: (obj: IOkrFormModel) => Promise<IOkr>
  Delete: (obj: IOkr) => Promise<void>
  okrList: Array<IOkr>
  setOkrList: Dispatch<SetStateAction<Array<IOkr>>>
}

const OkrContext = createContext<OkrContextData>({} as OkrContextData)

const OkrProvider: React.FC = ({ children }) => {
  const [COkr, setCOkr] = useState<IOkr>({} as IOkr)
  const [COkrList, setCOkrList] = useState<IPagination<IOkr>>(
    {} as IPagination<IOkr>,
  )
  const [IsCOkrListReloaded, setIsCOkrListReloaded] = useState<boolean>(false)
  const [CKpi, setCKpi] = useState<IKPI>({} as IKPI)
  const [metaKpi, setMetaKpi] = useState<TMetaKpi>({} as TMetaKpi)

  const [okrList, setOkrList] = useState([] as Array<IOkr>)

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = useCallback(
    async (search: SearchPagination): Promise<IPagination<IOkr>> => {
      try {
        const { data } = await api.get<IPagination<IOkr>>(apiRoute, {
          params: { page: search.page, limit: search.limit },
        })
        return data
      } catch (error) {
        // console.log(`getAll ${error}`)
        errorHandler('Ocorreu um erro ao listar as okrs')
        return {} as IPagination<IOkr>
      }
    },
    [],
  )

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = useCallback(async (ob: string): Promise<IOkr> => {
    try {
      const { data } = await api.get(`${apiRoute}/${ob}`)
      return data
    } catch (error) {
      errorHandler('Ocorreu um erro ao buscar essa okr')
      return {} as IOkr
    }
  }, [])

  /**
   * Adiciona um registro no DB
   * @param ob: IObject
   */
  const Add = useCallback(async (ob: IOkrFormModel): Promise<IOkr> => {
    try {
      const { data } = await api.post(apiRoute, ob)
      return data
    } catch (error) {
      errorHandler('Ocorreu um erro ao criar essa okr')
      return Promise.reject(error)
    }
  }, [])

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  const Update = useCallback(async (ob: IOkr): Promise<IOkr> => {
    try {
      const { data } = await api.put(`${apiRoute}/${ob._id}`, ob)

      return data
    } catch (error) {
      errorHandler('Ocorreu um erro ao atualizar essa okr')
      return Promise.reject(error)
    }
  }, [])

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  const UpdateFormOkr = useCallback(
    async (ob: IOkrFormModel): Promise<IOkr> => {
      try {
        const { data } = await api.put(`${apiRoute}/${ob._id}`, ob)

        return data
      } catch (error) {
        errorHandler('Ocorreu um erro ao atualizar essa okr')
        return Promise.reject(error)
      }
    },
    [],
  )

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  // eslint-disable-next-line consistent-return
  const Delete = useCallback(async (ob: IOkr): Promise<void> => {
    try {
      await api.delete(`${apiRoute}/${ob._id}`)
    } catch (error) {
      errorHandler('Ocorreu um erro ao deletar essa okr')
    }
  }, [])

  return (
    <OkrContext.Provider
      value={{
        COkr,
        setCOkr,
        COkrList,
        setCOkrList,
        IsCOkrListReloaded,
        setIsCOkrListReloaded,
        CKpi,
        setCKpi,
        metaKpi,
        setMetaKpi,
        getAll,
        getById,
        Add,
        Update,
        Delete,
        UpdateFormOkr,
        okrList,
        setOkrList,
      }}
    >
      {children}
    </OkrContext.Provider>
  )
}

export default OkrProvider

export function useOkr(): OkrContextData {
  const context = useContext(OkrContext)

  return context
}
