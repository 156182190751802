import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import CardItem from '../../../components/Workshop/CardItem'
import { WorkshopHeader } from '../../../components/Workshop/WorkshopHeader'
import ModalAddIdea from '../../../components/Workshop/ModalAddIdea'
import { WorkshopInitCounter } from '../../../components/Workshop/WorkshopInitCounter'
import './styles.scss'

import useWorkshopIdeas, {
  TProblemCard,
  TIdeaCard,
} from '../../../hooks/workshop/useWorkshopIdeas'
import { WorkshopContext } from '../WorkshopStageManager'
import { WorkshopAdvanceButton } from '../../../components/Workshop/WorkshopAdvanceButton'
import { WorkshopBackButton } from '../../../components/Workshop/WorkshopBackButton'
import { WorkshopCardFooterFactory } from '../../../components/Workshop/WorkshopCardFooterFactory'
import getParsedToken, { IToken } from '../../../utils/getParsedToken'
import AuthContext from '../../../context/auth'
import dateToString from '../../../utils/dateToString'

export const ScoreResult: React.FC = () => {
  // console.log('ACESSO')

  const { workshop, nextStage } = useContext(WorkshopContext)
  const { user } = useContext(AuthContext)
  const {
    createCard,
    problemCards,
    ideasCards,
    started,
    onLike,
    impactVote,
    trustVote,
    hookPermission,
  } = useWorkshopIdeas()
  const [impact, setImpact] = useState('')
  const [trust, setTrust] = useState('')
  const [state, setState] = useState<Array<any>>([])
  const parsedToken = getParsedToken() || ({} as IToken)

  const [problemCard, setProblemCard] = useState<TProblemCard>(
    {} as TProblemCard,
  )

  const onStart = useCallback((): void => {
    nextStage()
  }, [nextStage])

  const averageVotes = useCallback((card: TIdeaCard) => {
    return (
      (card.impact.rating + card.trust.rating + card.engage.rating) /
      3
    ).toFixed(2)
  }, [])

  useEffect(() => {
    hookPermission('list')
  }, [])

  return (
    <div className="workshop-ideas">
      <div className="container-scroll">
        <WorkshopHeader
          title={`Resultados do Workshop ${workshop?.name}`}
          description={`${workshop?.department?.name} - ${
            workshop?.okr?.name
          } - ${workshop?.kpi?.name} - Concluído em ${dateToString(
            workshop?.createdAt,
          )}`}
          RightComponent={started ? WorkshopBackButton : undefined}
        />

        {!started && <WorkshopInitCounter onClick={onStart} />}

        <div className="row">
          {/* {console.log(`refresh do real time ${problemCards}`)} */}
          {problemCards?.map(problemCardList => (
            <div className="col-md-4" key={problemCardList?.id}>
              <div className="problem-container">
                <span>&quot;{problemCardList?.description}&quot;</span>
              </div>
              {/* {console.log(problemCards)} */}
              {/* {console.log(ideasCards)} */}
              {ideasCards?.map((ideaCard, ideaCardIndex) => {
                // console.log(ideaCard)
                return (
                  problemCardList.id == ideaCard.problem._id && (
                    <div className="problem-container-score" key={ideaCard.id}>
                      <div className="col-md-6" key={ideaCard.id}>
                        <div className="description-score">
                          {ideaCard.description}
                        </div>
                        <span className="priority">Prioridade</span>
                        <div className="score">
                          <div className="span-score">
                            {averageVotes(ideaCard)}
                          </div>
                        </div>
                        {/* <div className="container-score">
                          <span className="description-score">
                            {ideaCard.description}
                          </span>
                          <div className="priority">Confiança</div>
                          <div className="score">
                            <div className="span-score">
                              {' '}
                              {ideaCard?.trust?.rating &&
                                ideaCard?.trust?.rating}
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="container-score">
                          <span className="description-score">
                            {ideaCard.description}
                          </span>
                          <div className="priority">Facilidade</div>
                          <div className="score">
                            <div className="span-score">
                              {' '}
                              {ideaCard?.engage?.rating &&
                                ideaCard?.engage?.rating}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )
                )
              })}
            </div>
          ))}
        </div>

        <ModalAddIdea id={problemCard.id} />
      </div>
    </div>
  )
}

export default ScoreResult
