import React, { useMemo } from 'react'
import { WorkshopCountdown } from '../WorkshopCountdown'
import './styles.scss'

interface IWorkshopHeaderProps {
  title: string
  description: string
  RightComponent?: React.FC
  buttonProps?: {
    icon?: React.FC
    text: string
    props: Record<string, unknown>
  }
}

export const WorkshopHeader: React.FC<IWorkshopHeaderProps> = ({
  title,
  description,
  RightComponent = () => null,
  buttonProps,
}) => {
  const Icon = useMemo(
    () => (buttonProps && buttonProps.icon ? buttonProps.icon : () => null),
    [buttonProps],
  )

  return (
    <div className="components-workshop-header">
      <div>
        <h1 className="title">{title}</h1>

        <span className="description">{description}</span>

        {buttonProps && (
          <button
            type="button"
            className="action-button"
            {...buttonProps.props}
          >
            <Icon />
            <span>{buttonProps.text}</span>
          </button>
        )}
      </div>
      <div>
        <WorkshopCountdown />
        <RightComponent />
      </div>
    </div>
  )
}

export default WorkshopHeader
