import React, { FormEvent, useCallback, useState } from 'react'

import useWorkshopIdeas, {
  TProblemCard,
} from '../../../hooks/workshop/useWorkshopIdeas'
import { PortalModal } from '../../PortalModal'

import './styles.scss'

const ModalAddIdea: React.FC<TProblemCard> = ({ id }: TProblemCard) => {
  const { createCard } = useWorkshopIdeas()

  const [description, setDescription] = useState('')

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault()

      createCard(id, description)
      setDescription('')
    },
    [id, createCard, description],
  )

  return (
    <>
      <PortalModal>
        <div
          className="modal fade"
          id="modalAddIdea"
          aria-labelledby="modalAddIdea"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Ideia
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="input-content  objective">
                    <label htmlFor="basic-url" className="form-label">
                      O que podemos fazer?
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      onChange={e => {
                        setDescription(e.target.value)
                      }}
                      value={description}
                    />
                    {/* <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={e => {
                          setDescription(e.target.value)
                        }}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Enviar
                  </button>
                  <button
                    id="workshop-close-modal"
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </PortalModal>
    </>
  )
}

export default ModalAddIdea
