import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { BsFillHouseFill } from 'react-icons/bs'

import { FaLightbulb } from 'react-icons/fa'

import { FiTarget, FiKey, FiFileText } from 'react-icons/fi'

import {
  AiOutlineDashboard,
  AiOutlineCheckSquare,
  AiFillSetting,
} from 'react-icons/ai'

import {
  RiDatabase2Fill,
  RiArtboardLine,
  RiSpaceShipFill,
  RiMenuUnfoldFill,
  RiMenuUnfoldLine,
  RiMenuLine,
  RiMenuFoldLine,
} from 'react-icons/ri'

import { MdVideoLibrary } from 'react-icons/md'

import { GrConfigure } from 'react-icons/gr'

import { useTheme } from '../../hooks/theme'

import borderLeft from '../../assets/img/menu/border-left.svg'
import logo from '../../assets/img/header/logo.png'

import './styles.scss'

const Menu: React.FC = () => {
  // const [isRetractable, setIsRetractable] = useState<boolean>(false)

  const {
    backgroundColorGeral,
    colorMenuSection,
    colorItemMenu,
    cssMenu,
    changeThemeToggle,
    isRetractable,
    setIsRetractable,
  } = useTheme()

  const checkCss = changeThemeToggle
    ? cssMenu
    : { backgroundColor: backgroundColorGeral }

  return (
    <>
      <div
        className={isRetractable ? 'menu menu-retractable' : 'menu'}
        style={checkCss}
      >
        <ul>
          {/* <li> */}
          {/* <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon"> */}
          {/* <Link style={{ color: colorMenuSection }} to="/">
                <BsFillHouseFill />
              </Link> */}
          {/* </div> */}
          {/* <div className="titulo" /> */}
          {/* </li> */}
          <li className="separador" />
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/okr">
                <FiTarget />
              </Link>
            </div>
            <div className="titulo" />
          </li>
          {/* <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/okr/keyResults">
                <FiKey />
              </Link>
            </div>
            <div className="titulo" />
          </li> */}
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/kpi/">
                <AiOutlineDashboard />
              </Link>
            </div>
            <div className="titulo" />
          </li>
          <li className="separador" />
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/inspiration">
                <MdVideoLibrary />
              </Link>
            </div>
            <div className="titulo" />
          </li>
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/workshop">
                <RiArtboardLine />
              </Link>
            </div>
            <div className="titulo" />
          </li>
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/idea">
                <FaLightbulb />
              </Link>
            </div>
            <div className="titulo" />
          </li>
          <li className="separador" />
          {/* <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/diagnostic">
                <FiFileText />
              </Link>
            </div>
            <div className="titulo" />
          </li> */}
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/experiments">
                <AiOutlineCheckSquare />
              </Link>
            </div>
            <div className="titulo" />
          </li>
          {/* <li className="separador" />
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            <div className="icon">
              <Link style={{ color: colorItemMenu }} to="/configuration">
                <AiFillSetting />
              </Link>
            </div>
            <div className="titulo" />
          </li> */}
        </ul>
        <ul>
          <li>
            <div className="borda">
              <img src={borderLeft} alt="" />
            </div>
            {!isRetractable && (
              <div className="retractable-switch">
                <button
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasScrolling"
                  aria-controls="offcanvasScrolling"
                  style={{ color: colorItemMenu }}
                  onClick={e => setIsRetractable(true)}
                >
                  <RiMenuUnfoldLine />
                </button>
              </div>
            )}
          </li>
        </ul>

        <div
          className="offcanvas offcanvas-start"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          id="offcanvasScrolling"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <ul>
            {/* <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div> */}
            {/* <div className="icon">
                <BsFillHouseFill />
              </div> */}
            {/* <div className="titulo">
                <Link style={{ color: colorMenuSection }} to="/">
                  Painel Principal
                </Link>
              </div> */}
            {/* </li> */}
            <li className="separador">
              <div style={{ color: colorMenuSection }}>MAPEAMENTO</div>
            </li>
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <FiTarget />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/okr">
                  Objetivos
                </Link>
              </div>
            </li>
            {/* <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <FiKey />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/okr/keyResults">
                  Resultados Chave
                </Link>
              </div>
            </li> */}
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <AiOutlineDashboard />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/kpi/">
                  Indicadores Macro
                </Link>
              </div>
            </li>
            <li className="separador">
              <div style={{ color: colorMenuSection }}>IDEAÇÃO</div>
            </li>
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <MdVideoLibrary />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/inspiration">
                  Inspiração
                </Link>
              </div>
            </li>
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <RiArtboardLine />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/workshop">
                  Workshop
                </Link>
              </div>
            </li>
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <FaLightbulb />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/idea">
                  Banco de Ideias
                </Link>
              </div>
            </li>
            <li className="separador">
              <div style={{ color: colorMenuSection }}>SOLUÇÃO</div>
            </li>
            {/* <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <FiFileText />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/diagnostic">
                  Diagnóstico
                </Link>
              </div>
            </li> */}
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <AiOutlineCheckSquare />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/experiments">
                  Experimentos
                </Link>
              </div>
            </li>
            {/* <li className="separador">
              <div style={{ color: colorMenuSection }}>PREFERÊNCIAS</div>
            </li> */}
            {/* <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              <div className="icon">
                <AiFillSetting />
              </div>
              <div className="titulo">
                <Link style={{ color: colorItemMenu }} to="/configuration">
                  Configuração
                </Link>
              </div>
            </li> */}
          </ul>
          <ul>
            <li>
              <div className="borda">
                <img src={borderLeft} alt="" />
              </div>
              {console.log(isRetractable)}
              {isRetractable && (
                <div className="retractable-switch">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasScrolling"
                    aria-controls="offcanvasScrolling"
                    style={{ color: colorItemMenu }}
                    onClick={e => setIsRetractable(false)}
                  >
                    <RiMenuFoldLine />
                  </button>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Menu
