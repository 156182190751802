import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import CardItem from '../../../components/Workshop/CardItem'
import { WorkshopHeader } from '../../../components/Workshop/WorkshopHeader'
import ModalAddIdea from '../../../components/Workshop/ModalAddIdea'
import { WorkshopInitCounter } from '../../../components/Workshop/WorkshopInitCounter'
import './styles.scss'

import useWorkshopIdeas, {
  TProblemCard,
} from '../../../hooks/workshop/useWorkshopIdeas'
import { WorkshopContext } from '../WorkshopStageManager'
import { WorkshopAdvanceButton } from '../../../components/Workshop/WorkshopAdvanceButton'
import { WorkshopCardFooterFactory } from '../../../components/Workshop/WorkshopCardFooterFactory'
import getParsedToken, { IToken } from '../../../utils/getParsedToken'

export const WorkshopIdeas: React.FC = () => {
  // console.log('ACESSO')

  const { workshop, nextStage } = useContext(WorkshopContext)
  const {
    createCard,
    problemCards,
    ideasCards,
    started,
    onLike,
    hookPermission,
  } = useWorkshopIdeas()
  const parsedToken = getParsedToken() || ({} as IToken)

  const [problemCard, setProblemCard] = useState<TProblemCard>(
    {} as TProblemCard,
  )

  const disabledButton = !started

  const onStart = useCallback((): void => {
    nextStage()
  }, [nextStage])

  const handleAddIdea = useCallback(problemCardHandle => {
    setProblemCard(problemCardHandle)
  }, [])

  useEffect(() => {
    hookPermission('list')
  }, [])

  return (
    <div className="workshop-ideas">
      <div className="container-scroll">
        <WorkshopHeader
          title="Etapa 3: ideias para solução"
          description="O que podemos fazer para superar ou resolver nossas dores e problemas?"
          RightComponent={started ? WorkshopAdvanceButton : undefined}
        />

        {!started && <WorkshopInitCounter onClick={onStart} />}

        <div className="row">
          {/* {console.log(`refresh do real time ${problemCards}`)} */}
          {problemCards?.map(problemCardList => (
            <div className="col-md-4" key={problemCardList?.id}>
              <div className="problem-container">
                <span>&quot;{problemCardList?.description}&quot;</span>
                <button
                  type="button"
                  className={
                    workshop.stage > 7
                      ? 'problem-button-disable'
                      : 'problem-button'
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#modalAddIdea"
                  disabled={workshop.stage > 7}
                  onClick={e => handleAddIdea(problemCardList)}
                >
                  <AiOutlinePlus size={22} color="#FFF" />
                  &nbsp;Criar ideia
                </button>
              </div>
              <div className="row row-idea">
                {/* {console.log(problemCards)} */}
                {/* {console.log(ideasCards)} */}
                {ideasCards?.map(ideaCard => {
                  // console.log(ideaCard)
                  return (
                    problemCardList?.id == ideaCard?.problem?._id && (
                      <div className="col-md-6" key={ideaCard?._id}>
                        <div className="idea-container">
                          <CardItem
                            className="idea-card"
                            description={ideaCard?.description}
                            backgroundColor={ideaCard?.hexadecimalColor}
                            rotate="0"
                            Footer={() => (
                              <WorkshopCardFooterFactory
                                stage={workshop.stage}
                                onClick={() => onLike(ideaCard)}
                                likes={ideaCard?.likedBy?.length}
                                iLike={
                                  ideaCard?.likedBy?.indexOf(
                                    parsedToken?.id,
                                  ) !== -1
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          ))}
        </div>

        <ModalAddIdea id={problemCard?.id} />
      </div>
    </div>
  )
}

export default WorkshopIdeas
