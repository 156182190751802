import React from 'react'

import { Link } from 'react-router-dom'

import { useTheme } from '../../hooks/theme'

import './styles.scss'

const MenuOrganization: React.FC = () => {
  const {
    backgroundColorGeral,
    colorMenuSection,
    colorItemMenu,
    cssMenu,
    changeThemeToggle,
  } = useTheme()

  const checkCss = changeThemeToggle
    ? cssMenu
    : { backgroundColor: backgroundColorGeral }

  return (
    <>
      <div className="menu" style={checkCss}>
        <ul>
          <li className="separador">
            <div style={{ color: colorMenuSection }}>Áreas</div>
          </li>
          <li>
            <div className="titulo">
              <Link style={{ color: colorItemMenu }} to="/">
                Geral
              </Link>
            </div>
          </li>
          <li>
            <div className="titulo">
              <Link style={{ color: colorItemMenu }} to="/">
                RH
              </Link>
            </div>
          </li>
          <li>
            <div className="titulo">
              <Link style={{ color: colorItemMenu }} to="/">
                + Nova área
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default MenuOrganization
