import React, { FormEvent, useCallback, useEffect } from 'react'
import ModalDeleteExperiment from '../ModalDeleteExperiment'

import { useEperiment } from '../../../hooks/experiments'

import './styles.scss'

const ModalExperimentsView: React.FC = () => {
  document.body.classList.add('experiments-modal')

  const {
    cKanbanColumnName,
    cIdeaIndex,
    cKanbanColumnIndex,
    cExperimentIndex,
    cKanbanIdea,
    CExperimentList,
    setIsExperimentListReloded,
    Update,
  } = useEperiment()

  const handleMoveToLeft = useCallback(async () => {
    if (cKanbanColumnName != 'A iniciar') {
      CExperimentList[cExperimentIndex].kanban[
        cKanbanColumnIndex
      ].column.ideas.splice(cIdeaIndex, 1)
      CExperimentList[cExperimentIndex].kanban[
        cKanbanColumnIndex - 1
      ].column.ideas.push(cKanbanIdea)
      await Update(CExperimentList[cExperimentIndex])
      setIsExperimentListReloded(false)
    }
  }, [
    CExperimentList,
    Update,
    cExperimentIndex,
    cIdeaIndex,
    cKanbanColumnIndex,
    cKanbanColumnName,
    cKanbanIdea,
    setIsExperimentListReloded,
  ])
  const handleMoveToRight = useCallback(async () => {
    if (cKanbanColumnName != 'Concluído') {
      CExperimentList[cExperimentIndex].kanban[
        cKanbanColumnIndex
      ].column.ideas.splice(cIdeaIndex, 1)
      CExperimentList[cExperimentIndex].kanban[
        cKanbanColumnIndex + 1
      ].column.ideas.push(cKanbanIdea)
      await Update(CExperimentList[cExperimentIndex])
      setIsExperimentListReloded(false)
    }
  }, [
    CExperimentList,
    Update,
    cExperimentIndex,
    cIdeaIndex,
    cKanbanColumnIndex,
    cKanbanColumnName,
    cKanbanIdea,
    setIsExperimentListReloded,
  ])

  return (
    <>
      <div
        className="modal fade"
        id="modalExperimentsView"
        aria-labelledby="modalExperimentsView"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Idéia
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <span className="span-modal">Idéia</span>
                    <p className="p-modal">{cKanbanIdea?.description}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Objetivo</span>
                    <p className="p-modal">{cKanbanIdea?.okr?.name}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Impacto esperado</span>
                    <p className="p-modal">{cKanbanIdea?.impact}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Confiança</span>
                    <p className="p-modal">{cKanbanIdea?.trust}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Facilidade</span>
                    <p className="p-modal">{cKanbanIdea?.engage}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Média</span>
                    <p className="p-modal">
                      {(
                        (cKanbanIdea?.engage +
                          cKanbanIdea?.trust +
                          cKanbanIdea?.impact) /
                        3
                      ).toFixed()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteExperiment"
                >
                  Remover experimento
                </button>
                {cKanbanColumnName && cKanbanColumnName != 'A iniciar' ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-bs-dismiss="modal"
                    onClick={e => handleMoveToLeft()}
                  >
                    Retornar para etapa anterior
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-bs-dismiss="modal"
                    disabled
                  >
                    Retornar para etapa anterior
                  </button>
                )}

                {cKanbanColumnName && cKanbanColumnName != 'Concluido' ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-bs-dismiss="modal"
                    onClick={e => handleMoveToRight()}
                  >
                    Mover para próxima etapa
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-bs-dismiss="modal"
                    disabled
                  >
                    Mover para próxima etapa
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalDeleteExperiment />
    </>
  )
}

export default ModalExperimentsView
