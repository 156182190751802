import React, { useEffect } from 'react'

import Header from '../../components/Header'
import Menu from '../../components/Menu'

import { useTheme } from '../../hooks/theme'

import './styles.scss'

const Inspiration: React.FC = () => {
  const { isRetractable, setIsRetractable } = useTheme()

  useTheme().addPageStyle('view-inspiration')
  useTheme().changeTheme()

  useEffect(() => {
    setIsRetractable(false)
  }, [])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div
          className={isRetractable ? 'wrapper wrapper-retractable' : 'wrapper'}
        >
          <div className="container">
            <main>
              <h1 className="training">Inspiração</h1>
              <h2>Treinamentos de Apoio</h2>
              <ul>
                <li>
                  <div className="video">
                    <iframe
                      width="330"
                      height="245"
                      src="https://www.youtube.com/embed/4mbfX4IXkhg"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    Imersão torne-se Growth Hacker
                  </div>
                </li>
                <li>
                  <div className="video">
                    <iframe
                      width="330"
                      height="245"
                      src="https://www.youtube.com/embed/K4vKP_y9DiA"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    Treinamento em Growth Hacking o mais completo do Brasil
                  </div>
                </li>
                <li>
                  <div className="video">
                    <iframe
                      width="330"
                      height="245"
                      src="https://www.youtube.com/embed/UV9JAAFiR68"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    O que fazer para aumentar a Aquisição de Clientes?
                  </div>
                </li>
                {/* <li>
                  <div className="video">
                    <iframe
                      width="350"
                      height="265"
                      src="https://www.youtube.com/embed/13Kw0hOVrfk"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    Como monitorar a concorrência na rede social
                  </div>
                </li> */}
              </ul>

              <h2>Vídeos Inspiracionais</h2>
              <ul>
                <li>
                  <div className="video">
                    <iframe
                      width="330"
                      height="245"
                      src="https://www.youtube.com/embed/yyoSiItnjJU"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    Depoimento - Gabriela Souza
                  </div>
                </li>
                <li>
                  <div className="video">
                    <iframe
                      width="330"
                      height="245"
                      src="https://www.youtube.com/embed/0U5dS7S7Le4"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    Depoimento - Cristiano Lenz
                  </div>
                </li>
                <li>
                  <div className="video">
                    <iframe
                      width="330"
                      height="245"
                      src="https://www.youtube.com/embed/Sm_dxEPkNSo"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    Depoimento - Bruna Dias
                  </div>
                </li>
                {/* <li>
                  <div className="video">
                    <iframe
                      width="350"
                      height="265"
                      src="https://www.youtube.com/embed/UV9JAAFiR68"
                      title="YouTube video player"
                      frameBorder="0"
                      eslint-disable-next-line
                      max-len
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="description-video">
                    O que fazer para aumentar a Aquisição de Clientes?
                  </div>
                </li> */}
              </ul>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default Inspiration
