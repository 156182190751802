import React, { useState, useEffect, useMemo } from 'react'
import { Serie } from '@nivo/line'
import { useOkr } from '../../../hooks/okr'
import LineChart from '../../LineChart'
import Modal from '../../Modal'

const ModalKpiDetails = () => {
  const { CKpi, metaKpi } = useOkr()
  const [data, setData] = useState([])
  useMemo(() => {
    const { updates } = metaKpi
    const { name } = CKpi

    if (metaKpi?.updates) {
      // console.log(updates)
      const monthNames = [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ]
      const chartData = [
        {
          id: name,
          color: 'hsla(119, 100%, 48%, 1)',
          data: monthNames.map(month => ({
            x: month,
            y: updates.reduce((acc, curr) => {
              return new Date(curr.date).toLocaleString('pt-BR', {
                month: 'long',
              }) === month
                ? acc + curr.value
                : acc
            }, 0),
          })),
        },
      ]
      setData(chartData)
    }
  }, [metaKpi, CKpi])
  return (
    <Modal id="detailsModal" title="Detalhes" size="large">
      <div style={{ height: '300px', width: '100%' }}>
        <LineChart data={data} goal={metaKpi.value} />
      </div>
    </Modal>
  )
}

export default ModalKpiDetails
