import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import IIdeaBase from '../interfaces/IIdeaBase'

import api from '../services/api'
import errorHandler from '../utils/errorHandler'

const apiRoute = 'ideasbase'

interface IIdeaContextData {
  ideasBaseList: IIdeaBase[]
  setIdeaBaseList: Dispatch<SetStateAction<IIdeaBase[]>>
  getAll: () => Promise<IIdeaBase[]>
}

const IdeaBaseContext = createContext<IIdeaContextData>({} as IIdeaContextData)

const IdeiaBBaseProvider: React.FC = ({ children }) => {
  const [ideasBaseList, setIdeaBaseList] = useState<IIdeaBase[]>([])

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = useCallback(async (): Promise<IIdeaBase[]> => {
    try {
      const result = await api.get(apiRoute).then(response => {
        return response.data
      })
      setIdeaBaseList(result)
      return result
    } catch (error) {
      errorHandler('Ocorreu um erro ao listar as ideias')
      return [] as IIdeaBase[]
    }
  }, [])

  return (
    <IdeaBaseContext.Provider
      value={{
        ideasBaseList,
        setIdeaBaseList,
        getAll,
      }}
    >
      {children}
    </IdeaBaseContext.Provider>
  )
}

export default IdeiaBBaseProvider

export function useIdeaBase(): IIdeaContextData {
  const context = useContext(IdeaBaseContext)

  return context
}
