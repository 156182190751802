import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'

import api from '../services/api'

//
//
/**
 * A interface deve ficar em um arquivo separado, pois ela determina os atributos do objeto
 * Usando o export default, o nome pode ser genérico, alterando apenas o endereço da interface a ser utilizada
 */
import IObject from '../interfaces/IKPIBase'
import IKPI, { IKPICreateRecord } from '../interfaces/IKPI'
import errorHandler from '../utils/errorHandler'
import IPagination from '../interfaces/IPagination'
import { SearchPagination } from '../interfaces/ISearchPagination'

// Alterar para o nome da Model (que é o mesmo nome da rota)
const apiRoute = 'kpis'

// Não precisa alterar, é igual para todos
interface ThisProviderProps {
  children: ReactNode
}

interface IModel {
  name: string
  description: string
  fullName: string
  formula: string
  unit: string
  direction: string
  department: {
    _id: string
  }
  company: {
    _id: string
  }
}

/**
 * Métodos exportados pelo Context
 */
interface ThisContextData {
  getAll: (search: SearchPagination) => Promise<IPagination<IKPI>>
  getById: (id: number | string) => Promise<IKPI>
  kpiList: IPagination<IKPI>
  setKpiList: Dispatch<SetStateAction<IPagination<IKPI>>>
  Add: (
    obj: Omit<IObject, '_id' | 'pillar' | 'department'>,
  ) => Promise<IObject | unknown>
  Edit: (obj: Omit<IKPI, 'department' | 'updates'>) => Promise<IKPI | unknown>
  CreateRecordValue: (kpiId: string, obj: IKPICreateRecord) => Promise<IKPI>
  kpis: Array<IKPI>
  setKpis: Dispatch<SetStateAction<Array<IKPI>>>
  isKpiLoaded: boolean
  setIsKpiLoaded: Dispatch<SetStateAction<boolean>>
}

const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const KpisProvider: React.FC = ({ children }) => {
  const [kpiList, setKpiList] = useState<IPagination<IKPI>>(
    {} as IPagination<IKPI>,
  )

  const [kpis, setKpis] = useState<Array<IKPI>>([])
  const [isKpiLoaded, setIsKpiLoaded] = useState(false)

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = async (
    search: SearchPagination,
  ): Promise<IPagination<IKPI>> => {
    try {
      const { data } = await api.get(apiRoute, {
        params: { page: search.page, limit: search.limit },
      })
      return data
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao listar as kpis, tente novamente mais tarde',
      )
      return {} as IPagination<IKPI>
    }
  }

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = async (id: number | string): Promise<IKPI> => {
    try {
      const { data } = await api.get(`${apiRoute}/${id}`)
      return data
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao buscar a kpi, tente novamente mais tarde',
      )
      return {} as IKPI
    }
  }

  /**
   * Adiciona um registro no DB
   * @param ob: IObject
   */
  const Add = useCallback(
    async (
      ob: Omit<IObject, '_id' | 'pillar' | 'department'>,
    ): Promise<IObject> => {
      try {
        const { data } = await api.post(apiRoute, ob)
        return data
      } catch (error) {
        errorHandler(
          'Ocorreu um erro ao criar o valor da kpi, tente novamente mais tarde',
        )
        return Promise.reject(error)
      }
    },
    [],
  )

  /**
   * Edita um registro no DB
   * @param ob: IObject
   */
  const Edit = useCallback(
    async (
      ob: Omit<IKPI, 'department' | 'updates'>,
    ): Promise<IKPI | unknown> => {
      try {
        const { data } = await api.put(`${apiRoute}/${ob._id}`, ob)

        return data
      } catch (error) {
        errorHandler(
          'Ocorreu um erro ao editar o valor da kpi, tente novamente mais tarde',
        )
        throw new Error()
      }
    },
    [],
  )

  /**
   * Edita um registro no DB
   * @param ob: IObject
   */
  const CreateRecordValue = useCallback(
    async (kpiId: string, ob: IKPICreateRecord): Promise<IKPI> => {
      try {
        const result = await api
          .post(`${apiRoute}/${kpiId}/record`, ob)
          .then(response => {
            // console.log(`Add >> ${JSON.stringify(response.data)}`)
            return response.data
          })
        return result
      } catch (error) {
        errorHandler(
          'Ocorreu um erro ao editar o valor da kpi, tente novamente mais tarde',
        )
        return {} as IKPI
      }
    },
    [],
  )

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  // async function Update(ob: IObject): Promise<void> {
  //   return api
  //     .put(`${apiRoute}/${ob.id}`, {
  //        department: ob.department,
  //        name: ob.name,
  //        pillar: ob.pillar,
  //        fullName: ob.fullName,
  //        description: ob.description,
  //        formula: ob.formula,
  //        unit: ob.unit,
  //        direction: ob.direction
  //     })
  //     .then(response => {
  //       console.log(`Update >> ${JSON.stringify(response.data)}`)
  //       return response.data
  //     })
  // }

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  // async function Delete(id: number | string): Promise<void> {
  //   return api.delete(`${apiRoute}/${id}`).then(response => {
  //     console.log(`Delete >> ${JSON.stringify(response)}`)
  //   })
  // }

  return (
    <ThisContext.Provider
      value={{
        getAll,
        getById,
        kpiList,
        setKpiList,
        Add,
        Edit,
        CreateRecordValue,
        kpis,
        setKpis,
        isKpiLoaded,
        setIsKpiLoaded,
      }}
    >
      {children}
    </ThisContext.Provider>
  )
}

export default KpisProvider

export function useKpis(): ThisContextData {
  const context = useContext(ThisContext)

  return context
}
