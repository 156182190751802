import React from 'react'
import './styles.scss'

export const WorkshopBackground: React.FC = ({ children }) => {
  return (
    <div className="components-workshop-background">
      <div className="stripe2">{children}</div>
    </div>
  )
}

export default WorkshopBackground
