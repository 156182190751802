/* eslint-disable react/destructuring-assignment */
import { useEffect, useMemo, useState } from 'react'
import LoadingContext from '../../context/LoadindContext'
import {
  addGlobalRequestInterceptor,
  addGlobalResponseInterceptor,
  removeGlobalRequestInterceptor,
  removeGlobalResponseInterceptor,
} from '../../services/api'

// eslint-disable-next-line import/prefer-default-export
export const LoadingProvider: React.FC = props => {
  const [loading, setLoading] = useState<boolean>(false)
  const [countRequest, setCountRequest] = useState(0)

  useMemo(() => {
    let isSubscribed = true
    const requestsIds = addGlobalRequestInterceptor(config => {
      if (
        isSubscribed &&
        config.headers &&
        // eslint-disable-next-line no-prototype-builtins
        !config.headers.hasOwnProperty('x-ignore-loading')
      ) {
        setLoading(true)
        setCountRequest(prevState => prevState + 1)
      }
      return config
    })
    const responseIds = addGlobalResponseInterceptor(
      response => {
        if (
          isSubscribed &&
          (!response.config ||
            // eslint-disable-next-line no-prototype-builtins
            !response.config.headers.hasOwnProperty('x-ignore-loading'))
        ) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          decrementCountRequest()
        }
        return response
      },
      error => {
        if (
          isSubscribed &&
          (!error.config ||
            // eslint-disable-next-line no-prototype-builtins
            !error.config.headers.hasOwnProperty('x-ignore-loading'))
        ) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          decrementCountRequest()
        }
        return Promise.reject(error)
      },
    )
    return () => {
      isSubscribed = false
      removeGlobalRequestInterceptor(requestsIds)
      removeGlobalResponseInterceptor(responseIds)
    }
  }, [true])

  /**
   * Existe para garantir que requisições subsequentes não anulem o loading uma das outras
   * */
  useEffect(() => {
    if (!countRequest) {
      setLoading(false)
    }
  }, [countRequest])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function decrementCountRequest() {
    setCountRequest(prevState => prevState - 1)
  }

  return (
    <LoadingContext.Provider value={loading}>
      {props.children}
    </LoadingContext.Provider>
  )
}
