import { useContext, useCallback } from 'react'

import { WorkshopHeader } from '../../../components/Workshop/WorkshopHeader'
import { WorkshopInvite } from '../../../components/Workshop/WorkshopInvite'
import { useTheme } from '../../../hooks/theme'
import { WorkshopContext } from '../WorkshopStageManager'

import './styles.scss'

export const WorkshopWaitingInit: React.FC = () => {
  useTheme().addPageStyle('view-workshop')
  useTheme().changeTheme()
  const { workshop, nextStage, isAdmin } = useContext(WorkshopContext)

  const onClickInitWorkshop = useCallback((): void => {
    nextStage()
  }, [nextStage])

  return (
    <div className="workshop-waiting-init">
      <WorkshopHeader
        title={workshop?.name}
        description={`${workshop?.department?.name} - ${workshop?.okr?.name} - ${workshop?.kpi?.name}`}
        RightComponent={WorkshopInvite}
      />

      <h1 className="title-workshop-init">Aguardando</h1>

      {isAdmin && (
        <>
          <h2 className="subtitle-workshop-init">
            Você pode iniciar o workshop
            <br />a qualquer momento
          </h2>
          <button
            type="button"
            className="button-init-workshop"
            onClick={e => onClickInitWorkshop()}
          >
            Iniciar Workshop
          </button>
        </>
      )}

      {!isAdmin && (
        <h2 className="subtitle-workshop-init">
          A pessoa responsável pelo workshop
          <br />
          iniciará as dinâmicas a qualquer momento
        </h2>
      )}
    </div>
  )
}

export default WorkshopWaitingInit
