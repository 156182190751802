/* eslint-disable consistent-return */
import React, { createContext, ReactNode, useContext, useCallback } from 'react'

import api from '../services/api'

//
//
/**
 * A interface deve ficar em um arquivo separado, pois ela determina os atributos do objeto
 * Usando o export default, o nome pode ser genérico, alterando apenas o endereço da interface a ser utilizada
 */
import IObject, {
  IDepartmentListRequest,
  DepartmentList,
} from '../interfaces/IDepartment'
import errorHandler from '../utils/errorHandler'
import IPagination from '../interfaces/IPagination'

// Alterar para o nome da Model (que é o mesmo nome da rota)
const apiRoute = 'departments'

// Não precisa alterar, é igual para todos
interface ThisProviderProps {
  children: ReactNode
}

/**
 * Métodos exportados pelo Context
 */
interface ThisContextData {
  getAll: () => Promise<DepartmentList>
  getById: (id: number) => Promise<IObject>
  Add: (obj: IObject) => Promise<void>
  Update: (obj: IObject) => Promise<void>
  Delete: (id: number) => Promise<void>
}

const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const DepartmentProvider: React.FC = ({ children }) => {
  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = useCallback(async (): Promise<DepartmentList> => {
    try {
      const { data } = await api.get<IPagination<IDepartmentListRequest>>(
        apiRoute,
      )
      return data.results.map(item => ({ ...item, id: item._id }))
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao listar departamentos, tente novamente mais tarde',
      )
      return []
    }
  }, [])

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = useCallback(async (id: number): Promise<IObject> => {
    try {
      const { data } = await api.get(`${apiRoute}/${id}`)
      return data
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao buscar esse departamento, tente novamente mais tarde',
      )
      return {} as IObject
    }
  }, [])

  /**
   * Adiciona um registro no DB
   * @param ob: IObject
   */
  const Add = useCallback(async (ob: IObject): Promise<void> => {
    try {
      const { data } = await api.post(apiRoute, ob)
      return data
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao criar esse departamento, verifique os dados e tente novamente',
      )
    }
  }, [])

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  const Update = useCallback(async (ob: IObject): Promise<void> => {
    try {
      const { data } = await api.put(`${apiRoute}/${ob._id}`, {
        name: ob.name,
        description: ob.description,
      })
      return data
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao atualizar esse departamento, verifique os dados e tente novamente',
      )
    }
  }, [])

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  const Delete = useCallback(async (id: number): Promise<void> => {
    try {
      const { data } = await api.delete(`${apiRoute}/${id}`)
      return data
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao deletar esse departamento, tente novamente mais tarde',
      )
    }
  }, [])

  return (
    <ThisContext.Provider value={{ getAll, getById, Add, Update, Delete }}>
      {children}
    </ThisContext.Provider>
  )
}

export default DepartmentProvider

export function useDepartment(): ThisContextData {
  const context = useContext(ThisContext)

  return context
}
