import React from 'react'

import { useBreadcrumb } from '../../hooks/breadcrumbs'

import './styles.scss'

const Breadcrumbs: React.FC = () => {
  const { CBreadcrumbOne, CBreadcrumbTwo, CBreadcrumbCurrent } = useBreadcrumb()

  return (
    <>
      {CBreadcrumbCurrent?.breadcrumbTitle && (
        <div className="breadcrumbs">
          {CBreadcrumbOne?.breadcrumbTitle && (
            <>
              <span className="link">
                <a href={CBreadcrumbOne.breadcrumbLink}>
                  {CBreadcrumbOne.breadcrumbTitle}
                </a>
              </span>
              <span className="divisor"> / </span>
            </>
          )}

          {CBreadcrumbTwo?.breadcrumbTitle && (
            <>
              <span className="link">
                <a href={CBreadcrumbTwo.breadcrumbTitle}>
                  {CBreadcrumbTwo.breadcrumbTitle}
                </a>
              </span>
              <span className="divisor"> / </span>
            </>
          )}

          <span className="atual">{CBreadcrumbCurrent.breadcrumbTitle}</span>
        </div>
      )}
    </>
  )
}

export default Breadcrumbs
