import React, { useCallback, useContext } from 'react'

import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import profileImg from '../../assets/img/menu/profile.svg'
import { useTheme } from '../../hooks/theme'

import './styles.scss'
import AuthContext from '../../context/auth'

const Profile: React.FC = () => {
  document.body.classList.add('profile-component')
  const { setSigned, user } = useContext(AuthContext)
  const history = useHistory()

  const onLogOff = useCallback(() => {
    localStorage.removeItem('token')
    setSigned(false)
    history.push('/login')
  }, [setSigned])
  const { colorHeaderTitle, colorSpanSubTitle } = useTheme()

  return (
    <>
      <div className="profile">
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="img">
              <img src={profileImg} alt="" />
            </div>
            <div className="menu">
              <h3 style={{ color: colorHeaderTitle }}>{user?.name}</h3>
              <span style={{ color: colorSpanSubTitle }}>{user?.role}</span>
            </div>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="/">
                Editar Informações
              </a>
            </li>
            <li>
              <Link className="dropdown-item" to="/change-theme">
                Mudar tema
              </Link>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Mudar Senha
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Atividades de Gerência
              </a>
            </li>
            <li>
              <button
                type="button"
                className="dropdown-item"
                onClick={onLogOff}
              >
                Sair
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Profile
