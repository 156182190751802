import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { CustomRoute } from '../components/CustomRoute'
import { ICustomRoute } from '../interfaces/ICustomRoute'

import RegisterObjective from '../pages/RegisterObjective'
import RegisterCompany from '../pages/Company/Add'
import ViewCompany from '../pages/Company'
import ViewObjective from '../pages/ViewObjective/index'
import Login from '../pages/Login'
import Workshop from '../pages/Workshop'
import Idea from '../pages/Idea'
import UnderConstruction from '../pages/UnderConstruction'
// import ClosedWorkshop from '../pages/_old/ClosedWorkshop'
import Experiments from '../pages/Experiments'
import ChangeTheme from '../pages/Theme'
import WorkshopStageManager from '../pages/Workshop/WorkshopStageManager'
import ViewKPI from '../pages/KPIs'
import RegisterKPI from '../pages/RegisterKPI'
import DetailsKpi from '../pages/KPIs/details'
import Organization from '../pages/Organization'
import OrganizationRegisterd from '../pages/Organization/Registered/index'
import Inspiration from '../pages/inspiration'

const ArrayOfRoutes: ICustomRoute[] = [
  { path: '/login', exact: true, isPublic: true, component: Login },
  { path: '/', exact: true, component: Workshop },
  { path: '/company/add', exact: true, component: RegisterCompany },
  { path: '/company/', exact: true, component: ViewCompany },
  { path: '/okr/add', exact: true, component: RegisterObjective },
  { path: '/okr/update/:id', exact: true, component: RegisterObjective },
  { path: '/okr/', exact: true, component: ViewObjective },
  { path: '/okr/keyresults', exact: true, component: UnderConstruction },
  { path: '/kpi', exact: true, component: ViewKPI },
  { path: '/kpi/add', exact: true, component: RegisterKPI },
  { path: '/kpi/:id', exact: true, component: DetailsKpi },
  { path: '/kpi/update/:id', exact: true, component: RegisterKPI },
  { path: '/idea', exact: true, component: Idea },
  { path: '/workshop', exact: true, component: Workshop },
  { path: '/workshop/:id', exact: true, component: WorkshopStageManager },
  {
    path: '/workshop/:id/problems/:problemId/hypotheses',
    exact: true,
    component: WorkshopStageManager,
  },
  // { path: '/workshop/closed/:id', exact: true, component: ClosedWorkshop },
  { path: '/experiments', exact: true, component: Experiments },
  { path: '/idea/map', exact: true, component: UnderConstruction },
  { path: '/idea/alldata', exact: true, component: UnderConstruction },
  { path: '/diagnostic', exact: true, component: UnderConstruction },
  { path: '/inovation', exact: true, component: UnderConstruction },
  { path: '/configuration', exact: true, component: UnderConstruction },
  { path: '/datasource', exact: true, component: UnderConstruction },
  { path: '/change-theme', exact: true, component: ChangeTheme },
  { path: '/organization', exact: true, component: Organization },
  {
    path: '/organization/registered',
    exact: true,
    component: OrganizationRegisterd,
  },
  { path: '/inspiration', exact: true, component: Inspiration },
]

const Routes: React.FC = () => {
  return (
    <Switch>
      {ArrayOfRoutes.map(route => (
        <CustomRoute key={route.path} {...route} />
      ))}
      <Redirect to="/login?tokenExpired=expired" />
    </Switch>
    // <>
    //   <Switch>
    //     <Route path="/" exact component={UnderConstruction} />
    //     {/* <Route path="/" exact component={Login} /> */}
    //     <Route path="/company/add" exact component={RegisterCompany} />
    //     <Route path="/company/" exact component={ViewCompany} />
    //     {/* <Route path="/main" exact component={Main} /> */}
    //     <Route path="/okr/add" exact component={RegisterObjective} />
    //     <Route path="/okr/update/:id" exact component={RegisterObjective} />
    //     <Route path="/okr/" exact component={ViewObjective} />
    //     <Route path="/okr/keyresults" exact component={UnderConstruction} />
    //     <Route path="/kpi" exact component={UnderConstruction} />
    //     <Route path="/idea" exact component={Idea} />
    //     <Route path="/workshop" exact component={Workshop} />
    //     <Route path="/workshop/:id" exact component={WorkshopStageManager} />
    //     <Route path="/workshop/closed/:id" exact component={ClosedWorkshop} />
    //     <Route path="/experiments" exact component={Experiments} />
    //     {/* Design Thinking */}
    //     <Route path="/idea/map" exact component={UnderConstruction} />
    //     {/* Banco de Ideias */}
    //     <Route path="/idea/alldata" exact component={UnderConstruction} />
    //     <Route path="/diagnostic" exact component={UnderConstruction} />
    //     <Route path="/inovation" exact component={UnderConstruction} />
    //     <Route path="/configuration" exact component={UnderConstruction} />
    //     <Route path="/datasource" exact component={UnderConstruction} />
    //     <Route path="/change-theme" exact component={ChangeTheme} />
    //   </Switch>
    // </>
  )
}

export default Routes
