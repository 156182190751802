import * as Yup from 'yup'

const schema = Yup.object().shape({
  name: Yup.string().required(),
  fullName: Yup.string().required(),
  unit: Yup.string().required(),
  direction: Yup.string().required(),
  description: Yup.string().required(),
  formula: Yup.string().required(),
})

export default schema
