import React, {
  useState,
  useCallback,
  FormEvent,
  useContext,
  useEffect,
} from 'react'

import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'

import './styles.scss'

import logo from '../../assets/img/login/logo.svg'
import facebookIcon from '../../assets/img/login/facebook-icon.svg'
import instagramIcon from '../../assets/img/login/instagram-icon.svg'
import linkdinIcon from '../../assets/img/login/linkdin-icon.svg'

import IUser from '../../interfaces/IUser'
import AuthContext from '../../context/auth'
import 'react-toastify/dist/ReactToastify.css'

const Login: React.FC = () => {
  document.body.classList.add('login')

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { setSigned, Signed, user, setUser, SignIn } = useContext(AuthContext)

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const userForm: IUser = {} as IUser
      userForm.email = email
      userForm.password = password

      const result = await SignIn(userForm)

      if (result?.token) {
        // setUser({ id: 'teste' })
        // console.log(user)
        localStorage.setItem('token', result?.token)
        toast.success('Login com Sucesso')
        setSigned(true)
        // setUser(user)

        // setUser(result.user)
      } else {
        setSigned(false)
        toast.error('Credenciais inválidas', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    },
    [email, password, SignIn, setSigned],
  )

  // useEffect(() => {
  //   if (Signed) {
  //     const userStorage = localStorage.getItem('user') || ''
  //     console.log(userStorage)
  //     setSigned(false)
  //   }
  // }, [Signed, setSigned])

  // useEffect(() => {

  // }, [])

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="login-box">
          <div className="boas-vindas">
            <h1>Boas-vindas</h1>
          </div>
          <div className="acessa-sua-conta">
            <h2>Acesse sua conta</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="email-box">
              <label htmlFor="basic-url" className="form-label">
                E-mail
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM20 12C19.9997 10.2374 19.4173 8.52424 18.3433 7.12666C17.2693 5.72908 15.7637 4.72532 14.0606 4.27131C12.3575 3.8173 10.5521 3.93847 8.92493 4.61598C7.29774 5.2935 5.93986 6.48943 5.06222 8.018C4.18459 9.54656 3.83633 11.3222 4.07156 13.069C4.30679 14.8158 5.11233 16.4361 6.36305 17.6781C7.61376 18.92 9.23963 19.7142 10.9881 19.9371C12.7365 20.1601 14.5096 19.7993 16.032 18.911L15.024 17.184C13.8823 17.8502 12.5524 18.1208 11.2411 17.9536C9.92984 17.7864 8.71046 17.1908 7.77242 16.2594C6.83437 15.328 6.23019 14.1129 6.05371 12.8028C5.87723 11.4927 6.13834 10.161 6.79648 9.01457C7.45462 7.86814 8.47294 6.97114 9.69326 6.46292C10.9136 5.9547 12.2676 5.86371 13.5449 6.20409C14.8223 6.54446 15.9514 7.29715 16.7571 8.34522C17.5627 9.3933 17.9996 10.6781 18 12V13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14C16.7348 14 16.4804 13.8946 16.2929 13.7071C16.1054 13.5196 16 13.2652 16 13V9H14.646C14.0658 8.48826 13.3497 8.15573 12.5844 8.0427C11.8191 7.92966 11.0375 8.04097 10.3342 8.36314C9.63084 8.68531 9.03603 9.20452 8.62178 9.85786C8.20754 10.5112 7.99164 11.2707 8.00023 12.0442C8.00883 12.8178 8.24155 13.5722 8.67021 14.2162C9.09887 14.8602 9.70507 15.366 10.4154 15.6725C11.1257 15.979 11.9096 16.0729 12.6722 15.9429C13.4348 15.8129 14.1434 15.4645 14.712 14.94C15.1093 15.4085 15.6409 15.7437 16.2348 15.9004C16.8288 16.0571 17.4566 16.0276 18.0333 15.8161C18.61 15.6045 19.1078 15.221 19.4595 14.7174C19.8113 14.2137 19.9999 13.6143 20 13V12ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10Z"
                      fill="#CACACC"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="password-box">
              <label htmlFor="basic-url" className="form-label">
                Senha
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8ZM16 8V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8H16ZM11 14V16H13V14H11ZM7 14V16H9V14H7ZM15 14V16H17V14H15Z"
                      fill="#CACACC"
                    />
                  </svg>
                </span>
                <input
                  type="password"
                  className="form-control"
                  id="password-basic-url"
                  aria-describedby="basic-addon3"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            </div>

            <button type="submit">Entrar</button>
          </form>
          <div className="esqueceu-sua-senha">
            <div className="texto">Esqueceu sua senha?</div>
            <div className="solicitar-nova-senha">
              <a href="/">Solicite uma nova</a>
            </div>
          </div>
          <div className="login-footer">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="social-icons">
              <a href="/">
                <img src={facebookIcon} alt="" />
              </a>
              <a href="/">
                <img src={instagramIcon} alt="" />
              </a>
              <a href="/">
                <img src={linkdinIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
