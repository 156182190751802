import { useEffect, useState, createContext, useRef, useCallback } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import useRealtime from '../../../hooks/useRealtime'
import axios from '../../../services/api'

import './styles.scss'

import { Problem } from '../Problem'
import { WorkshopBackground } from '../../../components/Workshop/WorkshopBackground'
import { WorkshopWaitingInit } from '../WorkshopWaitingInit'
import { WorkshopIdeas } from '../WorkshopIdeas'
import { ScoreIce } from '../ScoreIce'
import { ScoreResult } from '../ScoreResult'
import IWorkshop from '../../../interfaces/IWorkshop'
import Header from '../../../components/Header'
import { useTheme } from '../../../hooks/theme'
import getParsedToken from '../../../utils/getParsedToken'
import recordToArray from '../../../utils/recordToArray'
import useWorkshopProblem from '../../../hooks/workshop/useWorkshopProblem'

import { useEperiment } from '../../../hooks/experiments'
import IExperiments from '../../../interfaces/IExperiments'
import IIdea from '../../../interfaces/IIdea'
import { POkr } from '../../../interfaces/IOkr'
import { isUserAdmin } from '../../../utils/getUserRole'

import TIdeaCard from '../../../interfaces/IIdeaCard'

interface IState {
  label: string
  workshop: IWorkshop
}
interface TArgs {
  countdown: number
}

const STEPS: Record<number, React.FC> = {
  0: WorkshopWaitingInit,
  1: Problem, // Aguardando início
  2: Problem, // Criar cards de problemas
  3: Problem, // Aguardando início
  4: Problem, // Like problemas
  5: Problem, // Ranking problemas
  6: WorkshopIdeas, // Aguardando início
  7: WorkshopIdeas, // Criar cards de ideias
  8: WorkshopIdeas, // Aguardando início
  9: WorkshopIdeas, // like ideias
  10: WorkshopIdeas, // Aguardando inicio
  11: WorkshopIdeas, // Ranking ideias
  12: ScoreIce, // Aguardando inicio
  13: ScoreIce, // Score Ice
  14: ScoreResult, // Score Result
}

const likeStages = [4, 9]
const showLikeStages = [5, 11]

interface IWorkshopContext {
  workshop: IWorkshop
  refetch: () => Promise<void>
  nextStage: () => Promise<void>
  prevStage: () => Promise<void>
  isAdmin: boolean
  likeStages: number[]
  showLikeStages: number[]
  isCountdown: boolean
  countdown: string
  setAdvanceButtom: (advance: boolean) => void
  getAdvanceButtom: () => boolean
  setProblemCard: (isCard: boolean) => void
  isProblemCard: () => boolean
  setIdeaCard: (isCard: boolean) => void
  isIdeaCard: () => boolean
}

export const WorkshopContext = createContext<IWorkshopContext>({
  workshop: {} as IWorkshop,
  refetch: () => new Promise(resolve => resolve()),
  nextStage: () => new Promise(resolve => resolve()),
  prevStage: () => new Promise(resolve => resolve()),
  isAdmin: false,
  likeStages: [],
  showLikeStages: [],
  isCountdown: false,
  countdown: '',
  setAdvanceButtom: () => ({}),
  getAdvanceButtom: () => false,
  setProblemCard: () => ({}),
  isProblemCard: () => false,
  setIdeaCard: () => ({}),
  isIdeaCard: () => false,
})

const RealtimeEvents: Record<string, string> = {
  STAGE: 'updated_stage',
  TIMER_PROBLEMS: 'timer_problems',
}

export const WorkshopStageManager: React.FC = () => {
  useTheme().addPageStyle('view-workshop')
  useTheme().changeTheme()

  const [countdown, setCountdown] = useState('05:00')
  const [isCountdown, setIsCountdown] = useState(false)
  const [advance, setAdvance] = useState(false)
  const [isProblemCards, setIsProblemCards] = useState(false)
  const [isIdeaCards, setIsIdeaCards] = useState(false)
  const [ideasResult, setIdeasResult] = useState<IIdea[]>([])
  const { current: isAdmin } = useRef(isUserAdmin())
  const { id } = useParams<{ id: string }>()
  const [state, setState] = useState<IState>({
    label: 'Carregando...',
    workshop: {} as IWorkshop,
  })
  // const { cards } = useWorkshopProblem()
  // const { getIdeasScore, ideasCards } = useWorkshopIdeas()
  // const { Update } = useEperiment()

  const onRealtimeEvent = useCallback((event: string, args: unknown): void => {
    if (event === RealtimeEvents.STAGE) {
      setState(p => ({
        ...p,
        workshop: {
          ...p.workshop,
          stage: args as number,
        },
      }))
    }
    if (event === RealtimeEvents.TIMER_PROBLEMS) {
      const response = args as TArgs
      const time = new Date(response.countdown * 1000)
        .toISOString()
        .substr(14, 5)
      setCountdown(time)
      setIsCountdown(true)
    } else {
      setIsCountdown(false)
    }
  }, [])

  const { emit, socket } = useRealtime(
    recordToArray(RealtimeEvents),
    onRealtimeEvent,
  )

  const startCountdown = useCallback((): void => {
    if (
      state.workshop.stage == 1 ||
      state.workshop.stage == 3 ||
      state.workshop.stage == 6 ||
      state.workshop.stage == 8 ||
      state.workshop.stage == 10 ||
      state.workshop.stage == 12
    ) {
      setIsCountdown(true)
      setCountdown('--:--')

      if (socket.connected) {
        emit('start_problems', {
          workshop: id,
          count: 180,
        })
      }
    } else {
      setIsCountdown(false)
      setCountdown('--:--')
      emit('stop_problems', {
        workshop: id,
        count: 0,
      })
    }
  }, [emit, id, socket.connected, state.workshop.stage])

  const getAdvanceBtn = (): boolean => {
    return advance
  }

  const setAdvanceBtn = (status: boolean): void => {
    setAdvance(status)
  }

  const isProblemCard = (): boolean => {
    return isProblemCards
  }

  const setProblemCard = (status: boolean): void => {
    setIsProblemCards(status)
  }

  const isIdeaCard = (): boolean => {
    return isIdeaCards
  }

  const setIdeaCard = (status: boolean): void => {
    setIsIdeaCards(status)
  }

  const getWorkshop = useCallback(async (): Promise<void> => {
    try {
      const { data } = await axios.get(`/workshops/${id}`)
      // eslint-disable-next-line no-underscore-dangle
      // console.log(data)

      setState(p => ({ ...p, workshop: { ...data, id: data._id } }))
    } catch (err) {
      const { message } = err as Error
      setState(p => ({ ...p, label: message }))
    }
  }, [id])

  const teste = useCallback(async () => {
    const ideasWorkshopResult: TIdeaCard[] = await axios
      .get<TIdeaCard[]>(
        `/workshops/${id}/problems/hypotheses?limit=3&sort=likedBy&direction=-1`,
      )
      .then(response => {
        return response.data
      })

    // if (ideasWorkshopResult) {
    ideasWorkshopResult.map(async idea => {
      // console.log(state.workshop.okr)
      const newIdea: IIdea = {} as IIdea

      newIdea.problem = idea.problem
      newIdea.okr = state.workshop.okr
      newIdea.description = idea.description
      newIdea.impact = idea.impact.rating
      newIdea.engage = idea.engage.rating
      newIdea.trust = idea.trust.rating

      // newIdea.okr = state.workshop.okr

      delete newIdea?.problem?.description
      delete newIdea?.okr?.name

      let ideasPost: any = {}
      ideasPost = await axios.post(`/ideas`, newIdea).then(ideaExperiment => {
        console.log(ideaExperiment)
        return ideaExperiment.data
      })
      console.log(ideasPost)
      ideasResult.push(ideasPost)

      console.log(ideasResult)
    })
  }, [id, ideasResult, state.workshop.okr])

  const nextStage = useCallback(async (): Promise<void> => {
    try {
      setIsCountdown(false)
      startCountdown()

      const stage = state.workshop.stage + 1

      // console.log(state.workshop.stage)

      await axios.put(`/workshops/${id}/stage`, {
        stage,
      })

      if (stage === 14) {
        await axios.put(`/workshops/${id}/status`, {
          status: 'Concluido',
        })

        // const ideasResult: IIdea[] = []

        const ideasWorkshopResult: TIdeaCard[] = await axios
          .get<TIdeaCard[]>(
            `/workshops/${id}/problems/hypotheses?limit=3&sort=likedBy&direction=-1`,
          )
          .then(response => {
            return response.data
          })

        // if (ideasWorkshopResult) {
        const promises = ideasWorkshopResult.map(async idea => {
          // console.log(state.workshop.okr)
          const newIdea: IIdea = {} as IIdea

          newIdea.problem = idea.problem
          newIdea.okr = state.workshop.okr
          newIdea.description = idea.description
          newIdea.impact = idea.impact.rating
          newIdea.engage = idea.engage.rating
          newIdea.trust = idea.trust.rating

          // newIdea.okr = state.workshop.okr

          delete newIdea?.problem?.description
          delete newIdea?.okr?.name

          let ideasPost: any = {}
          ideasPost = await axios
            .post(`/ideas`, newIdea)
            .then(ideaExperiment => {
              console.log(ideaExperiment)
              return ideaExperiment.data
            })
          // console.log(ideasPost)
          //  ideasResult.push(ideasPost)
          return ideasPost
          // console.log(ideasResult)
        })

        const results = await Promise.all(promises)
        // console.log(results)

        // console.log(ideasResult)
        // console.log(ideasResult && ideasResult.length)
        // console.log(ideasWorkshopResult)
        // }

        const experimentsResult: IExperiments[] = await axios
          .get<IExperiments[]>(`experiments`)
          .then(response => {
            return response.data
          })
        // console.log(experimentsResult)
        // console.log(ideasResult?.length)
        // console.log(ideasWorkshopResult.length)

        // if (ideasResult.length == ideasWorkshopResult.length) {
        // experimentsResult[0].kanban[0].column.ideas.push(ideasResult[2])
        experimentsResult[0].kanban[0].column.ideas = [
          ...experimentsResult[0].kanban[0].column.ideas,
          ...results,
        ]

        // console.log(experimentsResult)
        // console.log(experimentsResult[0].kanban[0].column.ideas)

        await axios.put(
          `${`experiments`}/${experimentsResult[0]._id}`,
          experimentsResult[0],
        )
        // }

        // const result

        // const { data } = await axios.get(
        //   `/workshops/${id}/problems/hypotheses?limit=3&sort=likedBy&direction=-1`,
        // )
      } else {
        await axios.put(`/workshops/${id}/status`, {
          status: 'Em andamento',
        })
      }

      getWorkshop()
    } catch (error) {
      const { message } = error as Error
      toast.error(message)
    }
  }, [getWorkshop, id, state.workshop.stage, startCountdown])

  const prevStage = useCallback(async (): Promise<void> => {
    try {
      setIsCountdown(false)
      startCountdown()

      if (state.workshop.stage == 4 && isProblemCard()) {
        setAdvance(true)
      }
      if (state.workshop.stage == 7) {
        setAdvance(true)
      }

      if (state.workshop.stage == 9 && isIdeaCard()) {
        setAdvance(true)
      }

      // console.log(state?.workshop?.stage)
      // ENTENDER PORQUE O STAGE EM 0 DA ERRO
      let stage
      if (state?.workshop?.stage <= 2) {
        stage = 1
      } else {
        stage = state?.workshop?.stage - 2
      }
      await axios.put(`/workshops/${id}/stage`, {
        stage,
      })
      await axios.put(`/workshops/${id}/status`, {
        status: 'Em andamento',
      })
      getWorkshop()
    } catch (error) {
      const { message } = error as Error
      toast.error(message)
    }
  }, [getWorkshop, id, state.workshop.stage, startCountdown])

  useEffect(() => {
    getWorkshop()
    setIsCountdown(false)

    const interval = setInterval(() => {
      if (socket.connected) {
        clearInterval(interval)
        emit('adduser', {
          room: id,
          username: getParsedToken()?.id,
        })
      }
    }, 200)
  }, [])

  if (state.workshop.stage === undefined) {
    return (
      <WorkshopBackground>
        <div className="d-flex justify-content-center align-items-center workshop-steps-manager">
          {state.label === 'Carregando...' && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          )}
          <strong>{state.label}</strong>
        </div>
      </WorkshopBackground>
    )
  }

  const Component: React.FC = STEPS[state.workshop.stage]

  return (
    <WorkshopContext.Provider
      value={{
        workshop: state.workshop,
        refetch: getWorkshop,
        nextStage,
        prevStage,
        isAdmin,
        likeStages,
        showLikeStages,
        isCountdown,
        countdown,
        setAdvanceButtom: setAdvanceBtn,
        getAdvanceButtom: getAdvanceBtn,
        setProblemCard,
        isProblemCard,
        setIdeaCard,
        isIdeaCard,
      }}
    >
      <WorkshopBackground>
        <Header />
        <Component />
      </WorkshopBackground>
    </WorkshopContext.Provider>
  )
}

export default WorkshopStageManager
