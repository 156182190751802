import * as Yup from 'yup'

const schema = Yup.object().shape({
  initialDate: Yup.date().required(
    'Verifique se as datas e intervalos são válidos',
  ),
  finalDate: Yup.date()
    .required('Verifique se as datas e intervalos são válidos')
    .min(
      Yup.ref('initialDate'),
      'Verifique se as datas e intervalos são válidos',
    ),
  action: Yup.string().required('Ação é um campo obrigatório'),
  name: Yup.string().required('Objetivo é um campo obrigatório'),
  kpi: Yup.string().required('Indicador é um campo obrigatório'),
  direction: Yup.string().required('Direção é um campo obrigatório'),
  unit: Yup.string().required('Unidade é um campo obrigatório'),
})

export default schema
