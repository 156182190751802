import React, { useEffect } from 'react'

import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { useBreadcrumb } from '../../hooks/breadcrumbs'
import Breadcrumbs from '../../components/BreadCrumbs'

import { useTheme } from '../../hooks/theme'

import './styles.scss'

const ChangeTheme: React.FC = () => {
  useTheme().addPageStyle('change-theme')
  useTheme().changeTheme()

  const {
    backgroundColorGeral,
    setBackgroundColorGeral,
    contentBackgroundColor,
    setContentBackgroundColor,
    colorHeaderTitle,
    setColorHeaderTitle,
    colorSpanSubTitle,
    setColorSpanSubTitle,
    colorMenuSection,
    setColorMenuSection,
    colorItemMenu,
    setColorItemMenu,
    changeThemeToggle,
    setChangeThemeToggle,
    themeSelected,
    setThemeSelected,
    cssContent,
  } = useTheme()

  const { UBreadcrumbOne, UBreadcrumbCurrent } = useBreadcrumb()

  useEffect(() => {
    UBreadcrumbOne({ breadcrumbLink: '/', breadcrumbTitle: 'Profile' })
    UBreadcrumbCurrent({
      breadcrumbLink: '/',
      breadcrumbTitle: 'Tema',
    })
  }, [])

  if (contentBackgroundColor) {
    document.body.style.backgroundColor = contentBackgroundColor
  }

  const checkCss = changeThemeToggle
    ? cssContent
    : { backgroundColor: backgroundColorGeral }

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div className="container">
          <header>
            <div className="breadcrumb">
              <Breadcrumbs />
            </div>
          </header>
          <main>
            <h1>Mudar tema</h1>
            <div className="row">
              <div className="col-6">
                {/* <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={changeThemeToggle}
                    onChange={e => setChangeThemeToggle(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Tema 1
                  </label>
                </div> */}
                <select
                  className=" form-select  indicator "
                  id="kpiSelector"
                  value={themeSelected}
                  placeholder="Selecione um tema"
                  onChange={e => setThemeSelected(e.target.value)}
                >
                  <option key="default" value="default" selected>
                    Selecione um tema
                  </option>

                  <option key="Theme 1" value="theme-1">
                    Tema 1
                  </option>
                  <option key="Theme 2" value="theme-2">
                    Tema 2
                  </option>
                  <option key="Theme 3" value="theme-3">
                    Tema 3
                  </option>
                  <option key="Theme 4" value="theme-4">
                    Tema 4
                  </option>
                  <option key="Theme 5" value="theme-5">
                    Tema 5
                  </option>
                  <option key="Theme 6" value="theme-6">
                    Tema 6
                  </option>
                  <option key="Theme 7" value="theme-7">
                    Tema 7
                  </option>
                  <option key="Theme 8" value="theme-8">
                    Tema 8
                  </option>
                </select>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default ChangeTheme
