import React, { useCallback, useContext, useMemo, useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'

import { CardItem } from '../../../components/Workshop/CardItem'
import { WorkshopHeader } from '../../../components/Workshop/WorkshopHeader'
import { ModalCreateProblem } from '../../../components/Problem/ModalCreateProblem'
import { WorkshopInitCounter } from '../../../components/Workshop/WorkshopInitCounter'

import './styles.scss'
import useWorkshopProblem from '../../../hooks/workshop/useWorkshopProblem'
import { WorkshopContext } from '../WorkshopStageManager'
import { WorkshopAdvanceButton } from '../../../components/Workshop/WorkshopAdvanceButton'
import { WorkshopCardFooterFactory } from '../../../components/Workshop/WorkshopCardFooterFactory'
import getParsedToken, { IToken } from '../../../utils/getParsedToken'

export const Problem: React.FC = () => {
  const { workshop, nextStage } = useContext(WorkshopContext)
  const { createCard, cards, started, onLike, hookPermission } =
    useWorkshopProblem()
  const parsedToken = getParsedToken() || ({} as IToken)

  const disabledButton = !started

  const onConfirm = useCallback(
    (description: string): void => {
      createCard(description)
    },
    [createCard],
  )

  const onStart = useCallback((): void => {
    nextStage()
  }, [nextStage])

  useEffect(() => {
    hookPermission('list')
  }, [])

  return (
    <div className="workshop-problem-screen">
      <div className="container-scroll">
        <WorkshopHeader
          title={` ${
            workshop?.stage < 4
              ? 'Etapa 1: Dores e problemas'
              : 'Etapa 2: Seleção de dores e problemas'
          }`}
          description={
            workshop?.stage < 4
              ? `Atualmente, o que nos impede de atingir o objetivo "${workshop?.okr?.name}"? O que está prejudicando a nossa métrica-chave "${workshop?.kpi?.name}"?`
              : `Vote nas questões que você mais concorda que estão nos prejudicando quanto a atingir o objetivo “${workshop?.okr?.name}” e melhorar a métrica "${workshop?.kpi?.name}".`
          }
          buttonProps={
            workshop?.stage > 2
              ? undefined
              : {
                  icon: () => <AiOutlinePlus size={22} color="#FFF" />,
                  text: 'Criar nota',
                  props: {
                    disabled: disabledButton,
                    onClick: () => null,
                    ...(disabledButton
                      ? {}
                      : {
                          'data-bs-toggle': 'modal',
                          'data-bs-target': '#modalProblem',
                        }),
                  },
                }
          }
          RightComponent={
            started && WorkshopAdvanceButton ? WorkshopAdvanceButton : undefined
          }
        />

        {!started && <WorkshopInitCounter onClick={onStart} />}

        <div className="card-container">
          <div className="row">
            {cards?.map(card => (
              <div className="col-lg-2 card-item-margin" key={card?._id}>
                <CardItem
                  description={card?.description}
                  backgroundColor={card?.hexadecimalColor}
                  rotate="0"
                  Footer={() => (
                    <WorkshopCardFooterFactory
                      stage={workshop?.stage}
                      onClick={() => onLike(card)}
                      likes={card?.likedBy?.length}
                      iLike={card?.likedBy.indexOf(parsedToken?.id) !== -1}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <ModalCreateProblem onConfirm={onConfirm} />
    </div>
  )
}

export default Problem
