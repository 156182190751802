import React, { useEffect } from 'react'

import { useTheme } from '../../hooks/theme'
import AreasExpertise from '../AreasExpertise'
import Profile from '../Profile'

import logo from '../../assets/img/header/logo.png'

import './styles.scss'

const Header: React.FC = () => {
  const { backgroundColorGeral, cssHeader, changeThemeToggle } = useTheme()

  const checkCss = changeThemeToggle
    ? cssHeader
    : { backgroundColor: backgroundColorGeral }

  // useEffect(() => {
  //   console.log(
  //     document?.getElementById('offcanvasScrolling')?.classList.toString(),
  //     // ?.match(/start\d/i),
  //   )
  // }, [])

  return (
    <>
      <nav className="navbar fixed-top" style={checkCss}>
        <div className="container-fluid">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <AreasExpertise />
          <Profile />
        </div>
      </nav>
    </>
  )
}

export default Header
