import React, { createContext, useEffect, useRef } from 'react'
import { io, Socket } from 'socket.io-client'
import {
  IRealTimeContext,
  IRealTimeProviderProps,
  TArgs,
} from '../../interfaces/IRealtime'

export const RealTimeContext = createContext<IRealTimeContext>(
  {} as IRealTimeContext,
)

export const RealTimeProvider: React.FC<IRealTimeProviderProps> = ({
  children,
  onConnect,
  onConnectionError,
  autoConnect = false,
}) => {
  const { current: socket } = useRef(
    io(process.env.REACT_APP_WEBSOCKET_URL, {
      extraHeaders: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      autoConnect,
      transports: ['websocket'],
    }),
  )

  const emit = (eventName: string, data: TArgs): Socket => {
    return socket.emit(eventName, data)
  }

  useEffect(() => {
    socket.on('connect', () => {
      if (onConnect) {
        onConnect()
      }
    })

    socket.on('connect_error', err => {
      if (onConnectionError) {
        onConnectionError(err)
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  return (
    <RealTimeContext.Provider value={{ socket, emit }}>
      {children}
    </RealTimeContext.Provider>
  )
}
