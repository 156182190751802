export const recordToArray = <T>(arg: Record<string, T>): T[] => {
  const result: T[] = []

  Object.keys(arg).forEach(key => {
    result.push(arg[key])
  })

  return result
}

export default recordToArray
