import { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import IProblemsBase from '../../interfaces/IProblemsBase'
import { WorkshopContext } from '../../pages/Workshop/WorkshopStageManager'
import axios from '../../services/api'
import getRandomFromArray from '../../utils/getRandomFromArray'
import recordToArray from '../../utils/recordToArray'
import useRealtime from '../useRealtime'

export type TUseWorkshopProblemResult = {
  createCard: (description: string) => Promise<void>
  cards: TCard[]
  started: boolean
  hookPermission(use: string): void
  onLike: (card: TCard) => Promise<void>
  onProblemsBase: () => Promise<IProblemsBase[] | undefined>
}

export type TCard = {
  id: string
  _id: string
  createdBy: string
  description: string
  hexadecimalColor: string
  likedBy: string[]
}

const colors: string[] = ['#ffe587', '#FFC3F9', '#C9FF9E', '#C0D9FF']
const rotations: number[] = [-2, -1, 0, 1, 2]
const RealtimeEvents: Record<string, string> = {
  CARDS: 'updated_cards',
  COUNTDOWN: 'timer_problems',
}
const ProblemStartedSteps = [2, 4, 5]

export const useWorkshopProblem: () => TUseWorkshopProblemResult = () => {
  const [cards, setCards] = useState<TCard[]>([])
  const [problemsBaseList, setProblemsBaseList] = useState<IProblemsBase[]>([])
  const { id } = useParams<{ id: string }>()
  const [useHook, setUseHook] = useState('')
  const { workshop, setAdvanceButtom, setProblemCard } =
    useContext(WorkshopContext)

  const started = useMemo(
    () => ProblemStartedSteps.indexOf(workshop.stage) !== -1,
    [workshop],
  )

  const onRealtimeEvent = useCallback((event: string, args: unknown): void => {
    if (event === RealtimeEvents.CARDS && Array.isArray(args)) {
      setCards(args as TCard[])
    }
  }, [])

  const createCard = useCallback(
    async (description: string): Promise<void> => {
      await axios.post(`/workshops/${id}/problems`, {
        description,
        hexadecimalColor: getRandomFromArray(colors),
      })
    },
    [id],
  )

  useRealtime(recordToArray(RealtimeEvents), onRealtimeEvent)

  // eslint-disable-next-line consistent-return
  const onProblemsBase = async (): Promise<IProblemsBase[] | undefined> => {
    try {
      const problemasBase = await axios.get(`/problemsbase`)
      const problemasBaseUpdate: IProblemsBase[] = problemasBase.data.map(
        (item: any) => ({
          ...item,
          // eslint-disable-next-line no-underscore-dangle
          id: item._id,
        }),
      )
      if (problemasBaseUpdate && problemasBaseUpdate.length > 0) {
        setProblemsBaseList(problemasBaseUpdate)
      }
      return problemasBaseUpdate
    } catch (err) {
      const { message } = err as Error
      toast.error(message)
    }
  }

  const getCards = useCallback(async (): Promise<void> => {
    try {
      axios
        .get(`/workshops/${workshop.id}/problems`)
        .then((response: AxiosResponse | null) => {
          if (!response || response.status !== 200) {
            setAdvanceButtom(false)
            setProblemCard(false)
            return
          }
          const updatedCards = response.data.map((item: any) => ({
            ...item,
            // eslint-disable-next-line no-underscore-dangle
            id: item._id,
          }))

          if (workshop.stage === 4) {
            let isLike = false
            updatedCards.forEach(function (item: TCard) {
              if (item?.likedBy.length > 0) {
                isLike = true
              }
            })
            setAdvanceButtom(isLike)
            setProblemCard(isLike)
          }
          if (workshop.stage === 5) {
            updatedCards.sort((a: TCard, b: TCard) =>
              a?.likedBy.length > b?.likedBy.length ? -1 : 1,
            )
            updatedCards.splice(3, updatedCards.length)
          }

          if (workshop.stage === 2) {
            setAdvanceButtom(true)
            setProblemCard(true)
          }

          setCards(updatedCards)
        })
        .catch((error: AxiosError) => {
          if (error.response?.status !== 404) {
            toast.error(error.message)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }, [workshop.id, workshop.stage])

  const onLike = useCallback(
    async (card: TCard): Promise<void> => {
      try {
        await axios.post(`/workshops/${id}/problems/${card._id}/like`)
        await getCards()
      } catch (err) {
        const { message } = err as Error
        toast.error(message)
      }
    },
    [getCards, id],
  )

  const hookPermission = useCallback((use: string) => {
    setUseHook(use)
  }, [])

  // useEffect(() => {
  //   if (useHook == 'list') {
  //     // console.log('Fase: ', workshop.stage)
  //     onProblemsBase()
  //   }
  // }, [useHook])

  useEffect(() => {
    if (workshop.stage === 5) {
      getCards()
    }
  }, [workshop.stage])

  useEffect(() => {
    if (started && useHook == 'list') {
      getCards()
    }
  }, [started, useHook])

  return {
    createCard,
    cards,
    started,
    onLike,
    onProblemsBase,
    hookPermission,
  }
}

export default useWorkshopProblem
