import React from 'react'
import './styles.scss'

interface IProps {
  description: string
  className?: string
  backgroundColor: string
  rotate: string | number
  Footer?: React.FC
}

export const CardItem: React.FC<IProps> = ({
  description,
  className,
  backgroundColor,
  rotate,
  Footer = () => null,
}) => {
  return (
    <div
      className={`workshop-card-item ${className}`}
      style={{ backgroundColor, transform: `rotate(${rotate}deg)` }}
    >
      <span>{description}</span>
      <Footer />
    </div>
  )
}

export default CardItem
