import React from 'react'

import Header from '../../components/Header'
import MenuOrganization from '../../components/MenuOrganization'

import { useTheme } from '../../hooks/theme'

import './styles.scss'

const Organization: React.FC = () => {
  useTheme().addPageStyle('Organization')
  useTheme().changeTheme()

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <MenuOrganization />
        <div className="wrapper">
          <div className="container">
            <main>
              <div className="dv-organization">RH</div>
              <div className="dv2-organization">
                Gerencie a área e seus usuários no sistema
              </div>
              <button type="button" className="edit">
                Editar
              </button>
              <div className="dv-organization">Gestores</div>
              <div className="dv2-organization">
                Podem criar e visualizar objetivos e indicadores
              </div>
              <div className="faixa">
                <span className="faixa-text1">E-MAIL</span>
                <span className="faixa-text2">NOME</span>
              </div>
              <div className="gestores-participantes">Sem gestores</div>
              <span className="add">+ Novo gestor</span>
              <div className="divisor" />
              <div className="dv-organization">Participantes</div>
              <div className="dv2-organization">
                Podem visualizar objetivo e indicadores
              </div>
              <button type="button" className="import">
                Importar planilha
              </button>
              <div className="faixa-2">
                <span className="faixa-text1">E-MAIL</span>
                <span className="faixa-text2">NOME</span>
              </div>
              <div className="gestores-participantes">Sem participantes</div>
              <span className="add">+ Novo participante</span>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default Organization
