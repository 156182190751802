import React, {
  useContext,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
} from 'react'

import ThisProviderProps from '../interfaces/IGeneric'

import IThemeContext from '../interfaces/ITheme'

import ThemeContext from '../context/theme'

import headerBg from '../assets/img/test/header-background-1.svg'
import contentBg from '../assets/img/test/sun-tornado.svg'
import ChangeTheme from '../pages/Theme'

const ThemeProvider: React.FC = ({ children }) => {
  const [backgroundColorGeral, setBackgroundColorGeral] = useState<string>('')
  const [contentBackgroundColor, setContentBackgroundColor] =
    useState<string>('')
  const [cssHeader, setCssHeader] = useState<CSSProperties>({} as CSSProperties)
  const [cssMenu, setCssMenu] = useState<CSSProperties>({} as CSSProperties)
  const [cssContent, setCssContent] = useState<CSSProperties>(
    {} as CSSProperties,
  )
  const [colorHeaderTitle, setColorHeaderTitle] = useState<string>('')
  const [colorSpanSubTitle, setColorSpanSubTitle] = useState<string>('')
  const [colorMenuSection, setColorMenuSection] = useState<string>('')
  const [colorItemMenu, setColorItemMenu] = useState<string>('')
  const [themeSelected, setThemeSelected] = useState<string>('')
  const [changeThemeToggle, setChangeThemeToggle] = useState<boolean>(false)
  const [isRetractable, setIsRetractable] = useState<boolean>(false)

  const addPageStyle = useCallback((pageStyle: string) => {
    document.body.removeAttribute('class')

    if (pageStyle != 'login') {
      document.body.classList.add('sistema')
      document.body.classList.add('header-elements')
      document.body.classList.add('expertise')
      document.body.classList.add('breadcrumb-area')
      document.body.classList.add('menu-component')
      document.body.classList.add('profile-component')
      // document.body.classList.add('change-theme')
      document.body.classList.add('theme')
      document.body.classList.add('theme-2')
    }

    document.body.classList.add(pageStyle)
  }, [])

  const changeTheme = useCallback(() => {
    if (themeSelected && themeSelected != 'default') {
      const oldTheme = document.body.classList.toString().match(/theme-\d/i)
      if (oldTheme) {
        document.body.classList.remove(oldTheme[0])
      }
      document.body.classList.add(themeSelected)
    }
  }, [themeSelected])

  useEffect(() => {
    // if (themeSelected && themeSelected != 'default') {
    //   document.body.removeAttribute('class')
    //   document.body.classList.add('theme')
    //   document.body.classList.add(themeSelected)
    // } else {
    //   console.log('habilitando tema')

    //   document.body.removeAttribute('class')

    //   document.body.classList.add('sistema')
    //   document.body.classList.add('change-theme')
    // }

    // if (themeSelected && themeSelected != 'default') {
    //   const oldTheme = document.body.classList.toString().match(/theme-\d/i)
    //   if (oldTheme) {
    //     document.body.classList.remove(oldTheme[0])
    //   }
    //   document.body.classList.add(themeSelected)
    // }

    if (changeThemeToggle) {
      document.body.classList.add('one-theme')

      setCssHeader({
        background: 'none',
        // backgroundImage: `url(${headerBg})`,
        // backgroundImage: `url(${contentBg})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      })
      setCssContent({
        marginTop: 0,
        height: '100%',
        // backgroundImage: `url(${contentBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      })
      setCssMenu({
        height: '100%',
        // backgroundImage: `url(${headerBg})`,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      })
    } else {
      document.body.classList.remove('one-theme')
      setCssHeader({} as CSSProperties)
      setCssContent({} as CSSProperties)
      setCssMenu({} as CSSProperties)
    }

    // if (ChangeTheme == 'Theme 1') {
    // }

    // console.log(changeThemeToggle)
  }, [changeThemeToggle, themeSelected])

  return (
    <ThemeContext.Provider
      value={{
        backgroundColorGeral,
        setBackgroundColorGeral,
        contentBackgroundColor,
        setContentBackgroundColor,
        cssHeader,
        setCssHeader,
        cssMenu,
        setCssMenu,
        cssContent,
        setCssContent,
        colorHeaderTitle,
        setColorHeaderTitle,
        colorSpanSubTitle,
        setColorSpanSubTitle,
        colorMenuSection,
        setColorMenuSection,
        colorItemMenu,
        setColorItemMenu,
        changeThemeToggle,
        setChangeThemeToggle,
        themeSelected,
        setThemeSelected,
        setIsRetractable,
        isRetractable,
        addPageStyle,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

export function useTheme(): IThemeContext {
  const context = useContext(ThemeContext)

  return context
}
