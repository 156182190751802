import React, {
  useEffect,
  useCallback,
  useState,
  FormEvent,
  MutableRefObject,
  useRef,
} from 'react'
import imgTaxaChurn from '../../../assets/img/graficao-taxa-de-churn.gif'
import './styles.scss'

const ModalAdd: React.FC = () => {
  document.body.classList.add('okr-modal')

  useEffect(() => {
    /*
    okrHook.getAll().then(response => {
      setOkrList(response)
    })
    departamentHook.getAll().then(response => {
      setDepartamentList(response)
    })
    */
  }, [])

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault()
      const element = document.getElementById('okr-close-modal')
      if (element) {
        element.click()
      }
    },
    [],
  )

  return (
    <>
      <div
        className="modal fade"
        id="modalOkrAdd"
        aria-labelledby="modalOkrAdd"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Detalhes
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="input-content">
                  <img
                    className="img-grafico-taxa-churn"
                    src={imgTaxaChurn}
                    alt="Taxa Churn"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  id="okr-close-modal"
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalAdd
