import React, {
  useCallback,
  useState,
  FormEvent,
  useRef,
  useEffect,
} from 'react'
import useWorkshopProblem from '../../../hooks/workshop/useWorkshopProblem'
import IProblemsBase from '../../../interfaces/IProblemsBase'
import { PortalModal } from '../../PortalModal'
import './styles.scss'

interface IProps {
  onConfirm: (description: string) => void
}

export const ModalCreateProblem: React.FC<IProps> = ({ onConfirm }) => {
  document.body.classList.add('create-problem-modal')
  const { onProblemsBase } = useWorkshopProblem()
  const [pain, setPain] = useState<string>('')
  const [disablePain, setDisablePain] = useState(false)
  const [suggestions, setSuggestions] = useState<IProblemsBase[] | undefined>(
    [],
  )
  const [suggestion, setSuggestion] = useState<string>('')

  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault()

      if (pain.trim() === '' && suggestion.trim() === '') {
        return
      }

      if (pain.trim() === '') {
        onConfirm(suggestion)
      } else {
        onConfirm(pain)
      }
      setPain('')
      setSuggestion('')

      if (closeButtonRef.current) {
        closeButtonRef.current.click()
      }
    },
    [pain, setPain, suggestion, setSuggestion, onConfirm],
  )

  const getProblemsBase = useCallback(async (): Promise<void> => {
    const problemsBase = await onProblemsBase()
    if (problemsBase && problemsBase.length > 0) {
      setSuggestions(problemsBase)
    }
  }, [onProblemsBase])

  useEffect(() => {
    getProblemsBase()
  }, [])

  useEffect(() => {
    const withoutSuggestion = suggestion.trim() === ''

    if (!withoutSuggestion) {
      setPain('')
    }

    setDisablePain(!withoutSuggestion)
  }, [suggestion])

  return (
    <PortalModal>
      <div
        className="modal fade"
        id="modalProblem"
        aria-labelledby="modalProblem"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Dores e problemas
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              />
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="pain" className="col-form-label">
                    O que nos prejudica atualmente?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pain"
                    value={pain}
                    onChange={ev => setPain(ev.target.value)}
                    disabled={disablePain}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="message-text" className="col-form-label">
                    Sugestões
                  </label>
                  <select
                    id="cbx-sugestao"
                    className="form-select"
                    aria-label="Ver lista de sugestões..."
                    value={suggestion}
                    onChange={ev => setSuggestion(ev.target.value)}
                  >
                    <option value="" defaultValue="Ver lista de sugestões...">
                      Ver lista de sugestões...
                    </option>
                    {suggestions?.map((item: IProblemsBase | undefined) => (
                      <option key={item?._id} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Salvar
                  </button>
                  <button
                    id="workshop-close-modal"
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PortalModal>
  )
}

export default ModalCreateProblem
