import React from 'react'

import { BreadcrumbsProvider } from './breadcrumbs'
import DepartmentProvider from './department'
import KpiBaseProvider from './kpiBase'
import KpisProvider from './kpis'
import OkrProvider from './okr'
import IdeaBaseProvider from './idea'
import WorkShopProvider from './workshop'
import ExperimentProvider from './experiments'
import ThemeProvider from './theme'

import { RealTimeProvider } from '../contexts/Realtime/RealTimeContext'
import { LoadingProvider } from '../components/ProgressBar/LoadingProvider'
import ProblemsBaseProvider from './problems'
import IdeiaBBaseProvider from './ideiaBase'

const AppProvider: React.FC = ({ children }) => (
  <LoadingProvider>
    <RealTimeProvider>
      <ThemeProvider>
        <ExperimentProvider>
          <WorkShopProvider>
            <DepartmentProvider>
              <KpiBaseProvider>
                <BreadcrumbsProvider>
                  <IdeaBaseProvider>
                    <KpisProvider>
                      <ProblemsBaseProvider>
                        <IdeiaBBaseProvider>
                          <OkrProvider>{children}</OkrProvider>
                        </IdeiaBBaseProvider>
                      </ProblemsBaseProvider>
                    </KpisProvider>
                  </IdeaBaseProvider>
                </BreadcrumbsProvider>
              </KpiBaseProvider>
            </DepartmentProvider>
          </WorkShopProvider>
        </ExperimentProvider>
      </ThemeProvider>
    </RealTimeProvider>
  </LoadingProvider>
)

export default AppProvider
