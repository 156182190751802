import React, { useState, FormEvent, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useKpis } from '../../../hooks/kpis'

import IKPI from '../../../interfaces/IKPI'
import Modal from '../../Modal'
import schema from './schema'

import './styles.scss'

type ModalKpiProps = {
  kpi: IKPI
  getKpi: () => void
}

const ModalUpdateKpi: React.FC<ModalKpiProps> = ({ kpi, getKpi }) => {
  document.body.classList.add('update-kpi-modal')
  const [formValues, setFormValues] = useState({
    date: '',
    newValue: '',
  })
  const [dateError, setDateError] = useState('')
  const { CreateRecordValue } = useKpis()

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setDateError('')
      try {
        const splittedDate = formValues.date.split('/')
        await schema.validate({
          date: `${splittedDate[2]}/${splittedDate[1]} / ${splittedDate[0]}`,
        })
        const model = {
          value: parseInt(formValues.newValue, 10),
          date: `${splittedDate[2]}/${splittedDate[1]} / ${splittedDate[0]}`,
        }
        try {
          CreateRecordValue(kpi._id, model)
          toast.success('Valor editado com sucesso')
          getKpi()
        } catch (error) {
          toast.error('Ocorreu um erro. Tente novamente mais tarde')
        }
        setFormValues({ newValue: '', date: '' })
      } catch (error) {
        setDateError('Insira uma data válida')
      }
    },
    [CreateRecordValue, kpi, formValues, getKpi],
  )

  const handleChange = (key: string, value: string | number): void => {
    setFormValues({ ...formValues, [key]: value })
  }

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget
    if (value.match(/^\d{2}$/) !== null) {
      value = `${value}/`
    } else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
      value = `${value}/`
    }
    e.currentTarget.value = value
    return e
  }, [])

  return (
    <Modal
      hasFooter={false}
      id="updatekpi"
      title="Atualizar indicador"
      size="large"
    >
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="input-content  objective">
            <label htmlFor="basic-url" className="form-label">
              Valor
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="newValue"
                name="newValue"
                aria-describedby="basic-addon3"
                value={formValues.newValue}
                onChange={e => {
                  handleChange('newValue', e.target.value)
                }}
              />
            </div>
          </div>
          <div className="input-content  objective">
            <label htmlFor="basic-url" className="form-label">
              Data
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="date"
                aria-describedby="basic-addon3"
                onKeyPress={handleKeyUp}
                placeholder="DD/MM/YYYY"
                name="date"
                value={formValues.date}
                onChange={e => {
                  handleChange('date', e.target.value)
                }}
              />
            </div>
            {!!dateError && (
              <small>
                <p className="text-danger">{dateError}</p>
              </small>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Fechar
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default ModalUpdateKpi
