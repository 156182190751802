import React, { useEffect, useCallback, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import InfiniteScroll from 'react-infinite-scroller'
import Header from '../../components/Header'
import Menu from '../../components/Menu'

import { useOkr } from '../../hooks/okr'

import IOkr from '../../interfaces/IOkr'

import ModalUpdateKpi from '../../components/KPI/ModalUpdateKpi'

import dateToString from '../../utils/dateToString'

import { useTheme } from '../../hooks/theme'

import './styles.scss'
import ModalKpiDetails from '../../components/KPI/ModalKpiDetails/index.js'
import { SearchPagination } from '../../interfaces/ISearchPagination'
import { LIMITOKR } from '../../config/constLimits'

const ViewObjective: React.FC = () => {
  const { isRetractable, setIsRetractable } = useTheme()

  useTheme().addPageStyle('view-objective')
  useTheme().changeTheme()

  const {
    getAll,
    setCKpi,
    setCOkr,
    COkrList,
    setCOkrList,
    IsCOkrListReloaded,
    setIsCOkrListReloaded,
    setMetaKpi,
    okrList,
    setOkrList,
  } = useOkr()
  // const okrHook = useOkr()

  const [limit, setLimit] = useState(LIMITOKR)
  const [page, setPage] = useState<number>(Number(1))
  const [totalPages, setTotalPages] = useState(0)
  const [hasMore, setHasMore] = useState(false)

  const handleUpdateKpi = useCallback(
    (okr, metaKpiSelected, kpi) => {
      setCOkr(okr)
      setMetaKpi(metaKpiSelected)
      setCKpi(kpi)
    },
    [setCOkr, setMetaKpi, setCKpi],
  )

  useEffect(() => {
    setIsRetractable(false)
  }, [])

  useEffect(() => {
    if (!IsCOkrListReloaded) {
      getAll({ page, limit } as SearchPagination).then(list => {
        setIsCOkrListReloaded(true)
        setCOkrList(list)
        setOkrList(list.results)

        setTotalPages(list.totalPages)

        if (list.nextPage != null) setHasMore(true)
      })
    }
  }, [IsCOkrListReloaded, setCOkrList, setIsCOkrListReloaded])

  const loadMore = (p: number): void => {
    if (p <= totalPages) {
      getAll({ page: p, limit } as SearchPagination).then(list => {
        setOkrList(oldList => [...oldList, ...(list?.results ?? [])])
      })
    } else {
      setHasMore(false)
    }
  }

  return (
    <>
      <Header />

      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        pageStart={1}
        // loader={<></>}
      >
        <div className="container-fluid conteudo">
          <Menu />
          <div
            className={
              isRetractable ? 'wrapper wrapper-retractable' : 'wrapper'
            }
          >
            <div className="container">
              <header>
                <div className="column-left">
                  <h1>Objetivos</h1>
                  <h2>O que a equipe precisa alcançar para crescer</h2>
                </div>
                <div className="column-right">
                  <Link to="/okr/add">
                    <button type="button">Novo objetivo</button>
                  </Link>
                </div>
              </header>
              <main>
                <>
                  {okrList.map((okr: IOkr) => {
                    return (
                      <div key={okr?._id} className="okr">
                        <div className="okr-header">
                          <div className="column-left">
                            <h3>{okr?.name}</h3>
                            <div className="okr-date">
                              <span>
                                {okr?.initialDate &&
                                  dateToString(okr.initialDate)}{' '}
                                até{' '}
                                {okr?.finalDate && dateToString(okr.finalDate)}
                              </span>
                            </div>
                          </div>
                          <div className="column-right">
                            <Link to={`/okr/update/${okr._id}`}>
                              <button type="button">Editar</button>
                            </Link>
                          </div>
                        </div>
                        <hr className="divider" />
                        <div className="kpi-columns">
                          {okr?.kpis.map((kpi, index) => {
                            return (
                              <>
                                {kpi?.kpi?.name && index === 0 && (
                                  <div className="kpi" key={kpi?.kpi?._id}>
                                    <div className="kpi-info">
                                      <div className="name">
                                        {kpi?.kpi?.name}
                                      </div>
                                      <div className="value">
                                        {kpi.kpi.updates &&
                                          kpi.kpi.updates.reduce(
                                            (previousValue, currentValue) =>
                                              previousValue +
                                              currentValue.value,
                                            0,
                                          )}
                                        / {kpi?.kpi.value}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <Link to={`kpi/${kpi.kpi._id}`}>
                                        <button
                                          type="button"
                                          disabled={!kpi.kpi.updates.length}
                                          className="btn"
                                        >
                                          {!kpi.kpi.updates.length
                                            ? 'Sem detalhes'
                                            : 'Ver detalhes'}
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </>

                <ModalKpiDetails />
              </main>
            </div>
          </div>
        </div>
      </InfiniteScroll>
    </>
  )
}

export default ViewObjective
