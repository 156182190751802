import { CSSProperties } from 'react'
import { toast } from 'react-toastify'

const style: CSSProperties = { marginTop: '30px' }

export const WorkshopInvite: React.FC = () => {
  const copyEventOverride = (e: ClipboardEvent): void => {
    if (!e.clipboardData) {
      return
    }

    e.clipboardData.setData('text/plain', window.location.href)
    e.preventDefault()
  }

  const onClick = (): void => {
    document.addEventListener('copy', copyEventOverride)
    document.execCommand('copy')
    document.removeEventListener('copy', copyEventOverride)
    toast.success('Copiado para a área de transferência!')
  }

  return (
    <button
      type="button"
      className="btn btn-outline-primary"
      style={style}
      onClick={onClick}
    >
      Convidar participantes
    </button>
  )
}

export default WorkshopInvite
