import { useContext, useEffect } from 'react'
import { Socket } from 'socket.io-client'
import { RealTimeContext } from '../contexts/Realtime/RealTimeContext'
import { TArgs } from '../interfaces/IRealtime'

export interface IUseRealtimeResult {
  emit: (eventName: string, data: TArgs) => Socket
  socket: Socket
}

const useRealtime = (
  listen: string[] = [],
  onEvent: (event: string, args: TArgs) => void,
): IUseRealtimeResult => {
  const { socket, emit } = useContext(RealTimeContext)

  useEffect(() => {
    if (!socket.connected) {
      socket.connect()
    }

    listen.forEach(eventName => {
      socket.on(eventName, args => {
        onEvent(eventName, args)
      })
    })

    return () => {
      listen.forEach(eventName => {
        socket.removeListener(eventName, args => {
          onEvent(eventName, args)
        })
      })
    }
  }, [])

  return { emit, socket }
}

export default useRealtime
