import React from 'react'
// import Context from '../../Context'
import { IoMdConstruct } from 'react-icons/io'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { useTheme } from '../../hooks/theme'

import './styles.scss'

const UnderConstruction: React.FC = () => {
  useTheme().addPageStyle('under-construction')
  useTheme().changeTheme()

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div className="wrapper">
          <div className="container">
            <header />
            <main>
              <h1>
                {' '}
                <IoMdConstruct /> Página em Construção
              </h1>

              <h2>Em breve traremos novidades!</h2>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default UnderConstruction
