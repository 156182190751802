/* eslint-disable react/prop-types */
import { Route, Redirect } from 'react-router-dom'
import { ICustomRoute } from '../../interfaces/ICustomRoute'

export const CustomRoute: React.FC<ICustomRoute> = ({
  isPublic = false,
  component: C,
  exact,
  path,
}) => {
  const hasToken = !!localStorage.getItem('token')
  const mustRender = isPublic || (!isPublic && hasToken)

  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        mustRender ? (
          <C {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: '?tokenExpired=expired',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default CustomRoute
