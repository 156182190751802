import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import CardItem from '../../../components/Workshop/CardItem'
import { WorkshopHeader } from '../../../components/Workshop/WorkshopHeader'
import ModalAddIdea from '../../../components/Workshop/ModalAddIdea'
import { WorkshopInitCounter } from '../../../components/Workshop/WorkshopInitCounter'
import './styles.scss'
import vector from '../../../assets/icons/Vector.svg'

import useWorkshopIdeas, {
  TProblemCard,
  TIdeaCard,
} from '../../../hooks/workshop/useWorkshopIdeas'
import { WorkshopContext } from '../WorkshopStageManager'
import { WorkshopAdvanceButton } from '../../../components/Workshop/WorkshopAdvanceButton'
import { WorkshopCardFooterFactory } from '../../../components/Workshop/WorkshopCardFooterFactory'
import getParsedToken, { IToken } from '../../../utils/getParsedToken'
import AuthContext from '../../../context/auth'

export const ScoreIce: React.FC = () => {
  // console.log('ACESSO')

  const { workshop, nextStage, setAdvanceButtom } = useContext(WorkshopContext)
  const { user } = useContext(AuthContext)
  const {
    createCard,
    problemCards,
    ideasCards,
    started,
    onLike,
    impactVote,
    trustVote,
    engageVote,
    hookPermission,
  } = useWorkshopIdeas()

  const [problemCard, setProblemCard] = useState<TProblemCard>(
    {} as TProblemCard,
  )

  const onStart = useCallback((): void => {
    nextStage()
  }, [nextStage])

  const findVote = useCallback(
    (index: number, type: string) => {
      if (type === 'impact') {
        const userCheck = ideasCards[index]?.impact?.votes.findIndex(
          (cardState: any) => {
            // console.log(cardState)
            // console.log(user?.id)
            return user?.id === cardState.userId
          },
        )
        // console.log(userCheck)

        if (type === 'impact' && userCheck !== -1) {
          // console.log(ideasCards[index]?.impact?.votes[userCheck]?.vote)
          setAdvanceButtom(true)
          return ideasCards[index]?.impact?.votes[userCheck]?.vote
        }
      }

      if (type === 'trust') {
        const userCheck = ideasCards[index]?.trust?.votes.findIndex(
          (cardState: any) => {
            // console.log(cardState)
            // console.log(user?.id)

            return user?.id === cardState.userId
          },
        )
        // console.log(userCheck)

        if (type === 'trust' && userCheck !== -1) {
          // console.log(ideasCards[index]?.trust?.votes[userCheck]?.vote)
          setAdvanceButtom(true)
          return ideasCards[index]?.trust?.votes[userCheck]?.vote
        }
      }

      if (type === 'engage') {
        const userCheck = ideasCards[index]?.engage?.votes.findIndex(
          (cardState: any) => {
            // console.log(cardState)
            // console.log(user?.id)

            return user?.id === cardState.userId
          },
        )
        // console.log(userCheck)

        if (type === 'engage' && userCheck !== -1) {
          // console.log(ideasCards[index]?.engage?.votes[userCheck]?.vote)
          setAdvanceButtom(true)
          return ideasCards[index]?.engage?.votes[userCheck]?.vote
        }
      }

      // console.log(user)

      // console.log(ideasCards)
      return 0
    },
    [ideasCards, user],
  )

  const handleUpdateIdeaCardVote = useCallback(
    (event: any, index: number, type: string, vote: number) => {
      event.preventDefault()
      event.stopPropagation()

      if (type === 'impact') {
        const userCheck = ideasCards[index]?.impact?.votes.findIndex(
          (cardState: any) => {
            // console.log(cardState)
            // console.log(user?.id)

            return user?.id === cardState.userId
          },
        )

        if (userCheck === -1 && user?.id) {
          ideasCards[index]?.impact?.votes.push({ userId: user?.id, vote })
        } else {
          ideasCards[index].impact.votes[userCheck].vote = vote
        }
        // console.log(ideasCards[index]?.impact?.votes)
        impactVote(ideasCards[index], vote)
      }

      if (type === 'trust') {
        const userCheck = ideasCards[index]?.trust?.votes.findIndex(
          (cardState: any) => {
            // console.log(cardState)
            // console.log(user?.id)

            return user?.id === cardState.userId
          },
        )
        // console.log(userCheck)

        if (userCheck === -1 && user?.id) {
          // console.log('adicionar voto trust')
          ideasCards[index]?.trust?.votes.push({ userId: user?.id, vote })
        } else {
          // console.log('atualizar voto trust')
          ideasCards[index].trust.votes[userCheck].vote = vote
        }
        // console.log(ideasCards[index]?.trust?.votes)
        trustVote(ideasCards[index], vote)
      }

      if (type === 'engage') {
        const userCheck = ideasCards[index]?.engage?.votes.findIndex(
          (cardState: any) => {
            // console.log(cardState)
            // console.log(user?.id)

            return user?.id === cardState.userId
          },
        )
        // console.log(userCheck)

        if (userCheck === -1 && user?.id) {
          // console.log('adicionar voto')
          ideasCards[index]?.engage?.votes.push({ userId: user?.id, vote })
        } else {
          // console.log('atualizar voto')

          ideasCards[index].engage.votes[userCheck].vote = vote
        }

        // console.log(ideasCards[index]?.engage?.votes)

        engageVote(ideasCards[index], vote)
      }

      // console.log(user)

      // console.log(ideasCards)
    },
    [engageVote, ideasCards, impactVote, trustVote, user],
  )

  useEffect(() => {
    hookPermission('list')
    setAdvanceButtom(false)
  }, [])

  return (
    <div className="workshop-ideas">
      <div className="container-scroll">
        <WorkshopHeader
          title="Etapa 3: ideias para solução"
          description="O que podemos fazer para superar ou resolver nossas dores e problemas?"
          RightComponent={started ? WorkshopAdvanceButton : undefined}
        />

        {!started && <WorkshopInitCounter onClick={onStart} />}

        <div className="row">
          {/* {console.log(`refresh do real time ${problemCards}`)} */}
          {problemCards?.map(problemCardList => (
            <div className="col-md-4" key={problemCardList.id}>
              <div className="problem-container">
                <span>&quot;{problemCardList.description}&quot;</span>
              </div>
              {/* {console.log(problemCards)} */}
              {/* {console.log(ideasCards)} */}
              {ideasCards?.map((ideaCard, ideaCardIndex) => {
                // console.log(ideaCard)
                return (
                  problemCardList?.id == ideaCard.problem._id && (
                    <>
                      <div className="container-priority" key={ideaCard.id}>
                        <span className="description">
                          {ideaCard.description}
                        </span>
                        <div className="form-range-1">
                          <label htmlFor="customRange2" className="form-label">
                            Impacto:
                          </label>
                          <div className="vector">
                            <img src={vector} alt="" />
                          </div>
                          <input
                            type="range"
                            onChange={e =>
                              handleUpdateIdeaCardVote(
                                e,
                                ideaCardIndex,
                                'impact',
                                parseInt(e.target.value, 10),
                              )
                            }
                            min={0}
                            max={10}
                            step={1}
                            defaultValue={findVote(ideaCardIndex, 'impact')}
                          />
                        </div>

                        <div className="form-range-2">
                          <label htmlFor="customRange2" className="form-label">
                            Confiança:
                          </label>
                          <div className="vector">
                            <img src={vector} alt="" />
                          </div>
                          <input
                            type="range"
                            onChange={e =>
                              handleUpdateIdeaCardVote(
                                e,
                                ideaCardIndex,
                                'trust',
                                parseInt(e.target.value, 10),
                              )
                            }
                            min={0}
                            max={10}
                            step={1}
                            defaultValue={findVote(ideaCardIndex, 'trust')}
                          />
                        </div>
                        <div className="form-range-3">
                          <label htmlFor="customRange2" className="form-label">
                            Facilidade:
                          </label>
                          <div className="vector">
                            <img src={vector} alt="" />
                          </div>
                          <input
                            type="range"
                            onChange={e =>
                              handleUpdateIdeaCardVote(
                                e,
                                ideaCardIndex,
                                'engage',
                                parseInt(e.target.value, 10),
                              )
                            }
                            min={0}
                            max={10}
                            step={1}
                            defaultValue={findVote(ideaCardIndex, 'engage')}
                          />
                        </div>
                      </div>
                    </>
                  )
                )
              })}
            </div>
          ))}
        </div>
        <ModalAddIdea id={problemCard.id} />
      </div>
    </div>
  )
}

export default ScoreIce
