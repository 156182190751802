import {
  useContext,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  useCallback,
} from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { WorkshopContext } from '../../pages/Workshop/WorkshopStageManager'
import axios from '../../services/api'
import getRandomFromArray from '../../utils/getRandomFromArray'
import recordToArray from '../../utils/recordToArray'
import useRealtime from '../useRealtime'
import { TCard } from './useWorkshopProblem'

export type TUseWorkshopIdeasResult = {
  createCard: (problemCardId: string, description: string) => Promise<void>
  problemCards: TProblemCard[]
  ideasCards: TIdeaCard[]
  started: boolean
  hookPermission(use: string): void
  onLike: (card: TIdeaCard) => Promise<void>
  impactVote: (card: TIdeaCard, impact: number) => Promise<void>
  trustVote: (card: TIdeaCard, trust: number) => Promise<void>
  engageVote: (card: TIdeaCard, engage: number) => Promise<void>
  advance: boolean
  getIdeasScore: () => Promise<void>
}

export type TVote = {
  _id?: string
  userId: string
  vote: number
}

export type TScoreIce = {
  rating: number
  votes: TVote[]
}

export type TProblemCard = {
  id: string
  _id?: string
  createdBy?: string
  description?: string
  hexadecimalColor?: string
  likedBy?: string[]
}

export type TIdeaCard = {
  id: string
  _id: string
  problem: TProblemCard
  createdBy: string
  description: string
  hexadecimalColor: string
  likedBy: string[]
  impact: TScoreIce
  trust: TScoreIce
  engage: TScoreIce
}

// interface ThisContextData {
//   isWorkShopListReloded: boolean
//   setIsWorkShopListReloded: Dispatch<SetStateAction<boolean>>
//   CWorkShopList: Array<IWorkshop> | void | undefined
//   setCWorkShopList: Dispatch<
//     SetStateAction<Array<IWorkshop> | void | undefined>
//   >
//   getAll: () => Promise<WorkShopList>
//   getById: (ob: IWorkshop) => Promise<IWorkshop>
//   Add: (obj: IWorkshop) => Promise<void>
//   Update: (obj: IWorkshop) => Promise<void>
//   Delete: (id: number) => Promise<void>
// }

// const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const colors: string[] = ['#ffe587', '#FFC3F9', '#C9FF9E', '#C0D9FF']
const rotations: number[] = [-2, -1, 0, 1, 2]
const RealtimeEvents: Record<string, string> = {
  CARDS: 'updated_cards_ideas',
}
const IdeasStartedSteps = [7, 9, 11]
const scoreIceSteps = [13]
const scoreResultSteps = [14]

export const useWorkshopIdeas: () => TUseWorkshopIdeasResult = () => {
  const [problemCards, setProblemCards] = useState<TProblemCard[]>([])
  const [ideasCards, setIdeasCards] = useState<TIdeaCard[]>([])
  const [advance, setAdvance] = useState<boolean>(false)
  // const [impact, setImpact] = useState()
  // const [trust, setTrust] = useState()
  // const [engage, setEngage] = useState()
  const { id } = useParams<{ id: string }>()
  const { problemId } = useParams<{ problemId: string }>()
  const { workshop, setAdvanceButtom, setIdeaCard } =
    useContext(WorkshopContext)
  const [useHook, setUseHook] = useState('')
  // console.log(`workshop stage: ${workshop.stage}`)
  // console.log(`indexOf: ${IdeasStartedSteps.indexOf(workshop.stage) !== -1}`)

  const started = useMemo(
    () =>
      IdeasStartedSteps.indexOf(workshop.stage) !== -1 ||
      scoreIceSteps.indexOf(workshop.stage) !== -1 ||
      scoreResultSteps.indexOf(workshop.stage) !== -1,
    [workshop],
  )
  // console.log(`useMemo: ${started}`)

  const onRealtimeEvent = useCallback((event: string, args: unknown): void => {
    if (event === RealtimeEvents.CARDS && Array.isArray(args)) {
      setIdeasCards(args as TIdeaCard[])
    }
  }, [])

  const createCard = useCallback(
    async (problemCardId: string, description: string): Promise<void> => {
      await axios.post(
        `/workshops/${id}/problems/${problemCardId}/hypotheses`,
        {
          description,
          hexadecimalColor: getRandomFromArray(colors),
        },
      )
      setAdvanceButtom(true)
      setIdeaCard(true)
    },
    [id],
  )

  useRealtime(recordToArray(RealtimeEvents), onRealtimeEvent)

  const getProblemsCards = useCallback(async (): Promise<void> => {
    try {
      const { data } = await axios.get(
        `/workshops/${workshop.id}/problems?sort=likedBy&direction=-1&limit=3`,
      )
      if (!data) {
        return
      }
      const updatedCards = data.map((item: any) => ({
        ...item,
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
      }))
      if (updatedCards && updatedCards.length > 0) {
        setAdvanceButtom(true)
      }
      setProblemCards(updatedCards)
    } catch (error) {
      const { message } = error as Error
      toast.error(message)
    }
  }, [workshop.id])

  const getIdeasCards = useCallback(async (): Promise<void> => {
    try {
      const response = await axios
        .get<TIdeaCard[]>(`/workshops/${workshop.id}/problems/hypotheses`)
        .catch(err => {
          console.log('Não foram encotrados cards de ideias.')
        })

      if (!response?.data) {
        setAdvanceButtom(false)
        setIdeaCard(false)
        return
      }

      const updatedCards = response?.data?.map((item: TIdeaCard) => ({
        ...item,
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
      }))

      if (workshop.stage === 7 || workshop.stage === 6) {
        setAdvanceButtom(true)
        setIdeaCard(true)
      }

      if (workshop.stage === 9) {
        let isLike = false
        updatedCards.forEach(function (item: TCard) {
          if (item?.likedBy.length > 0) {
            isLike = true
          }
        })
        setAdvanceButtom(isLike)
        setIdeaCard(isLike)
      }
      setIdeasCards(updatedCards)
    } catch (error) {
      const { message } = error as Error
      toast.error(message)
    }
  }, [workshop.id, workshop.stage])

  const getIdeasScore = useCallback(async (): Promise<void> => {
    try {
      const { data } = await axios.get<TIdeaCard[]>(
        `/workshops/${workshop.id}/problems/hypotheses?limit=3&sort=likedBy&direction=-1`,
        // `/workshops/${workshop.id}/problems/hypotheses?sort=likedBy&direction=-1`,
      )

      const updatedCards = data.map((item: TIdeaCard) => ({
        ...item,
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
      }))

      setIdeasCards(updatedCards)
    } catch (error) {
      const { message } = error as Error
      toast.error(message)
    }
  }, [workshop.id])

  const onLike = useCallback(
    async (card: TIdeaCard): Promise<void> => {
      try {
        await axios.post(
          `/workshops/${id}/problems/${card.problem._id}/hypotheses/${card._id}/like`,
        )
        await getIdeasCards()
      } catch (err) {
        const { message } = err as Error
        toast.error(message)
      }
    },
    [getIdeasCards, id],
  )

  const impactVote = useCallback(
    async (card: TIdeaCard, impact: number): Promise<void> => {
      try {
        await axios.put(
          `/workshops/${id}/problems/${card.problem._id}/hypotheses/${card._id}/vote`,
          { impact },
        )
        await getIdeasScore()
      } catch (err) {
        const { message } = err as Error
        toast.error(message)
      }
    },
    [getIdeasScore, id],
  )

  const trustVote = useCallback(
    async (card: TIdeaCard, trust: number): Promise<void> => {
      try {
        await axios.put(
          `/workshops/${id}/problems/${card.problem._id}/hypotheses/${card._id}/vote`,
          { trust },
        )
        await getIdeasScore()
      } catch (err) {
        const { message } = err as Error
        toast.error(message)
      }
    },
    [getIdeasScore, id],
  )

  const engageVote = useCallback(
    async (card: TIdeaCard, engage: number): Promise<void> => {
      try {
        await axios.put(
          `/workshops/${id}/problems/${card.problem._id}/hypotheses/${card._id}/vote`,
          { engage },
        )
        await getIdeasScore()
      } catch (err) {
        const { message } = err as Error
        toast.error(message)
      }
    },
    [getIdeasScore, id],
  )

  const hookPermission = useCallback((use: string) => {
    setUseHook(use)
  }, [])

  // useEffect(() => {
  //   if (workshop.stage === 11) {
  //     getIdeasScore()
  //   }
  //   // if (workshop.stage === 12) {
  //   //   getIdeasScore()
  //   // }
  // }, [workshop.stage])

  useEffect(() => {
    // console.log(`antes do start ${started}`)

    // console.log(workshop.stage)

    // console.log(
    //   IdeasStartedSteps.indexOf(workshop.stage) !== -1 ||
    //     scoreIceSteps.indexOf(workshop.stage) !== -1 ||
    //     scoreResultSteps.indexOf(workshop.stage) !== -1,
    // )
    // console.log(useHook)

    // if (started && IdeasStartedSteps.indexOf(workshop.stage) !== -1) {
    if (started && useHook == 'list') {
      // console.log('pós start')

      if (IdeasStartedSteps.includes(workshop.stage)) {
        console.log(workshop.stage)

        getProblemsCards()
        getIdeasCards()
      } else if (
        scoreIceSteps.includes(workshop.stage) ||
        scoreResultSteps.includes(workshop.stage)
      ) {
        console.log(workshop.stage)
        getProblemsCards()
        getIdeasScore()
      }
    }
  }, [started, useHook])

  return {
    createCard,
    problemCards,
    ideasCards,
    started,
    onLike,
    impactVote,
    trustVote,
    engageVote,
    hookPermission,
    advance,
    getIdeasScore,
  }
}

export default useWorkshopIdeas
