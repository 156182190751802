/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */

import { uuid } from 'uuidv4'
import { TCompany } from './ICompany'
import { TDepartment } from './IDepartment'
import { TUserData } from './IUser'
import IKPI from './IKPI'

export type Uuid = string

export function Uuid(): Uuid {
  return uuid() as Uuid
}

export interface TMetaKpi {
  kpi: IKPI
  value: number
  direction: string
  unit: string
  updates: IKpiUpdate[]
  _id: string
}

export interface IKpiOkrModel {
  direction: string
  kpi: { _id: Uuid; value?: number }
  unit: string
}

export interface IOkrFormModel {
  _id?: Uuid
  name: string
  initialDate: Date
  finalDate: Date
  department: { _id: Uuid }
  action: string | Actions
  status: 'active' | 'deactive'
  kpis: Array<IKpiOkrModel>
}

export default interface IOkr {
  _id: Uuid
  name: string
  initialDate: Date
  finalDate: Date
  company: TCompany
  department: TDepartment
  okrMain?: IOkrMain
  action: string | Actions
  status: 'active' | 'deactive'
  kpis: Array<TMetaKpi>
  createdBy: number
  updatedBy: number
  createdAt: Date
  updatedAt: Date
}

export interface POkr {
  _id?: Uuid | undefined
  name?: string | undefined
  initialDate?: Date | undefined
  finalDate?: Date | undefined
  company?: TCompany | undefined
  department?: TDepartment | undefined
  okrMain?: IOkrMain | undefined
  action?: string | Actions | undefined
  status?: 'active' | 'deactive' | undefined
  kpis?: Array<TMetaKpi> | undefined
  createdBy?: number | undefined
  updatedBy?: number | undefined
  createdAt?: Date | undefined
  updatedAt?: Date | undefined
}

export interface IOkrListRequest extends IOkr {
  _id: Uuid
}

export interface IKpiUpdate {
  user?: TUserData
  date: Date
  value: number
}

export type OkrList = IOkr[]

export type TOkrAction = Pick<IOkr, 'action'>

export type TOkrAdd = Omit<IOkr, '_id'>

export type TOkr = Pick<IOkr, '_id' | 'name'>

// export type POkr = Partial<IOkr>

export interface IOkrMain {
  id: number
  name: string
}

export enum Actions {
  Aumentar = 'Aumentar',
  Maximizar = 'Maximizar',
  Melhorar = 'Melhorar',
  Reduzir = 'Reduzir',
  Mitigar = 'Mitigar',
  Manter = 'Manter',
}
