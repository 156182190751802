import React, { useEffect, useCallback, MutableRefObject, useRef } from 'react'

import { useParams } from 'react-router-dom'

import Header from '../../components/Header'
import Menu from '../../components/Menu'

import ModalExperimentsView from '../../components/Experiments/ModalView'

import IExperiments from '../../interfaces/IExperiments'

import { useEperiment } from '../../hooks/experiments'

import { useTheme } from '../../hooks/theme'

import './styles.scss'

const ViewExperiments: React.FC = () => {
  const { isRetractable, setIsRetractable } = useTheme()

  useEffect(() => {
    setIsRetractable(false)
  }, [])

  useTheme().addPageStyle('view-experiments')
  useTheme().changeTheme()

  const {
    setCKanbanColumnName,
    setCIdeaIndex,
    setCKanbanColumnIndex,
    setCExperimentIndex,
    setCKanbanIdea,
    isExperimentListReloded,
    CExperimentList,
    setCExperimentList,
    setIsExperimentListReloded,
    getAll,
  } = useEperiment()

  // const { id } = useParams<{ id: string }>()

  // const workShopObj: MutableRefObject<IExperiments> = useRef({} as IExperiments)

  // workShopObj.current._id = id

  const handleUpdateIdeaStatus = useCallback(
    (column, kanbanIndex, experimentIndex, idea, ideaIndex) => {
      console.log(idea)
      setCKanbanColumnName(column)
      setCKanbanColumnIndex(kanbanIndex)
      setCExperimentIndex(experimentIndex)
      setCKanbanIdea(idea)
      setCIdeaIndex(ideaIndex)
    },
    [
      setCExperimentIndex,
      setCIdeaIndex,
      setCKanbanColumnIndex,
      setCKanbanColumnName,
      setCKanbanIdea,
    ],
  )

  useEffect(() => {
    if (!isExperimentListReloded) {
      getAll().then(list => {
        setCExperimentList(list)
        setIsExperimentListReloded(true)
      })
    }
  }, [
    getAll,
    isExperimentListReloded,
    setCExperimentList,
    setIsExperimentListReloded,
  ])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div
          className={isRetractable ? 'wrapper wrapper-retractable' : 'wrapper'}
        >
          <div className="container">
            <header>
              <div className="column-left">
                <h1>Experimentos</h1>
                <h2>
                  Acompanhe as iniciativas que estão sendo conduzidas para fazer
                  a sua organização crescer
                </h2>
              </div>
            </header>
            <main>
              {CExperimentList?.map((experiment, experimentIndex) => (
                <div className="kanban" key={experiment._id}>
                  <div className="row align-items-start">
                    {experiment?.kanban?.map((kanban, kanbanIndex) => (
                      <div className="col kanban-colunm" key={kanban._id}>
                        <div className="kanban-ideas-header">
                          {kanban?.column?.status}
                        </div>
                        {kanban?.column?.ideas?.map((idea, ideaIndex) => (
                          <div className="kanban-ideas" key={idea._id}>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#modalExperimentsView"
                              onClick={e =>
                                handleUpdateIdeaStatus(
                                  kanban.column.status,
                                  kanbanIndex,
                                  experimentIndex,
                                  idea,
                                  ideaIndex,
                                )
                              }
                            >
                              {idea?.description}
                            </button>
                          </div>
                        ))}
                      </div>
                    ))}
                    ,
                  </div>
                </div>
              ))}

              <ModalExperimentsView />
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewExperiments
