import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import { Fade } from '@mui/material'
import { useContext } from 'react'
import LoadingContext from '../../context/LoadindContext'

const ProgressBar: React.FC = () => {
  const loading = useContext(LoadingContext)
  return (
    <>
      <Fade in={loading}>
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={6}>
          <LinearProgress color="secondary" />
        </Stack>
      </Fade>
    </>
  )
}

export default ProgressBar
