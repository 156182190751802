/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

const url = process.env.REACT_APP_API_URL

const token = localStorage.getItem('token')

const api = axios.create({
  baseURL: url,
  headers: {
    Authorization: token,
  },
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.data?.code == 401) {
      localStorage.setItem('token', '')
      window.location.href = '/login?tokenExpired=expired'
    }
    return Promise.reject(error)
  },
)

const instances = [api]

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function addGlobalRequestInterceptor(
  onFulfilled?: (
    value: AxiosRequestConfig,
  ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
  onRejected?: (error: any) => unknown,
) {
  const ids: number[] = []
  // eslint-disable-next-line no-restricted-syntax
  for (const i of instances) {
    const id = i.interceptors.request.use(onFulfilled, onRejected)
    ids.push(id)
  }
  return ids
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function removeGlobalRequestInterceptor(ids: number[]) {
  ids.forEach((id: number, index: number) => {
    instances[index].interceptors.request.eject(id)
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function addGlobalResponseInterceptor(
  onFulfilled?: (
    value: AxiosResponse,
  ) => AxiosResponse | Promise<AxiosResponse>,
  onRejected?: (error: any) => unknown,
) {
  const ids: number[] = []
  // eslint-disable-next-line no-restricted-syntax
  for (const i of instances) {
    const id = i.interceptors.response.use(onFulfilled, onRejected)
    ids.push(id)
  }
  return ids
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function removeGlobalResponseInterceptor(ids: number[]) {
  ids.forEach((id: number, index: number) => {
    instances[index].interceptors.response.eject(id)
  })
}

export default api
