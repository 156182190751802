/* eslint-disable consistent-return */
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'

import api from '../services/api'

//
//
/**
 * A interface deve ficar em um arquivo separado, pois ela determina os atributos do objeto
 * Usando o export default, o nome pode ser genérico, alterando apenas o endereço da interface a ser utilizada
 */
import IIdea, { ideaList } from '../interfaces/IIdea'
import errorHandler from '../utils/errorHandler'
import IPagination from '../interfaces/IPagination'
import { SearchPagination } from '../interfaces/ISearchPagination'

// Alterar para o nome da Model (que é o mesmo nome da rota)
const apiRoute = 'ideas'

// Não precisa alterar, é igual para todos
/**
 * Métodos exportados pelo Context
 */
interface ThisContextData {
  isIdeaListReloded: boolean
  setIsIdeaListReloded: Dispatch<SetStateAction<boolean>>
  CIdeaList: IPagination<IIdea>
  setCIdeaList: Dispatch<SetStateAction<IPagination<IIdea>>>
  getAll: (search: SearchPagination) => Promise<IPagination<IIdea>>
  getById: (id: number) => Promise<IIdea>
  Add: (obj: any) => Promise<void>
  Update: (obj: IIdea) => Promise<void>
  Delete: (id: number) => Promise<void>
}

const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const IdeaBaseProvider: React.FC = ({ children }) => {
  const [isIdeaListReloded, setIsIdeaListReloded] = useState<boolean>(false)
  const [CIdeaList, setCIdeaList] = useState<IPagination<IIdea>>(
    {} as IPagination<IIdea>,
  )

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = useCallback(
    async (search: SearchPagination): Promise<IPagination<IIdea>> => {
      try {
        const result = await api
          .get(apiRoute, {
            params: {
              page: search.page,
              limit: search.limit,
              orderBy: search.orderBy,
              direction: search.direction,
            },
          })
          .then(response => {
            return response.data
          })
        setCIdeaList(result)
        return result
      } catch (error) {
        errorHandler(
          'Ocorreu um erro ao listar as ideias, tente novamente mais tarde',
        )
        return {} as IPagination<IIdea>
      }
    },
    [],
  )

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = useCallback(async (id: number): Promise<IIdea> => {
    try {
      return api.get(`${apiRoute}/${id}`).then(response => {
        // console.log(`getById >> ${JSON.stringify(response.data)}`)
        return response.data
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao buscar a ideia, tente novamente mais tarde',
      )
      return {} as IIdea
    }
  }, [])

  /**
   * Adiciona um registro no DB
   * @param ob: IIdea
   */
  const Add = useCallback(async (ob: any): Promise<void> => {
    try {
      const result = await api.post(apiRoute, ob).then(response => {
        return response.data
      })
      return result
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao criar a ideia, tente novamente mais tarde',
      )
    }
  }, [])

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  const Update = useCallback(async (ob: IIdea): Promise<void> => {
    try {
      return api.put(`${apiRoute}/${ob._id}`, ob).then(response => {
        // console.log(`Update >> ${JSON.stringify(response.data)}`)
        return response.data
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao atualizar a ideia, tente novamente mais tarde',
      )
    }
  }, [])

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  const Delete = useCallback(async (id: number): Promise<void> => {
    try {
      return api.delete(`${apiRoute}/${id}`).then(response => {
        // console.log(`Delete >> ${JSON.stringify(response)}`)
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao deletar a ideia, tente novamente mais tarde',
      )
    }
  }, [])

  return (
    <ThisContext.Provider
      value={{
        isIdeaListReloded,
        setIsIdeaListReloded,
        CIdeaList,
        setCIdeaList,
        getAll,
        getById,
        Add,
        Update,
        Delete,
      }}
    >
      {children}
    </ThisContext.Provider>
  )
}

export default IdeaBaseProvider

export function useIdea(): ThisContextData {
  const context = useContext(ThisContext)

  return context
}
