import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const modalRoot = document.getElementById('modal-root')

export const PortalModal: React.FC = ({ children }) => {
  const { current: element } = useRef(document.createElement('div'))

  useEffect(() => {
    modalRoot?.append(element)

    return () => {
      modalRoot?.removeChild(element)
    }
  }, [element])

  return ReactDOM.createPortal(children, element)
}

export default PortalModal
