import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { Link } from 'react-router-dom'

import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { useKpis } from '../../hooks/kpis'

import { useTheme } from '../../hooks/theme'
import IKPI from '../../interfaces/IKPI'
import { SearchPagination } from '../../interfaces/ISearchPagination'
import { LIMITKPI } from '../../config/constLimits'

import './styles.scss'

const ViewKPI: React.FC = () => {
  const { isRetractable, setIsRetractable } = useTheme()

  useTheme().addPageStyle('view-kpis')
  useTheme().changeTheme()
  const {
    getAll,
    setKpiList,
    kpiList,
    kpis,
    setKpis,
    isKpiLoaded,
    setIsKpiLoaded,
  } = useKpis()

  const [limit, setLimit] = useState(LIMITKPI)
  const [page, setPage] = useState<number>(Number(1))
  const [totalPages, setTotalPages] = useState(0)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setIsRetractable(false)
  }, [])

  useEffect(() => {
    if (!isKpiLoaded) {
      getAll({ page, limit } as SearchPagination).then(list => {
        setKpiList(list)
        setIsKpiLoaded(true)
        setKpis(list.results)
        setTotalPages(list.totalPages)
        if (list.nextPage != null) setHasMore(true)
      })
    }
  }, [setKpiList, isKpiLoaded])

  const loadMore = (p: number): void => {
    if (p <= totalPages) {
      getAll({ page: p, limit } as SearchPagination).then(list => {
        setKpis(oldList => [...oldList, ...(list?.results ?? [])])
      })
    } else {
      setHasMore(false)
    }
  }

  return (
    <>
      <Header />

      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        pageStart={1}
        // loader={<></>}
      >
        <div className="container-fluid conteudo">
          <Menu />
          <div
            className={
              isRetractable ? 'wrapper wrapper-retractable' : 'wrapper'
            }
          >
            <div className="container">
              <header>
                <div className="column-left">
                  <h1>Indicadores</h1>
                </div>
                <div className="column-right">
                  <Link to="/kpi/add">
                    <button type="button">Novo indicador</button>
                  </Link>
                </div>
              </header>
              <main>
                <div className="kpi-list">
                  {kpis.map(item => (
                    <Link
                      to={`/kpi/${item._id}`}
                      key={item._id}
                      className="kpi-list__card"
                    >
                      <span className="kpi-list__card--indicator">
                        {item.name}
                      </span>
                      <span className="kpi-list__card--value">
                        {item.unit === 'Percentual'
                          ? `${item.value || 0}%`
                          : ` ${item.value.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}`}
                      </span>
                      <span className="kpi-list__card--unit">{item.unit}</span>
                    </Link>
                  ))}
                </div>
              </main>
            </div>
          </div>
        </div>
      </InfiniteScroll>
    </>
  )
}

export default ViewKPI
