import React, { useCallback } from 'react'

import { useEperiment } from '../../../hooks/experiments'

import './styles.scss'

const ModalDeleteExperiment: React.FC = () => {
  document.body.classList.add('delete-experiment-modal')

  const {
    cIdeaIndex,
    cKanbanColumnIndex,
    cExperimentIndex,
    CExperimentList,
    setIsExperimentListReloded,
    Update,
  } = useEperiment()

  const handleRemove = useCallback(async () => {
    CExperimentList[cExperimentIndex].kanban[
      cKanbanColumnIndex
    ].column.ideas.splice(cIdeaIndex, 1)
    await Update(CExperimentList[cExperimentIndex])
    setIsExperimentListReloded(false)
  }, [
    CExperimentList,
    Update,
    cExperimentIndex,
    cIdeaIndex,
    cKanbanColumnIndex,
    setIsExperimentListReloded,
  ])

  return (
    <>
      <div
        className="modal fade"
        id="modalDeleteExperiment"
        aria-labelledby="modalDeleteExperiment"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Remover Experimento
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="input-content">
                <label htmlFor="basic-url" className="form-label labelForm">
                  Você tem certeza de que deseja remover esta ideia dos
                  experimentos? Ela ficará arquivada no Banco de Ideias.
                </label>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={e => handleRemove()}
                  >
                    Remover
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalDeleteExperiment
