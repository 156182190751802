import IOrderPagination from '../interfaces/IOrderPagination'

const ORDERBY = 'createdAt'
const DIRECTION = 'ASC'
const DIRECTIONS = ['ASC', 'DESC']

const ORDERBYWORKSHOP: Array<IOrderPagination> = [
  { value: 'createdAt', description: 'Data de Criação' },
  { value: 'name', description: 'Nome' },
  { value: 'stage', description: 'Status' },
]

const ORDERBYIDEAS: Array<IOrderPagination> = [
  { value: 'createdAt', description: 'Data de Criação' },
  { value: 'description', description: 'Ideia' },
]

export { ORDERBY, DIRECTION, DIRECTIONS, ORDERBYWORKSHOP, ORDERBYIDEAS }
