import { Serie } from '@nivo/line'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Header from '../../../components/Header'
import ModalUpdateKpi from '../../../components/KPI/ModalUpdateKpi'
import LineChart from '../../../components/LineChart'
import Menu from '../../../components/Menu'
import { useKpis } from '../../../hooks/kpis'

import { useTheme } from '../../../hooks/theme'
import IKPI from '../../../interfaces/IKPI'
import errorHandler from '../../../utils/errorHandler'

import './styles.scss'

const DetailsKpi: React.FC = () => {
  useTheme().addPageStyle('view-kpis')
  useTheme().changeTheme()

  const { id } = useParams<{ id: string }>()

  const { getById } = useKpis()
  const [kpi, setKpi] = useState<IKPI>({} as IKPI)
  const [data, setData] = useState<Serie[]>([])

  const getKpi = useCallback(async () => {
    const response = await getById(id)
    if (!response) {
      errorHandler('KPI não encontrada')
    }
    setKpi(response)
  }, [getById, id])

  useMemo(() => {
    getKpi()
  }, [])

  useMemo(() => {
    if (!kpi) return
    const { updates } = kpi
    if (!updates) return
    const monthNames = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]

    const chartData = [
      {
        id: kpi.name,
        color: 'hsla(119, 100%, 48%, 1)',
        data: monthNames.map(month => ({
          x: month,
          y: updates.reduce((acc, curr) => {
            return new Date(curr.date).toLocaleString('pt-BR', {
              month: 'long',
            }) === month
              ? acc + curr.value
              : acc
          }, 0),
        })),
      },
    ]
    setData(chartData)
  }, [kpi])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div className="wrapper">
          <div className="container">
            <header>
              <div className="column-left">
                <h1>Indicadores</h1>
              </div>
              <div className="column-right">
                <Link to={`/kpi/update/${kpi?._id}`}>
                  <button type="button">Editar</button>
                </Link>
              </div>
            </header>
            <main>
              <div className="row">
                <div className="col-12">
                  <div className="kpi-card">
                    <div className="mb-4">
                      <h1>Indicadores</h1>
                      <h2>
                        {kpi?.name} - {kpi?.department?.name}
                      </h2>
                    </div>
                    <p className="mb-4">{kpi?.formula}</p>
                    <p className="mb-4">
                      Unidade: {kpi?.unit} · {kpi?.direction}
                    </p>
                    <p className="mb-4">{kpi?.description}</p>
                    <div style={{ height: '300px', width: '100%' }}>
                      <LineChart data={data} />
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h1>Detalhamento</h1>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#updatekpi"
                        className="btn btn-primary"
                        type="button"
                      >
                        Novo Registro
                      </button>
                    </div>
                    <div className="mt-4">
                      <table className="table table-sm table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Valor</th>
                            <th scope="col">Data</th>
                            <th scope="col">Fonte</th>
                          </tr>
                        </thead>
                        <tbody>
                          {kpi?.updates?.map(update => (
                            <tr>
                              <td>
                                {kpi.unit === 'Percentual'
                                  ? `${update.value || 0}%`
                                  : ` ${update.value.toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    })}`}
                              </td>
                              <td>
                                {new Date(update.date).toLocaleString('pt-BR', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                })}
                              </td>
                              <td>{update.updatedBy?.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* <Link to="/kpi">Ver todos os registros</Link> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-4">
                  <div className="kpi-card">
                    <h1>Objetivos Relacionados</h1>
                    <h2>Objetivos associados a este indicador</h2>
                  </div>
                </div> */}
              </div>
            </main>
            <ModalUpdateKpi kpi={kpi} getKpi={getKpi} />
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailsKpi
