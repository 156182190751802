export interface IToken {
  company: string
  email: string
  name: string
  role: string
  id: string
  exp: number
  iat: number
}

export const getParsedToken = (): null | IToken => {
  try {
    const token = localStorage.getItem('token')

    if (token === null) {
      return null
    }

    const data = JSON.parse(atob(token.split('.')[1]))

    // eslint-disable-next-line no-underscore-dangle
    return { ...data, id: data._id }
  } catch {
    return null
  }
}

export default getParsedToken
