import getParsedToken from './getParsedToken'

export const getUserRole = (): string => {
  const role = getParsedToken()?.role
  if (!role) {
    return 'user'
  }
  return ['admin', 'gavb_adm', 'client_adm', 'client_head'].includes(role)
    ? 'admin'
    : 'user'
}

export const isUserAdmin = (): boolean => {
  const role = getParsedToken()?.role
  if (!role) {
    return false
  }
  return ['admin', 'gavb_adm', 'client_adm', 'client_head'].includes(role)
}
