import React from 'react'
import { Link } from 'react-router-dom'

import { useTheme } from '../../hooks/theme'

import './styles.scss'

const AreasExpertise: React.FC = () => {
  const { colorHeaderTitle, colorSpanSubTitle } = useTheme()

  return (
    <>
      <div className="expertise">
        <div className="area-atuacao">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="menu">
                <h3 style={{ color: colorHeaderTitle }}>
                  Empresa: Grupo Boticário
                </h3>
                <span style={{ color: colorSpanSubTitle }}>
                  Depto: Tecnologia da Informação
                </span>
              </div>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <Link to="/company" className="dropdown-item">
                  Informações cadastrais
                </Link>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  Áreas e usuários
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default AreasExpertise
