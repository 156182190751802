import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'

import { Pagination } from '@mui/material'
import api from '../services/api'

//
//
/**
 * A interface deve ficar em um arquivo separado, pois ela determina os atributos do objeto
 * Usando o export default, o nome pode ser genérico, alterando apenas o endereço da interface a ser utilizada
 */
import IWorkshop, {
  IWorkshopListRequest,
  WorkShopList,
} from '../interfaces/IWorkshop'
import IPagination from '../interfaces/IPagination'
import { SearchPagination } from '../interfaces/ISearchPagination'

// Alterar para o nome da Model (que é o mesmo nome da rota)
const apiRoute = 'workshops'

// Não precisa alterar, é igual para todos
interface ThisProviderProps {
  children: ReactNode
}

/**
 * Métodos exportados pelo Context
 */
interface ThisContextData {
  isWorkShopListReloded: boolean
  setIsWorkShopListReloded: Dispatch<SetStateAction<boolean>>
  CWorkShopList: IPagination<IWorkshopListRequest>
  setCWorkShopList: Dispatch<SetStateAction<IPagination<IWorkshopListRequest>>>
  getAll: (
    search: SearchPagination,
  ) => Promise<IPagination<IWorkshopListRequest>>
  getById: (ob: IWorkshop) => Promise<IWorkshop>
  Add: (obj: IWorkshop) => Promise<void>
  Update: (obj: IWorkshop) => Promise<void>
  Delete: (id: number) => Promise<void>
}

const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const WorkShopProvider: React.FC = ({ children }) => {
  const [isWorkShopListReloded, setIsWorkShopListReloded] =
    useState<boolean>(false)
  const [CWorkShopList, setCWorkShopList] = useState(
    {} as IPagination<IWorkshopListRequest>,
  )

  // const hookPermission = useCallback((use: string) => {
  //   setUseHook(use)
  // }, [])

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = useCallback(
    async (
      search: SearchPagination,
    ): Promise<IPagination<IWorkshopListRequest>> => {
      return api
        .get<IPagination<IWorkshopListRequest>>(apiRoute, {
          params: {
            page: search.page,
            limit: search.limit,
            orderBy: search.orderBy,
            direction: search.direction,
          },
        })
        .then(response => {
          // console.log(response)
          // console.log(`getAll >> ${JSON.stringify(response?.data)}`)
          return response?.data
          // return response.data
        })
    },
    [],
  )

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = useCallback(async (ob: IWorkshop): Promise<IWorkshop> => {
    return api.get(`${apiRoute}/${ob.id}`).then(response => {
      // eslint-disable-next-line no-console
      // console.log(`getById >> ${JSON.stringify(response.data)}`)
      return response.data
    })
  }, [])

  /**
   * Adiciona um registro no DB
   * @param ob: IObject
   */
  const Add = useCallback(async (ob: IWorkshop): Promise<void> => {
    await api.post(apiRoute, ob).then(response => {
      // console.log(`Add >> ${JSON.stringify(response.data)}`)
      return response.data
    })
  }, [])

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  const Update = useCallback(async (ob: IWorkshop): Promise<void> => {
    return api.put(`${apiRoute}/${ob.id}`, ob).then(response => {
      // console.log(`Update >> ${JSON.stringify(response.data)}`)
      return response.data
    })
  }, [])

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  const Delete = useCallback(async (id: number): Promise<void> => {
    return api.delete(`${apiRoute}/${id}`).then(response => {
      // console.log(`Delete >> ${JSON.stringify(response)}`)
    })
  }, [])

  return (
    <ThisContext.Provider
      value={{
        isWorkShopListReloded,
        setIsWorkShopListReloded,
        CWorkShopList,
        setCWorkShopList,
        getAll,
        getById,
        Add,
        Update,
        Delete,
      }}
    >
      {children}
    </ThisContext.Provider>
  )
}

export default WorkShopProvider

export function useWorkshop(): ThisContextData {
  const context = useContext(ThisContext)

  return context
}
