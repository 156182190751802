import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import IProblemsBase from '../interfaces/IProblemsBase'

import api from '../services/api'
import errorHandler from '../utils/errorHandler'

const apiRoute = 'problemsbase'

interface IProblemsContextData {
  problemsBaseList: IProblemsBase[]
  setProblemsBaseList: Dispatch<SetStateAction<IProblemsBase[]>>
  getAll: () => void
}

const ProblemsBaseContext = createContext<IProblemsContextData>(
  {} as IProblemsContextData,
)

const ProblemsBaseProvider: React.FC = ({ children }) => {
  const [problemsBaseList, setProblemsBaseList] = useState<IProblemsBase[]>([])

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = useCallback(async () => {
    try {
      const result = await api.get(apiRoute).then(response => {
        return response.data
      })
      setProblemsBaseList(result)
    } catch (error) {
      errorHandler('Ocorreu um erro ao listar os problemas')
    }
  }, [])

  return (
    <ProblemsBaseContext.Provider
      value={{
        problemsBaseList,
        setProblemsBaseList,
        getAll,
      }}
    >
      {children}
    </ProblemsBaseContext.Provider>
  )
}

export default ProblemsBaseProvider

export function useProblemsBase(): IProblemsContextData {
  const context = useContext(ProblemsBaseContext)

  return context
}
