import { Link } from 'react-router-dom'
import IPagination from '../../interfaces/IPagination'

interface Pagination {
  pathname: string
  page: number
  previousPage: number
  nextPage: number
  perPage: number
  handlePage: (p: number) => void
  orderBy: string
  direction: string
}

const Pagination: React.FC<Pagination> = ({
  pathname,
  page,
  previousPage,
  nextPage,
  perPage,
  handlePage,
  orderBy,
  direction,
}) => {
  const getSearch = (p: number, l: number, o: string, d: string): string => {
    return `?qPage=${p}&qLimit=${l}&qOrderBy=${o}&qDirection=${d}`
  }

  return (
    <>
      <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${previousPage == null ? 'disabled' : ''}`}>
            <Link
              className="page-link"
              to={{
                pathname,
                search: getSearch(previousPage, perPage, orderBy, direction),
              }}
              onClick={() => handlePage(previousPage)}
            >
              Anterior
            </Link>
          </li>
          {previousPage && (
            <li className="pageitem">
              <Link
                className="page-link"
                to={{
                  pathname,
                  search: getSearch(previousPage, perPage, orderBy, direction),
                }}
                onClick={() => handlePage(previousPage)}
              >
                {previousPage}
              </Link>
            </li>
          )}
          <li className="page-item">
            <Link
              className="page-link"
              to={{
                pathname,
                search: getSearch(page, perPage, orderBy, direction),
              }}
              onClick={() => handlePage(page)}
            >
              {page}
            </Link>
          </li>
          {nextPage && (
            <li className="page-item">
              <Link
                className="page-link"
                to={{
                  pathname,
                  search: getSearch(nextPage, perPage, orderBy, direction),
                }}
                onClick={() => handlePage(nextPage)}
              >
                {nextPage}
              </Link>
            </li>
          )}
          <li className={`page-item ${nextPage == null ? 'disabled' : ''}`}>
            <Link
              className="page-link"
              to={{
                pathname,
                search: getSearch(nextPage, perPage, orderBy, direction),
              }}
              onClick={() => handlePage(nextPage)}
            >
              Próxima
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Pagination
