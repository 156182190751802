import React, { useCallback, MutableRefObject, useRef } from 'react'

import { useEperiment } from '../../../hooks/experiments'

import IExperiments from '../../../interfaces/IExperiments'

import './styles.scss'

const ModalView: React.FC = () => {
  document.body.classList.add('idea-modal')

  const {
    cExperimentIndex,

    cKanbanIdea,

    CExperimentList,

    setIsExperimentListReloded,
    getAll,
    Update,
  } = useEperiment()

  const experimentsObj: MutableRefObject<Array<IExperiments>> = useRef(
    new Array<IExperiments>(),
  )

  const handleMoveToExperiments = useCallback(async () => {
    if (!CExperimentList[0]?.kanban) {
      await getAll().then(list => {
        experimentsObj.current = list
      })

      experimentsObj.current[0].kanban[0].column.ideas.push(cKanbanIdea)
      await Update(experimentsObj.current[0])
      setIsExperimentListReloded(false)
    } else {
      CExperimentList[0].kanban[0].column.ideas.push(cKanbanIdea)

      await Update(CExperimentList[cExperimentIndex])
      setIsExperimentListReloded(false)
    }
  }, [
    CExperimentList,
    Update,
    cExperimentIndex,
    cKanbanIdea,
    setIsExperimentListReloded,
    getAll,
  ])

  return (
    <>
      <div
        className="modal fade"
        id="modalIdeaView"
        aria-labelledby="modalIdeaView"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Idéia
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <span className="span-modal">Idéia</span>
                    <p className="p-modal">{cKanbanIdea?.description}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Objetivo</span>
                    <p className="p-modal">{cKanbanIdea?.okr?.name}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Impacto esperado</span>
                    <p className="p-modal">{cKanbanIdea?.impact}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Confiança</span>
                    <p className="p-modal">{cKanbanIdea?.trust}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Facilidade</span>
                    <p className="p-modal">{cKanbanIdea?.engage}</p>
                  </div>
                  <div className="col-md-6">
                    <span className="span-modal">Média</span>
                    <p className="p-modal">
                      {(
                        (cKanbanIdea?.engage +
                          cKanbanIdea?.trust +
                          cKanbanIdea?.impact) /
                        3
                      ).toFixed()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={handleMoveToExperiments}
                >
                  Encaminhar para Experimentos
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalView
