import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'

import api from '../services/api'

//
//
/**
 * A interface deve ficar em um arquivo separado, pois ela determina os atributos do objeto
 * Usando o export default, o nome pode ser genérico, alterando apenas o endereço da interface a ser utilizada
 */
import IObject from '../interfaces/IKPIBase'
import errorHandler from '../utils/errorHandler'

// Alterar para o nome da Model (que é o mesmo nome da rota)
const apiRoute = 'kpisbase'

// Não precisa alterar, é igual para todos
interface ThisProviderProps {
  children: ReactNode
}

/**
 * Métodos exportados pelo Context
 */
interface ThisContextData {
  getAll: () => Promise<IObject[]>
  getById: (id: number) => Promise<IObject>
  kpiList: IObject[]
  setKpiList: Dispatch<SetStateAction<IObject[]>>
}

const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const KpiBaseProvider: React.FC = ({ children }) => {
  const [kpiList, setKpiList] = useState<Array<IObject>>(Array<IObject>())
  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  const getAll = async (): Promise<IObject[]> => {
    try {
      return api.get(apiRoute).then(response => {
        // eslint-disable-next-line no-console
        // console.log(`getAll >> ${JSON.stringify(response.data)}`)
        return response.data
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao listar as kpis, tente novamente mais tarde',
      )
      return []
    }
  }

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = async (id: number): Promise<IObject> => {
    try {
      return api.get(`${apiRoute}/${id}`).then(response => {
        // eslint-disable-next-line no-console
        // console.log(`getById >> ${JSON.stringify(response.data)}`)
        return response.data
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao buscar a kpi, tente novamente mais tarde',
      )
      return {} as IObject
    }
  }

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  // async function Update(ob: IObject): Promise<void> {
  //   return api
  //     .put(`${apiRoute}/${ob.id}`, {
  //        department: ob.department,
  //        name: ob.name,
  //        pillar: ob.pillar,
  //        fullName: ob.fullName,
  //        description: ob.description,
  //        formula: ob.formula,
  //        unit: ob.unit,
  //        direction: ob.direction
  //     })
  //     .then(response => {
  //       console.log(`Update >> ${JSON.stringify(response.data)}`)
  //       return response.data
  //     })
  // }

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  // async function Delete(id: number): Promise<void> {
  //   return api.delete(`${apiRoute}/${id}`).then(response => {
  //     console.log(`Delete >> ${JSON.stringify(response)}`)
  //   })
  // }

  return (
    <ThisContext.Provider value={{ getAll, getById, kpiList, setKpiList }}>
      {children}
    </ThisContext.Provider>
  )
}

export default KpiBaseProvider

export function useKpiBase(): ThisContextData {
  const context = useContext(ThisContext)

  return context
}
