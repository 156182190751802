import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import Header from '../../components/Header'
import Menu from '../../components/Menu'

import ModalAdd from '../../components/Idea/ModalAdd'
import ModalView from '../../components/Idea/ModalView'

// import IIdea, { IVote } from '../../interfaces/IIdea'

import { useIdea } from '../../hooks/idea'

import { useEperiment } from '../../hooks/experiments'

import { useTheme } from '../../hooks/theme'

import './styles.scss'
import Pagination from '../../components/Pagination'
import { SearchPagination } from '../../interfaces/ISearchPagination'
import { LIMITS, LIMITTABLES } from '../../config/constLimits'
import PaginationEngine from '../../components/PaginationEngine'
import { DIRECTION, ORDERBY, ORDERBYIDEAS } from '../../config/constOrders'

const Idea: React.FC = () => {
  const { isRetractable, setIsRetractable } = useTheme()

  useTheme().addPageStyle('view-idea')
  useTheme().changeTheme()

  const { setCKanbanIdea } = useEperiment()

  const {
    isIdeaListReloded,
    setIsIdeaListReloded,
    CIdeaList,
    setCIdeaList,
    getAll,
  } = useIdea()

  useEffect(() => {
    setIsRetractable(false)
  }, [setIsRetractable])
  // const ideaHook = useIdea()

  // const handleUpdateVote = useCallback(
  //   (idea: IIdea) => {
  //     const newVote: IVote = {} as IVote

  //     newVote.id = uuidv4()
  //     newVote.user = user

  //     const index = CIdeaList.findIndex(ideaObj => ideaObj._id === idea._id)

  //     if (
  //       CIdeaList[index].votes.findIndex(
  //         userList => user?.id && userList.id !== user?.id.toString(),
  //       )
  //     ) {
  //       idea.votes.push(newVote)
  //       Update(idea)
  //       setIsIdeaListReloded(false)
  //     }
  //   },
  //   [CIdeaList, setIsIdeaListReloded, user, Update],
  // )

  const { search, pathname } = useLocation()

  const {
    qLimit = LIMITTABLES,
    qPage = 1,
    qOrderBy = ORDERBY,
    qDirection = DIRECTION,
  } = queryString.parse(search)

  const [limit, setLimit] = useState<number>(Number(qLimit))
  const [page, setPage] = useState<number>(Number(qPage))
  const [orderBy, setOrderBy] = useState<string>(`${qOrderBy}`)
  const [direction, setDirection] = useState<string>(`${qDirection}`)

  const handlePage = (p: number): void => {
    setPage(p)
    setIsIdeaListReloded(false)
  }
  const handleLimit = (e: number): void => {
    setLimit(e)
    setIsIdeaListReloded(false)
  }

  const handleDirection = (): void => {
    if (direction == 'ASC') {
      setDirection('DESC')
    } else {
      setDirection('ASC')
    }
    setIsIdeaListReloded(false)
  }

  const handleOrder = (value: string): void => {
    setOrderBy(value)
    setIsIdeaListReloded(false)
  }

  const getSearch = (p: number, l: number, o: string, d: string): string => {
    return `?qPage=${p}&qLimit=${l}&qOrderBy=${o}&qDirection=${d}`
  }

  useEffect(() => {
    if (!isIdeaListReloded) {
      getAll({ page, limit, orderBy, direction } as SearchPagination).then(
        list => {
          setCIdeaList(list)
          setIsIdeaListReloded(true)
        },
      )
    }
  }, [
    getAll,
    isIdeaListReloded,
    setCIdeaList,
    setIsIdeaListReloded,
    limit,
    page,
  ])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div
          className={isRetractable ? 'wrapper wrapper-retractable' : 'wrapper'}
        >
          <div className="container">
            <header>
              <div className="column-left">
                <h1>Ideias</h1>
                <h2>
                  Confira ou cadastre novas iniciativas para alavancar o negócio
                </h2>
              </div>
              <div className="column-right">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#modalIdeaAdd"
                >
                  Nova ideia
                </button>
              </div>
            </header>
            <main>
              <table className="table table-sm table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Área</th>
                    <th scope="col">Objetivo</th>
                    <th scope="col">Idéia</th>
                  </tr>
                </thead>
                <tbody>
                  {CIdeaList?.results?.map(ideaObj => (
                    <tr key={ideaObj._id}>
                      <td>
                        <button
                          className="btn btn-link btn-sm"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#modalIdeaView"
                          onClick={() => setCKanbanIdea(ideaObj)}
                        >
                          <AiOutlineEye size={22} />
                        </button>
                      </td>
                      <td>{ideaObj?.department?.name}</td>
                      <td>{ideaObj?.okr?.name}</td>
                      <td>{ideaObj?.description}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot />
              </table>
              <ModalAdd />
              <ModalView />
              <div className="row">
                <div className="col-6 col-md-4" />
                <div className="col-6 col-md-4 mt-3">
                  <Pagination
                    pathname={pathname}
                    page={CIdeaList.page}
                    previousPage={CIdeaList.previousPage}
                    nextPage={CIdeaList.nextPage}
                    perPage={CIdeaList.perPage}
                    handlePage={value => handlePage(value)}
                    orderBy={orderBy}
                    direction={direction}
                  />
                </div>
                <div className="col-6 col-md-4">
                  <PaginationEngine
                    pathname={pathname}
                    page={CIdeaList.page}
                    limit={limit}
                    orderBy={orderBy}
                    direction={direction}
                    limits={LIMITS}
                    orders={ORDERBYIDEAS}
                    getSearch={getSearch}
                    handleLimit={handleLimit}
                    handleOrder={handleOrder}
                    handleDirection={handleDirection}
                  />
                  {/* <div className="d-flex flex-row-reverse">
                    <div className="alert alert-light" role="alert">
                      <div className="dropdown">
                        <Link
                          className="btn  dropdown-toggle"
                          to={{}}
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Exibir
                        </Link>

                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {LIMITS.map(LIMIT => (
                            <li>
                              <Link
                                className="dropdown-item"
                                to={{
                                  pathname,
                                  search: `?qPage=${page}&qLimit=${LIMIT}`,
                                }}
                                onClick={() => handleLimit(LIMIT)}
                              >
                                {LIMIT}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default Idea
