import React, { createContext, useCallback, useState, useContext } from 'react'

interface IBreadcrumb {
  breadcrumbLink: string
  breadcrumbTitle: string
}

interface BreadcrumbContextData {
  CBreadcrumbOne: IBreadcrumb
  CBreadcrumbTwo: IBreadcrumb
  CBreadcrumbCurrent: IBreadcrumb
  UBreadcrumbOne(breadcrumbOne: IBreadcrumb): void
  UBreadcrumbTwo(breadcrumbTwo: IBreadcrumb): void
  UBreadcrumbCurrent(breadcrumbCurrent: IBreadcrumb): void
}

const BreadcrumbContext = createContext<BreadcrumbContextData>(
  {} as BreadcrumbContextData,
)

export const BreadcrumbsProvider: React.FC = ({ children }) => {
  const [CBreadcrumbOne, setCBreadcrumbOne] = useState<IBreadcrumb>(
    {} as IBreadcrumb,
  )
  const [CBreadcrumbTwo, setCBreadcrumbTwo] = useState<IBreadcrumb>(
    {} as IBreadcrumb,
  )
  const [CBreadcrumbCurrent, setCBreadcrumbCurrent] = useState<IBreadcrumb>(
    {} as IBreadcrumb,
  )

  const UBreadcrumbOne = useCallback(
    ({ breadcrumbLink, breadcrumbTitle }: IBreadcrumb) => {
      setCBreadcrumbOne({ breadcrumbLink, breadcrumbTitle })
    },
    [],
  )

  const UBreadcrumbTwo = useCallback(
    ({ breadcrumbLink, breadcrumbTitle }: IBreadcrumb) => {
      setCBreadcrumbTwo({ breadcrumbLink, breadcrumbTitle })
    },
    [],
  )

  const UBreadcrumbCurrent = useCallback(
    ({ breadcrumbLink, breadcrumbTitle }: IBreadcrumb) => {
      setCBreadcrumbCurrent({ breadcrumbLink, breadcrumbTitle })
    },
    [],
  )

  return (
    <BreadcrumbContext.Provider
      value={{
        CBreadcrumbOne,
        UBreadcrumbOne,
        CBreadcrumbTwo,
        UBreadcrumbTwo,
        CBreadcrumbCurrent,
        UBreadcrumbCurrent,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
}

function useBreadcrumb(): BreadcrumbContextData {
  const context = useContext(BreadcrumbContext)

  return context
}

export { useBreadcrumb }
