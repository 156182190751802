import React, {
  useEffect,
  useCallback,
  useState,
  FormEvent,
  MutableRefObject,
  useRef,
} from 'react'

import * as Yup from 'yup'
import { toast } from 'react-toastify'
import IDepartment, { TDepartment } from '../../../interfaces/IDepartment'

import IOkr, { TOkr } from '../../../interfaces/IOkr'
import IWorkshop from '../../../interfaces/IWorkshop'
import { TKpi } from '../../../interfaces/IKPI'

import { useOkr } from '../../../hooks/okr'
import { useDepartment } from '../../../hooks/department'
import { useWorkshop } from '../../../hooks/workshop'

import './styles.scss'
import errorHandler from '../../../utils/errorHandler'
import IPagination from '../../../interfaces/IPagination'
import { SearchPagination } from '../../../interfaces/ISearchPagination'

const ModalAdd: React.FC = () => {
  document.body.classList.add('workshop-modal')

  const [name, setName] = useState<string>('')
  const [okrList, setOkrList] = useState<IPagination<IOkr>>(
    {} as IPagination<IOkr>,
  )
  // const [departamentList, setDepartamentList] = useState<Array<IDepartment>>(
  //   Array<IDepartment>(),
  // )

  const [okrIndex, setOkrIndex] = useState<number | string>(
    'Selecione um objetivo...',
  )
  // const [departmentIndex, setDepartmentIndex] = useState<number>(0)
  const [metaKpiKeyIndex, setMetaKpiKeyIndex] = useState<number>(0)

  const { setIsWorkShopListReloded, Add } = useWorkshop()

  const okrHook = useOkr()
  const departamentHook = useDepartment()

  const workShopObj: MutableRefObject<IWorkshop> = useRef({} as IWorkshop)

  async function validate(): Promise<boolean> {
    const schema = Yup.object().shape({
      name: Yup.string().required('Campo nome é obrigatorio'),
      okrIndex: Yup.number()
        .required('Campo Objetivo é obrigatorio')
        .typeError('Campo Objetivo é obrigatorio'),
    })

    try {
      await schema.validate({
        okrIndex,
        name,
      })
      return true
    } catch (err) {
      errorHandler('Todos os campos são obrigatórios')
      return false
    }
  }

  useEffect(() => {
    okrHook.getAll({ all: true } as SearchPagination).then(response => {
      setOkrList(response)
    })
    // departamentHook.getAll().then(response => {
    //   setDepartamentList(response)
    // })
  }, [])

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault()

      const validation = await validate()
      // console.log(name)
      if (typeof okrIndex === 'number' && validation) {
        const okrListObj: IOkr = okrList.results[okrIndex]
        workShopObj.current.name = name
        workShopObj.current.okr = {} as TOkr
        workShopObj.current.okr._id = okrListObj._id
        workShopObj.current.kpi = {} as TKpi
        workShopObj.current.kpi._id = okrListObj.kpis[0].kpi._id
        workShopObj.current.kpi.name = okrListObj.kpis[0].kpi.name

        await Add(workShopObj.current)

        toast.success(`${workShopObj.current.name} cadastrado.`)

        const element = document.getElementById('workshop-close-modal')
        if (element) {
          element.click()
        }

        setIsWorkShopListReloded(false)
        setName('')
      }
      // workShopObj.current.department = {} as TDepartment
      // workShopObj.current.department._id = departamentList[departmentIndex]._id

      // console.log(workShopObj.current)
    },
    [
      Add,
      name,
      setIsWorkShopListReloded,
      okrIndex,
      okrList,
      // departamentList,
      // departmentIndex,
      metaKpiKeyIndex,
    ],
  )

  return (
    <>
      <div
        className="modal fade"
        id="modalWorkshopAdd"
        aria-labelledby="modalWorkshopAdd"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                Novo Workshop
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                {/* <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Área
                  </label>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="departmentInput"
                      value={departmentIndex}
                      onChange={e =>
                        setDepartmentIndex(parseInt(e.target.value, 10))
                      }
                    >
                      <option defaultValue="Selecione uma área...">
                        Selecione uma área...
                      </option>
                      {departamentList &&
                        departamentList.map((departamentListObj, index) => (
                          <option key={departamentListObj?._id} value={index}>
                            {departamentListObj.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div> */}
                <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Objetivo
                  </label>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="objectiveInput"
                      // value={okrIndex}
                      onChange={e => setOkrIndex(parseInt(e.target.value, 10))}
                    >
                      <option value="Selecione um objetivo..." selected>
                        Selecione um objetivo...
                      </option>
                      {okrList
                        ? okrList?.results?.map((okrListObj: IOkr, index) => (
                            <option key={okrListObj?._id} value={index}>
                              {okrListObj?.name}
                            </option>
                          ))
                        : []}
                      {console.log(okrIndex, 'index')}
                    </select>
                  </div>
                </div>
                <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Nome (Workshop)
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      value={name}
                      onChange={e => {
                        setName(e.target.value)
                      }}
                    />
                  </div>
                  <h6>
                    Qual métrica queremos focar em melhorar com o workshop?
                  </h6>
                </div>
                {/* <div className="input-content">
                  <label htmlFor="basic-url" className="form-label labelForm">
                    Métrica-chave
                  </label>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="metaKpiKeyInput"
                      value={metaKpiKeyIndex}
                      onChange={e =>
                        setMetaKpiKeyIndex(parseInt(e.target.value, 10))
                      }
                    >
                      <option defaultValue="Selecione um indicador...">
                        Selecione um indicador...
                      </option>
                      {okrList &&
                        okrList[okrIndex]?.kpis?.map((okrListObj, index) => (
                          <option key={okrListObj?.kpi?._id} value={index}>
                            {okrListObj?.kpi?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div> */}
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Salvar
                </button>
                <button
                  id="workshop-close-modal"
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalAdd
