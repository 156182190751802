import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { BsCardList } from 'react-icons/bs'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { ImEye } from 'react-icons/im'
import Header from '../../components/Header'
import Menu from '../../components/Menu'

import { useWorkshop } from '../../hooks/workshop'

import ModalAdd from '../../components/Workshop/ModalAdd'

import dateToString from '../../utils/dateToString'

import { useTheme } from '../../hooks/theme'

import './styles.scss'
import { IWorkshopListRequest } from '../../interfaces/IWorkshop'
import { SearchPagination } from '../../interfaces/ISearchPagination'
import Pagination from '../../components/Pagination'
import { LIMITS, LIMITTABLES } from '../../config/constLimits'
import { DIRECTION, ORDERBY, ORDERBYWORKSHOP } from '../../config/constOrders'
import IOrderPagination from '../../interfaces/IOrderPagination'
import PaginationEngine from '../../components/PaginationEngine'

const ViewWorkshop: React.FC = () => {
  const { isRetractable, setIsRetractable } = useTheme()
  useTheme().addPageStyle('view-workshop')
  useTheme().changeTheme()

  const { search, pathname } = useLocation()

  const {
    qLimit = LIMITTABLES,
    qPage = 1,
    qOrderBy = ORDERBY,
    qDirection = DIRECTION,
  } = queryString.parse(search)

  const [limit, setLimit] = useState<number>(Number(qLimit))
  const [page, setPage] = useState<number>(Number(qPage))
  const [orderBy, setOrderBy] = useState<string>(`${qOrderBy}`)
  const [direction, setDirection] = useState<string>(`${qDirection}`)

  const {
    getAll,
    isWorkShopListReloded,
    CWorkShopList,
    setCWorkShopList,
    setIsWorkShopListReloded,
  } = useWorkshop()

  useEffect(() => {
    setIsRetractable(false)
  }, [])

  useEffect(() => {
    if (!isWorkShopListReloded) {
      getAll({
        page,
        limit,
        orderBy,
        direction,
      } as SearchPagination).then(list => {
        setCWorkShopList(list)
        setIsWorkShopListReloded(true)
      })
    }
  }, [
    getAll,
    isWorkShopListReloded,
    setCWorkShopList,
    setIsWorkShopListReloded,
    limit,
    page,
  ])

  const handlePage = (p: number): void => {
    setPage(p)
    setIsWorkShopListReloded(false)
  }

  const handleLimit = (e: number): void => {
    setLimit(e)
    setIsWorkShopListReloded(false)
  }

  const handleOnClick = (id: string): void => {
    window.location.href = `/workshop/${id}`
  }

  const handleDirection = (): void => {
    if (direction == 'ASC') {
      setDirection('DESC')
    } else {
      setDirection('ASC')
    }
    setIsWorkShopListReloded(false)
  }

  const handleOrder = (value: string): void => {
    setOrderBy(value)
    setIsWorkShopListReloded(false)
  }

  const getSearch = (p: number, l: number, o: string, d: string): string => {
    return `?qPage=${p}&qLimit=${l}&qOrderBy=${o}&qDirection=${d}`
  }

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />

        <div
          className={isRetractable ? 'wrapper wrapper-retractable' : 'wrapper'}
        >
          <div className="container">
            <header>
              <div className="column-left">
                <h1>Workshop</h1>
                <h2>
                  Engaje sua equipe e construa iniciativas de forma colaborativa
                </h2>
              </div>
              <div className="column-right">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#modalWorkshopAdd"
                >
                  Novo Workshop
                </button>
              </div>
            </header>

            <main>
              <table className="table table-sm table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Área</th>
                    <th scope="col">Objetivo</th>
                    <th scope="col">Nome</th>
                    {/* <th scope="col">Métrica-chave</th> */}
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {CWorkShopList?.results &&
                    CWorkShopList.results?.map(
                      (workShopList: IWorkshopListRequest) => (
                        <tr
                          key={workShopList?._id}
                          onClick={() => handleOnClick(workShopList?._id)}
                          className="linha"
                        >
                          <td>
                            <Link
                              to={{
                                pathname: `/workshop/${workShopList?._id}`,
                              }}
                            >
                              Início{' '}
                              {workShopList?.createdAt &&
                                dateToString(workShopList?.createdAt)}
                              <br />
                              Fim{' '}
                              {workShopList?.createdAt &&
                                dateToString(workShopList?.createdAt)}
                            </Link>
                          </td>
                          <td>
                            {' '}
                            <Link
                              to={{
                                pathname: `/workshop/${workShopList?._id}`,
                              }}
                            >
                              {workShopList?.department?.name}
                            </Link>
                          </td>
                          <td>
                            {' '}
                            <Link
                              to={{
                                pathname: `/workshop/${workShopList?._id}`,
                              }}
                            >
                              {workShopList?.okr?.name}
                            </Link>
                          </td>
                          <td>
                            {' '}
                            <Link
                              to={{
                                pathname: `/workshop/${workShopList?._id}`,
                              }}
                            >
                              {workShopList?.name}
                            </Link>
                          </td>
                          {/* <td>
                          {' '}
                          <Link
                            to={{
                              pathname: `/workshop/${workShopList?._id}`,
                            }}
                          >
                            {workShopList?.kpi?.name}
                          </Link>
                        </td> */}
                          <td>
                            {' '}
                            <Link
                              to={{
                                pathname: `/workshop/${workShopList?._id}`,
                              }}
                            >
                              {workShopList?.status}
                            </Link>
                          </td>
                        </tr>
                      ),
                    )}
                </tbody>
                <tfoot />
              </table>
              <ModalAdd />
              <div className="row">
                <div className="col-6 col-md-4" />
                <div className="col-6 col-md-4 ">
                  <Pagination
                    pathname={pathname}
                    page={CWorkShopList.page}
                    previousPage={CWorkShopList.previousPage}
                    nextPage={CWorkShopList.nextPage}
                    perPage={CWorkShopList.perPage}
                    handlePage={value => handlePage(value)}
                    orderBy={orderBy}
                    direction={direction}
                  />
                </div>
                <div className="col-6 col-md-4">
                  <PaginationEngine
                    pathname={pathname}
                    page={CWorkShopList.page}
                    limit={limit}
                    orderBy={orderBy}
                    direction={direction}
                    limits={LIMITS}
                    orders={ORDERBYWORKSHOP}
                    getSearch={getSearch}
                    handleLimit={handleLimit}
                    handleOrder={handleOrder}
                    handleDirection={handleDirection}
                  />
                </div>
              </div>
              <div />
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewWorkshop
