interface enumFormat {
  id: string
  name: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/ban-types
const convertEnumToArray = (enumName: object): enumFormat[] => {
  const finalArray: enumFormat[] = []
  // eslint-disable-next-line no-restricted-syntax
  for (const [propertyKey, propertyValue] of Object.entries(enumName)) {
    if (!Number.isNaN(Number(propertyKey))) {
      // eslint-disable-next-line no-continue
      continue
    }
    finalArray.push({ id: propertyValue, name: propertyKey })
  }

  return finalArray
}

// eslint-disable-next-line import/prefer-default-export
export { convertEnumToArray }
