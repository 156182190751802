import React, { FormEvent, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { useBreadcrumb } from '../../hooks/breadcrumbs'
import Breadcrumbs from '../../components/BreadCrumbs'

import { useTheme } from '../../hooks/theme'
import ModalAdd from '../../components/Okr/ModalAdd'
import { DepartmentList } from '../../interfaces/IDepartment'
// 'src\components\Okr\ModalAdd'

import './styles.scss'
import { useDepartment } from '../../hooks/department'
import { useKpis } from '../../hooks/kpis'
import IKPI from '../../interfaces/IKPI'
import schema from './schema'
import errorHandler from '../../utils/errorHandler'

const RegisterKPI: React.FC = () => {
  useTheme().addPageStyle('register-kpi')
  useTheme().changeTheme()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { UBreadcrumbOne, UBreadcrumbCurrent } = useBreadcrumb()
  const { getAll } = useDepartment()
  const { Add, getById, Edit } = useKpis()
  const [deptList, setDeptList] = useState<DepartmentList | undefined>(
    undefined,
  )
  const [kpi, setKpi] = useState({} as IKPI)
  const [kpiForm, setKpiForm] = useState({
    name: '',
    fullName: '',
    unit: '',
    direction: '',
    description: '',
    formula: '',
  })

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    try {
      await schema.validate({ ...kpiForm })
    } catch (error) {
      errorHandler('Todos os campos são obrigatórios')
      return
    }

    const model = {
      ...kpiForm,
      _id: kpi._id,
      value: kpi.value || 0,
    }
    try {
      if (id) {
        await Edit(model)
      } else {
        await Add(model)
      }
      toast.success(`${id ? 'Atualizado' : 'Criado'} com sucesso`)
      history.push('/kpi')
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (name: string, value: string): void => {
    setKpiForm({ ...kpiForm, [name]: value })
  }

  useEffect(() => {
    UBreadcrumbOne({ breadcrumbLink: '/kpi', breadcrumbTitle: 'Indicadores' })
    UBreadcrumbCurrent({
      breadcrumbLink: '/',
      breadcrumbTitle: id ? 'Edição de Indicador' : 'Cadastro de Indicador',
    })
  }, [UBreadcrumbCurrent, UBreadcrumbOne])

  useEffect(() => {
    ;(async () => {
      if (deptList) return
      const list = await getAll()
      setDeptList(list)
    })()
  }, [getAll, deptList])

  useEffect(() => {
    ;(async () => {
      if (!id) return
      const data = await getById(id)
      setKpi(data)
      setKpiForm({
        ...kpiForm,
        name: data.name,
        fullName: data.fullName || '',
        unit: data.unit || '',
        direction: data.direction || '',
        description: data.description || '',
        formula: data.formula || '',
      })
    })()
  }, [])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div className="wrapper">
          <div className="container">
            <header>
              <div className="breadcrumb">
                <Breadcrumbs />
              </div>
            </header>
            <main>
              <form onSubmit={handleSubmit}>
                <h1>Indicador</h1>
                <h2>
                  Defina o que você e a sua equipe precisam alcançar para
                  crescer
                </h2>
                <div className="row">
                  {/* <div className="col-3">
                    <div className="input-content  action">
                      <label htmlFor="basic-url" className="form-label">
                        Área
                      </label>
                      <div className="input-group">
                        <select
                          className="form-select"
                          id="actionSelector"
                          value={kpiForm.department}
                          onChange={e =>
                            handleChange('department', e.target.value)
                          }
                        >
                          <option defaultValue="Selecione uma ação">
                            Selecione uma área
                          </option>
                          {deptList?.map(dept => (
                            <option key={dept._id} value={dept._id}>
                              {dept.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-2">
                    <div className="input-content  objective">
                      <label htmlFor="basic-url" className="form-label">
                        Sigla ou Abreviação
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          aria-describedby="basic-addon3"
                          value={kpiForm.name}
                          onChange={e => handleChange('name', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="input-content data-initial">
                      <label htmlFor="basic-url" className="form-label">
                        Nome completo
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          name="fullName"
                          aria-describedby="basic-addon3"
                          value={kpiForm.fullName}
                          onChange={e => {
                            handleChange('fullName', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-content">
                      <label htmlFor="basic-url" className="form-label">
                        Unidade
                      </label>
                      <div className="input-group">
                        <select
                          className="form-select"
                          id="unit"
                          name="unit"
                          value={kpiForm.unit}
                          onChange={e => handleChange('unit', e.target.value)}
                        >
                          <option defaultValue="Selecione uma ação">
                            Selecione uma unidade
                          </option>
                          <option value="Percentual">Percentual</option>
                          <option value="Monetária">Monetário</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-content">
                      <label htmlFor="basic-url" className="form-label">
                        Direção
                      </label>
                      <div className="input-group">
                        <select
                          className="form-select"
                          id="direction"
                          name="direction"
                          onChange={e =>
                            handleChange('direction', e.target.value)
                          }
                          value={kpiForm.direction}
                        >
                          <option defaultValue="Selecione uma ação">
                            Selecione uma direção
                          </option>
                          <option value="Crescente">Crescente</option>
                          <option value="Decrescente">Decrescente</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-10">
                    <div className="input-content data-initial">
                      <label htmlFor="basic-url" className="form-label">
                        Descrição
                      </label>
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          aria-describedby="basic-addon3"
                          value={kpiForm.description}
                          onChange={e => {
                            handleChange('description', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="input-content data-initial">
                      <label htmlFor="basic-url" className="form-label">
                        Formula
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="formula"
                          name="formula"
                          aria-describedby="basic-addon3"
                          value={kpiForm.formula}
                          onChange={e => {
                            handleChange('formula', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="actions">
                  <button type="submit" className="save">
                    Salvar
                  </button>
                  <button type="button" className="clean">
                    Descartar alterações
                  </button>
                </div>
              </form>
              <ModalAdd />
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterKPI
