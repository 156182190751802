import React, { useEffect, useState, FormEvent } from 'react'

import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ValidationError } from 'yup'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { useBreadcrumb } from '../../hooks/breadcrumbs'
import Breadcrumbs from '../../components/BreadCrumbs'

import { useOkr } from '../../hooks/okr'

import { useKpiBase } from '../../hooks/kpiBase'
import IOkr, { Actions } from '../../interfaces/IOkr'
import { convertEnumToArray } from '../../utils/convertEnumToArray'
import IAction from '../../interfaces/IAction'
import dateSplit from '../../utils/dateSplit'

import { useTheme } from '../../hooks/theme'
import ModalAdd from '../../components/Okr/ModalAdd'

// 'src\components\Okr\ModalAdd'

import './styles.scss'
import schema from './schema'
import { useKpis } from '../../hooks/kpis'
import { SearchPagination } from '../../interfaces/ISearchPagination'
import IDepartment from '../../interfaces/IDepartment'

interface MergedKPIS {
  name: string
  _id: string | number
  direction: string
}

interface MergedKpisObject {
  kpis: MergedKPIS[]
  kpisBase: MergedKPIS[]
}

const RegisterObjective: React.FC = () => {
  const history = useHistory()
  useTheme().addPageStyle('register-objective')
  useTheme().changeTheme()

  const { UBreadcrumbOne, UBreadcrumbCurrent } = useBreadcrumb()

  const [kpis, setKpis] = useState<MergedKpisObject>({ kpis: [], kpisBase: [] })
  const [editedOkr, setEditedOkr] = useState<IOkr>({} as IOkr)
  const [dateError, setDateError] = useState('')
  const [actions, setActions] = useState<IAction[]>([])
  const [okrForm, setOkrForm] = useState({
    name: '',
    action: '',
    initialDate: '',
    finalDate: '',
    kpis: [
      {
        kpi: {
          _id: '',
          value: 0,
        },
        direction: '',
        unit: '',
        _id: '',
      },
    ],
    status: 'active' as IOkr['status'],
  })

  const okrHook = useOkr()
  const kpiBase = useKpiBase()
  const kpiHooks = useKpis()

  const { id } = useParams<{ id: string }>()

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target
    const { value } = e.target

    if (name === 'value') {
      const kpisModel = {
        ...okrForm.kpis[0],
        kpi: {
          ...okrForm.kpis[0].kpi,
          [name]: parseInt(value, 10),
        },
      }
      setOkrForm({ ...okrForm, kpis: [kpisModel] })
    } else {
      setOkrForm({ ...okrForm, [name]: value })
    }
  }

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const { name } = e.target
    const { value } = e.target

    if (name === 'action') {
      return setOkrForm({ ...okrForm, [name]: value })
    }
    if (name === 'kpis') {
      const kpisModel = {
        ...okrForm.kpis[0],
        kpi: {
          ...okrForm.kpis[0].kpi,
          _id: value,
        },
      }
      return setOkrForm({ ...okrForm, kpis: [kpisModel] })
    }

    const kpisModel = {
      ...okrForm.kpis[0],
      [name]: value,
    }
    return setOkrForm({ ...okrForm, kpis: [kpisModel] })
  }

  const onDateInputKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const input = e.target as HTMLInputElement
    const code = parseInt(e.code, 10)
    if (code < 47 || code) {
      e.preventDefault()
    }
    const len = input.value.length

    if (len !== 1 && len !== 3) {
      if (code == 47) {
        e.preventDefault()
      }
    }

    if (len === 2) {
      input.value += '/'
    }

    if (len === 5) {
      input.value += '/'
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setDateError('')
    try {
      await schema.validate({
        ...okrForm,
        initialDate: `${dateSplit(okrForm.initialDate)[2]}/${
          dateSplit(okrForm.initialDate)[1]
        }/${dateSplit(okrForm.initialDate)[0]}`,
        finalDate: `${dateSplit(okrForm.finalDate)[2]}/${
          dateSplit(okrForm.finalDate)[1]
        }/${dateSplit(okrForm.finalDate)[0]}`,
        direction: okrForm.kpis[0].direction,
        unit: okrForm.kpis[0].unit,
        kpi: okrForm.kpis[0].kpi._id,
      })
      const model = {
        ...okrForm,
        initialDate: new Date(
          parseInt(dateSplit(okrForm.initialDate)[2], 10),
          parseInt(dateSplit(okrForm.initialDate)[1], 10) - 1,
          parseInt(dateSplit(okrForm.initialDate)[0], 10),
        ),
        finalDate: new Date(
          parseInt(dateSplit(okrForm.finalDate)[2], 10),
          parseInt(dateSplit(okrForm.finalDate)[1], 10) - 1,
          parseInt(dateSplit(okrForm.finalDate)[0], 10),
        ),
        department: {} as IDepartment,
        kpis: [
          {
            kpi: {
              _id: okrForm.kpis[0].kpi._id,
            },
            value: okrForm.kpis[0].kpi.value,
            direction: okrForm.kpis[0].direction,
            unit: okrForm.kpis[0].unit,
          },
        ],
      }
      if (!id) {
        await okrHook
          .Add({ ...model })
          .then(() => {
            toast.success('Okr criada com sucesso!')
            okrHook.setIsCOkrListReloaded(false)
            history.goBack()
          })
          .catch(() => {
            return false
          })
      } else {
        await okrHook
          .UpdateFormOkr({
            ...model,
            _id: editedOkr._id,
          })
          .then(() => {
            toast.success('Okr editada com sucesso!')
            okrHook.setIsCOkrListReloaded(false)
            history.goBack()
          })
          .catch(() => {
            return false
          })
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    UBreadcrumbOne({ breadcrumbLink: '/okr', breadcrumbTitle: 'Objetivos' })
    UBreadcrumbCurrent({
      breadcrumbLink: '/',
      breadcrumbTitle: 'Cadastro de Objetivo',
    })
    setActions(convertEnumToArray(Actions))

    // get kpi lists and okr when is updating a okr
    ;(async () => {
      // get kpi lists
      const [kpisBaseList, kpisList] = await Promise.all([
        kpiBase.getAll(),
        kpiHooks.getAll({ all: true } as SearchPagination),
      ])
      setKpis({
        kpis: kpisList.results.sort((a, b) => a.name.localeCompare(b.name)),
        kpisBase: kpisBaseList.sort((a, b) => a.name.localeCompare(b.name)),
      })
      // get okr
      if (id) {
        const okr = await okrHook.getById(id)
        const currentKpi = [...kpisBaseList, ...kpisList.results].find(
          kpi => kpi._id === okr.kpis[0].kpi._id,
        )
        const kpiModel = {
          kpi: {
            _id: okr.kpis[0].kpi._id,
            value: okr.kpis[0].kpi.value,
          },
          updates: okr.kpis[0].updates,
          direction: currentKpi?.direction || '',
          unit: currentKpi?.unit || '',
          _id: okr.kpis[0]._id,
        }
        setOkrForm({
          ...okr,
          initialDate: new Date(okr.initialDate).toLocaleDateString('pt-BR'),
          finalDate: new Date(okr.finalDate).toLocaleDateString('pt-BR'),
          kpis: [kpiModel],
        })
        setEditedOkr(okr)
      }
    })()
  }, [UBreadcrumbCurrent, UBreadcrumbOne, id, kpiBase, kpiHooks, okrHook])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div className="wrapper">
          <div className="container">
            <header>
              <div className="breadcrumb">
                <Breadcrumbs />
              </div>
            </header>
            <main>
              <form onSubmit={handleSubmit}>
                <h1>Objetivos</h1>
                <h2>
                  Defina o que você e a sua equipe precisam alcançar para
                  crescer
                </h2>
                <div className=" row-1">
                  <div className="input-content  action">
                    <label htmlFor="basic-url" className="form-label">
                      Ação
                    </label>
                    <div className="input-group">
                      <select
                        className="form-select"
                        id="action"
                        name="action"
                        value={okrForm.action}
                        onChange={handleSelectChange}
                      >
                        <option defaultValue="Selecione uma ação">
                          Selecione uma ação
                        </option>
                        {actions.map(act => (
                          <option key={act.id} value={act.id}>
                            {act.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input-content  objective">
                    <label htmlFor="basic-url" className="form-label">
                      Objetivo
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        aria-describedby="basic-addon3"
                        placeholder="Especifique o que você deseja atingir"
                        value={okrForm.name}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row-2">
                  <div className="input-content data-initial">
                    <label htmlFor="basic-url" className="form-label">
                      Data de Início
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="initialDate"
                        name="initialDate"
                        aria-describedby="basic-addon3"
                        placeholder="___/___/______"
                        value={okrForm.initialDate}
                        onKeyPress={onDateInputKeyPress}
                        onChange={handleFormChange}
                      />
                      <span className="input-group-text" id="basic-addon3">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 10H20V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946
                        0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V10ZM15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20
                        2.73478 20 3V8H0V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7V2H13V0H15V2Z"
                            fill="#518CD5"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="input-content data-end">
                    <label htmlFor="basic-url" className="form-label">
                      Data Final
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="basic-addon3"
                        placeholder="___/___/______"
                        value={okrForm.finalDate}
                        onKeyPress={onDateInputKeyPress}
                        id="finalDate"
                        name="finalDate"
                        onChange={handleFormChange}
                      />
                      <span className="input-group-text" id="basic-addon3">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 10H20V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946
                        0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V10ZM15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20
                        2.73478 20 3V8H0V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7V2H13V0H15V2Z"
                            fill="#518CD5"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    {!!dateError && (
                      <small>
                        <p className="text-danger">{dateError}</p>
                      </small>
                    )}
                  </div>
                </div>
                <h3>Metas-Chave</h3>
                <div className="rows">
                  <div className=" row-3">
                    <select
                      className=" form-select  indicator "
                      id="kpis"
                      name="kpis"
                      value={okrForm.kpis[0].kpi._id}
                      placeholder=" Selecione um indicador"
                      onChange={handleSelectChange}
                    >
                      <option defaultValue="Selecione um indicador">
                        Selecione um indicador
                      </option>
                      <optgroup label="Kpis criados">
                        {kpis.kpis.map(kpi => (
                          <option key={kpi._id} value={kpi._id}>
                            {kpi.name}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup className="m-5" label="KPIs da base">
                        {kpis.kpisBase.map(kpi => (
                          <option key={kpi._id} value={kpi._id}>
                            {kpi.name}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                    <select
                      className="form-select  action"
                      id="direction"
                      name="direction"
                      value={okrForm.kpis[0].direction}
                      onChange={handleSelectChange}
                    >
                      <option defaultValue="Selecione uma direção">
                        Selecione uma direção
                      </option>
                      <option value="Aumentar">Aumentar</option>
                      <option value="Reduzir">Reduzir</option>
                    </select>

                    <select
                      className="form-select  type"
                      id="unit"
                      name="unit"
                      value={okrForm.kpis[0].unit}
                      onChange={handleSelectChange}
                    >
                      <option defaultValue="Selecione uma unidade">
                        Selecione uma unidade
                      </option>
                      <option value="Absoluto">Absoluto</option>
                      <option value="Percentual">Percentual</option>
                    </select>
                    <div className="col-2">
                      <input
                        type="text"
                        className="form-control m-3"
                        id="value"
                        name="value"
                        aria-describedby="basic-addon3"
                        placeholder="Valor"
                        value={okrForm.kpis[0].kpi.value}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div className="actions">
                      <button type="submit" className="save">
                        Salvar
                      </button>
                      <button type="button" className="clean">
                        Descartar alterações
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <ModalAdd />
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterObjective
