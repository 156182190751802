/* eslint-disable consistent-return */
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'

import api from '../services/api'

//
//
/**
 * A interface deve ficar em um arquivo separado, pois ela determina os atributos do objeto
 * Usando o export default, o nome pode ser genérico, alterando apenas o endereço da interface a ser utilizada
 */
import IExperiments, { ExperimentList } from '../interfaces/IExperiments'
import IIdea from '../interfaces/IIdea'
import errorHandler from '../utils/errorHandler'

// Alterar para o nome da Model (que é o mesmo nome da rota)
const apiRoute = 'experiments'

// Não precisa alterar, é igual para todos
interface ThisProviderProps {
  children: ReactNode
}

/**
 * Métodos exportados pelo Context
 */
interface ThisContextData {
  cKanbanIdea: IIdea
  setCKanbanIdea: Dispatch<SetStateAction<IIdea>>
  cKanbanColumnName: string
  setCKanbanColumnName: Dispatch<SetStateAction<string>>
  cKanbanColumnIndex: number
  setCKanbanColumnIndex: Dispatch<SetStateAction<number>>
  cIdeaIndex: number
  setCIdeaIndex: Dispatch<SetStateAction<number>>
  cExperimentIndex: number
  setCExperimentIndex: Dispatch<SetStateAction<number>>
  isExperimentListReloded: boolean
  setIsExperimentListReloded: Dispatch<SetStateAction<boolean>>
  CExperimentList: Array<IExperiments>
  setCExperimentList: Dispatch<SetStateAction<Array<IExperiments>>>
  getAll: () => Promise<Array<IExperiments>>
  getById: (ob: IExperiments) => Promise<IExperiments>
  Add: (obj: IExperiments) => Promise<void>
  Update: (obj: IExperiments) => Promise<IExperiments[]>
  Delete: (id: number) => Promise<void>
}

const ThisContext = createContext<ThisContextData>({} as ThisContextData)

const ExperimentProvider: React.FC = ({ children }) => {
  const [cKanbanIdea, setCKanbanIdea] = useState<IIdea>({} as IIdea)
  const [cKanbanColumnName, setCKanbanColumnName] = useState<string>('')
  const [cIdeaIndex, setCIdeaIndex] = useState<number>(0)
  const [cKanbanColumnIndex, setCKanbanColumnIndex] = useState<number>(0)
  const [cExperimentIndex, setCExperimentIndex] = useState<number>(0)
  const [isExperimentListReloded, setIsExperimentListReloded] =
    useState<boolean>(false)
  const [CExperimentList, setCExperimentList] = useState<Array<IExperiments>>(
    Array<IExperiments>(),
  )

  /**
   * Obtém todos os objetos
   * @returns returna um array de objetos
   */
  // const getAll = useCallback(async (): Promise<IExperiments[]> => {
  //   try {
  //     return api.get(apiRoute).then(response => {
  //       // console.log(`getAll >> ${JSON.stringify(response.data)}`)
  //       return response.data
  //     })
  //   } catch (error) {
  //     errorHandler(
  //       'Ocorreu um erro ao listar os experimentos, tente novamente mais tarde',
  //     )
  //     return []
  //   }
  // }, [])
  const getAll = useCallback(async (): Promise<IExperiments[]> => {
    const result = await api
      .get(apiRoute)
      .then(response => {
        // console.log(`getAll >> ${JSON.stringify(response.data)}`)
        return response.data
      })
      .catch(error => {
        errorHandler(
          'Ocorreu um erro ao listar os experimentos, tente novamente mais tarde',
        )
        return []
      })

    return result
  }, [])

  /**
   * Retorna o objeto usando o ID como parâmetro
   * Para retornar o objeto o mesmo deve ser informado no response
   * @param id
   * @returns retorna o objeto
   */
  const getById = useCallback(
    async (ob: IExperiments): Promise<IExperiments> => {
      try {
        return api.get(`${apiRoute}/${ob._id}`).then(response => {
          // eslint-disable-next-line no-console
          // console.log(`getById >> ${JSON.stringify(response.data)}`)
          return response.data
        })
      } catch (error) {
        errorHandler(
          'Ocorreu um erro ao buscar o experimento, tente novamente mais tarde',
        )
        return {} as IExperiments
      }
    },
    [],
  )

  /**
   * Adiciona um registro no DB
   * @param ob: IObject
   */
  const Add = useCallback(async (ob: IExperiments): Promise<void> => {
    try {
      api.post(apiRoute, ob).then(response => {
        // console.log(`Add >> ${JSON.stringify(response.data)}`)
        return response.data
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao criar o experimento, tente novamente mais tarde',
      )
    }
  }, [])

  /**
   * Atualiza os dados do registro como o ID informado
   * @param ob
   * @returns retorna o objeto atualizado
   */
  const Update = useCallback(
    async (ob: IExperiments): Promise<IExperiments[]> => {
      try {
        return api.put(`${apiRoute}/${ob._id}`, ob).then(response => {
          // console.log(`Update >> ${JSON.stringify(response.data)}`)
          return response.data
        })
      } catch (error) {
        errorHandler(
          'Ocorreu um erro ao atualizar o experimento, tente novamente mais tarde',
        )
        return [] as Array<IExperiments>
      }
    },
    [],
  )

  /**
   * Apaga o registro conforme o ID informado
   * @param id
   * @returns retorna o status 204
   */
  const Delete = useCallback(async (id: number): Promise<void> => {
    try {
      return api.delete(`${apiRoute}/${id}`).then(response => {
        // console.log(`Delete >> ${JSON.stringify(response)}`)
      })
    } catch (error) {
      errorHandler(
        'Ocorreu um erro ao deletar o experimento, tente novamente mais tarde',
      )
    }
  }, [])

  return (
    <ThisContext.Provider
      value={{
        cKanbanIdea,
        setCKanbanIdea,
        cKanbanColumnName,
        setCKanbanColumnName,
        cIdeaIndex,
        setCIdeaIndex,
        cKanbanColumnIndex,
        setCKanbanColumnIndex,
        cExperimentIndex,
        setCExperimentIndex,
        isExperimentListReloded,
        setIsExperimentListReloded,
        CExperimentList,
        setCExperimentList,
        getAll,
        getById,
        Add,
        Update,
        Delete,
      }}
    >
      {children}
    </ThisContext.Provider>
  )
}

export default ExperimentProvider

export function useEperiment(): ThisContextData {
  const context = useContext(ThisContext)

  return context
}
