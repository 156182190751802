import { useContext, useEffect, useState } from 'react'
import { WorkshopContext } from '../../../pages/Workshop/WorkshopStageManager'
import useWorkshopProblem from '../../../hooks/workshop/useWorkshopProblem'
import useWorkshopIdeas from '../../../hooks/workshop/useWorkshopIdeas'
import './styles.scss'

export const WorkshopAdvanceButton: React.FC = () => {
  const [isCard, setCard] = useState<boolean>(true)
  const { cards } = useWorkshopProblem()
  const { ideasCards } = useWorkshopIdeas()
  const { nextStage, isAdmin, workshop, prevStage, getAdvanceButtom } =
    useContext(WorkshopContext)
  const [isAdvance, setAdvance] = useState(false)

  useEffect(() => {
    if (isAdmin) {
      // console.log('Etapa: ', workshop?.stage)

      // console.log('Advance status: ', getAdvanceButtom())
      setAdvance(getAdvanceButtom())

      if (workshop?.stage < 6 && cards?.length > 0) {
        setCard(false)
        setAdvance(true)
      }
      if (workshop?.stage >= 6 && isAdvance) {
        setCard(false)
      }
      if ([2, 4, 5, 9, 11, 13].includes(workshop?.stage)) {
        setCard(false)
      }
    }
  }, [
    cards?.length,
    getAdvanceButtom,
    ideasCards.length,
    isAdmin,
    isAdvance,
    workshop?.stage,
  ])

  return (
    <>
      {isAdmin && (
        <>
          <button
            className="workshop-preview-button"
            type="button"
            onClick={e => prevStage()}
          >
            Voltar
          </button>

          <span className="span-space" />

          <button
            className={
              !isCard && isAdvance
                ? 'workshop-advance-button'
                : 'workshop-advance-button-disabled'
            }
            type="button"
            disabled={!isAdvance}
            onClick={e => nextStage()}
          >
            Avançar
          </button>
        </>
      )}
    </>
  )
}

export default WorkshopAdvanceButton
