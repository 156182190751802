import { useContext, useEffect, useState } from 'react'
import { WorkshopContext } from '../../../pages/Workshop/WorkshopStageManager'
import './styles.scss'

export type WorkshopCountdownProps = {
  time?: string
  isActive?: boolean
}

export const WorkshopCountdown: React.FC<WorkshopCountdownProps> = () => {
  const { isCountdown, countdown, isAdmin } = useContext(WorkshopContext)
  const [isActive, setIsActive] = useState(isCountdown)
  const [time, setTime] = useState(countdown)
  const [positionButton, setPositionButon] = useState('')

  useEffect(() => {
    setIsActive(isCountdown)
  }, [isCountdown])

  useEffect(() => {
    setTime(countdown)
  }, [countdown])

  useEffect(() => {
    if (isAdmin) {
      setPositionButon('workshop-countdown-button')
    } else {
      setPositionButon(
        'workshop-countdown-button workshop-countdown-button-convidado',
      )
    }
  }, [])

  return (
    <>
      <button
        className={
          isActive ? positionButton : 'workshop-countdown-button-no-visible'
        }
        type="button"
      >
        {time}
      </button>
    </>
  )
}

export default WorkshopCountdown
