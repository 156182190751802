import React, { useEffect, useState } from 'react'

import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { useBreadcrumb } from '../../hooks/breadcrumbs'
import Breadcrumbs from '../../components/BreadCrumbs'

import './styles.scss'

const ViewCompany: React.FC = () => {
  document.body.classList.add('informacoes-cadastrais')

  const { UBreadcrumbOne, UBreadcrumbCurrent } = useBreadcrumb()

  useEffect(() => {
    UBreadcrumbOne({ breadcrumbLink: '/', breadcrumbTitle: 'Organização' })
    UBreadcrumbCurrent({
      breadcrumbLink: '/',
      breadcrumbTitle: 'Informações cadastrais',
    })
  }, [])

  return (
    <>
      <Header />

      <div className="container-fluid conteudo">
        <Menu />
        <div className="container">
          <header>
            <div className="breadcrumb">
              <Breadcrumbs />
            </div>
          </header>
          <main>
            <h1>Nome curto</h1>
            <p className="nome-fantasia">Nome Fantasia</p>
            <p className="cnpj">CNPJ 00.000.000/0000-00</p>

            <h2>Atividades Econômicas</h2>
            <ul>
              <li>Atividade econômica principal</li>
              <li>Atividade econômica secundária</li>
              <li>Atividade econômica secundária</li>
              <li>Atividade econômica secundária</li>
              <li>Atividade econômica secundária</li>
            </ul>

            <h2>Contatos</h2>
            <ul>
              <li>
                Administrador: administrador_nome · administrador_email ·
                administrador_celular
              </li>
              <li>
                Financeiro: financeiro_nome · financeiro_email ·
                financeiro_celular
              </li>
            </ul>
          </main>
        </div>
      </div>
    </>
  )
}

export default ViewCompany
