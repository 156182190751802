import React, { useContext, useMemo } from 'react'
import { WorkshopContext } from '../../../pages/Workshop/WorkshopStageManager'
import './styles.scss'

export interface IWorkshopInitCounterProps {
  onClick: () => void
}

export const WorkshopInitCounter: React.FC<IWorkshopInitCounterProps> = ({
  onClick,
}) => {
  const { isAdmin } = useContext(WorkshopContext)
  const spanText = useMemo(
    () =>
      isAdmin
        ? 'Inicie a contagem de tempo para começar'
        : 'Aguardando início...',
    [isAdmin],
  )

  return (
    <div className="workshop-init-counter">
      <span>{spanText}</span>

      {isAdmin && (
        <button type="button" onClick={onClick}>
          Iniciar contagem
        </button>
      )}
    </div>
  )
}

export default WorkshopInitCounter
