import React, {
  createContext,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react'
import { useHistory, useLocation } from 'react-router'
import ReactTooltip from 'react-tooltip'
import { ToastContainer, toast } from 'react-toastify'
import AppProvider from './hooks'

import Routes from './routes'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'react-toastify/dist/ReactToastify.css'

import './styles/index.scss'

import api from './services/api'

import IUser, { TUser, TUserId } from './interfaces/IUser'

import { ISignIn } from './interfaces/IAuth'

import AuthContext from './context/auth'
import ProgressBar from './components/ProgressBar/ProgressBar'

function useQuery(): any {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const App: React.FC = () => {
  document.body.classList.add('sistema')

  const [Signed, setSigned] = useState<boolean>(false)
  const [user, setUser] = useState<IUser>({ id: 'tt' } as IUser)
  const history = useHistory()
  const location = useLocation<{ from: string }>()

  const query = useQuery()

  const SignIn = useCallback(
    async (obj: IUser): Promise<ISignIn> => {
      const result = await api
        .post(`users/login`, obj)
        .then(response => {
          const token = response.headers.authorization
          // console.log(response)

          api.defaults.headers.Authorization = token
          localStorage.setItem('token', token)
          localStorage.setItem(
            'user',
            JSON.stringify({ id: response.data._id }),
          )
          // console.log(response)

          setUser({ id: response.data._id })
          // console.log(user)

          return {
            ...response.data,
            token,
          }
        })
        .catch(function (error) {
          // console.log(error)

          error?.response?.data?.errors?.map((err: any) => alert(err.message))
        })

      // console.log('result:')

      // console.log(result)

      return result
    },
    [user, setUser],
  )

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const userStorage = localStorage.getItem('user') || ''
      const userStorageJson = JSON.parse(userStorage)
      setUser(userStorageJson)
    }
  }, [])

  useEffect(() => {
    if (location.pathname != '/login') {
      localStorage.setItem('pathname', `${location.pathname}${location.search}`)
    }
  }, [location])

  useEffect(() => {
    // console.log(query.get('tokenExpired'))

    if (Signed) {
      const token = localStorage.getItem('token')

      api.defaults.headers.Authorization = token
      setSigned(false)

      if (localStorage.getItem('pathname')) {
        history.push(`${localStorage.getItem('pathname') || '/workshop'}`)
      } else {
        history.push('/workshop')
      }
    }

    if (query.get('tokenExpired') == 'expired') {
      setSigned(false)

      if (!Signed) {
        toast.error('Sua sessão expirou.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        query.set('tokenExpired', '')
        setUser({})
      }
    }
  }, [Signed, query])

  return (
    <>
      <AuthContext.Provider
        value={{
          Signed,
          setSigned,
          user,
          SignIn,
          // SignOut,
          setUser,
        }}
      >
        <AppProvider>
          <Routes />
          <ProgressBar />
          <ReactTooltip />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            draggable
            pauseOnFocusLoss
            pauseOnHover
          />
        </AppProvider>
      </AuthContext.Provider>
    </>
  )
}

export default App
