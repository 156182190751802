import { toast } from 'react-toastify'

const errorHandler = (errorMessage: string): void => {
  toast.error(errorMessage, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export default errorHandler
