import React from 'react'

interface IModalProps {
  id: string
  title: string
  hasCloseButton?: boolean
  size?: 'default' | 'large' | 'small'
  hasFooter?: boolean
}

const Modal: React.FC<IModalProps> = ({
  id,
  title,
  hasCloseButton = true,
  size = 'default',
  children,
  hasFooter = true,
}) => {
  const getModalSize = (): string => {
    if (size === 'large') return 'modal-lg'
    if (size === 'small') return 'modal-sm'
    return ''
  }
  return (
    <div className="modal fade" id={id} aria-labelledby={id} aria-hidden="true">
      <div className={`modal-dialog ${getModalSize()}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              {title}
            </h5>
            {hasCloseButton && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            )}
          </div>
          <div className="modal-body">{children}</div>
          {hasFooter && (
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
